import backgroundImageMobile from '@assets/referral/referral-background-mobile.svg';
import backgroundImage from '@assets/referral/referral-background-web.svg';
import LanguageSwitcher from '@components/V3/LanguageSwitcher';
import { UPGRADE_EDUCATION_EMAIL } from '@shared/constants';
import style from '@styles/pages/referral-step-one.module.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../../TranslationContext';

const ReferralThankYouView = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <div className={style.pageWithBackground}>
      <div
        style={{
          position: 'absolute',
          top: '32px',
          right: '32px',
          width: 'fit-content',
        }}
      >
        <LanguageSwitcher />
      </div>
      <img
        src={screenWidth < 830 ? backgroundImageMobile : backgroundImage}
        alt="Background"
        className={style.backgroundSvg}
      />

      <div className={style.centeredContent}>
        <h1 className={style.title}>
          {t('referral_thank_you_part1')}{' '}
          <span style={{ color: '#D7B037' }}>{t('referral_thank_you_part2')}</span>!
        </h1>

        <p className={style.description}>
          {t('referral_thank_you_part3')} <br />
          <br /> {t('referral_thank_you_part4')}{' '}
          <a style={{ color: '#D7B037' }} href={`mailto:${UPGRADE_EDUCATION_EMAIL}`}>
            <strong>{UPGRADE_EDUCATION_EMAIL}</strong>
          </a>
          .
        </p>

        {/*<Link to="/referral">*/}
        <button
          style={{ maxWidth: '400px' }}
          className={style.button}
          onClick={() => history.goBack()}
        >
          {t('referral_thank_you_part5')}
        </button>
        {/*</Link>*/}
      </div>
    </div>
  );
};

export default ReferralThankYouView;
