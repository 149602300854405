import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { MailIcon, PhoneIcon, UserIcon } from '@heroicons/react/outline';
import { useParentData } from '@shared/react';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Card, Space, Typography } from 'antd';
import { COLORS } from '../../../../v3/global';

const { Text } = Typography;

type Props = {
  studentId: string;
};

const ParentInfoCard = ({ studentId }: Props) => {
  const { data: parentData, isLoading } = useParentData(studentId);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={styles.studentModalInfoCard}
    >
      <div style={{ color: COLORS.TEXT_SECONDARY }} className={styles.studentModalInfoCardHead}>
        Parent Contact
      </div>

      <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.studentModalInfoCardItem}>
        <UserIcon className={styles.icon} />
        <Text style={{ color: COLORS.TEXT_PRIMARY }}>{parentData?.fullName || 'N/A'}</Text>
      </div>

      <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.studentModalInfoCardItem}>
        <MailIcon className={styles.icon} />
        <Text style={{ color: COLORS.TEXT_PRIMARY }}>{parentData?.email || 'N/A'}</Text>
      </div>

      <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.studentModalInfoCardItem}>
        <PhoneIcon className={styles.icon} />
        <Text style={{ color: COLORS.TEXT_PRIMARY }}>{parentData?.phoneNumber || 'N/A'}</Text>
      </div>
    </div>
  );
};

export default ParentInfoCard;
