import { enrollmentsApis, usersApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useEnrollment = genericQueryWithId<enrollmentsApis.GetEnrollment>(
  enrollmentsApis.getEnrollment
);

export const useEnrollmentMentorHistory =
  genericQueryWithId<enrollmentsApis.GetEnrollmentMentorHistory>(
    enrollmentsApis.getEnrollmentMentorHistory
  );

export const useCreateEnrollment = genericMutation<enrollmentsApis.CreateEnrollment>(
  enrollmentsApis.createEnrollment,
  enrollmentsApis.createEnrollment.endpoint,
  usersApis.getUserProfile.endpoint
);

export const useUpdateEnrollment = genericMutation<enrollmentsApis.UpdateEnrollment>(
  enrollmentsApis.updateEnrollment,
  enrollmentsApis.createEnrollment.endpoint,
  enrollmentsApis.getEnrollments.endpoint,
  enrollmentsApis.getEnrollment.endpoint,
  usersApis.getUserProfile.endpoint
);

export const useGetEnrollments = genericQueryWithId<enrollmentsApis.GetEnrollments>(
  enrollmentsApis.getEnrollments
);

export const useUserEnrollments = genericQueryWithId<enrollmentsApis.GetUserEnrollments>(
  enrollmentsApis.getUserEnrollments
);

export const useStudentEnrollments = genericQuery<enrollmentsApis.GetStudentEnrollments>(
  enrollmentsApis.getStudentEnrollments
);

export const useMyMentorEnrollments =
  genericQueryWithParams<enrollmentsApis.SearchMentorEnrollments>(
    enrollmentsApis.searchMentorEnrollments
  );

export const useMentorNoOfActiveEnrollments =
  genericQueryWithId<enrollmentsApis.GetMentorNoOfActiveEnrollments>(
    enrollmentsApis.getMentorNoOfActiveEnrollments
  );

export const useGetActiveStudentsByMentor = genericQuery<enrollmentsApis.GetActiveStudentsByMentor>(
  enrollmentsApis.getActiveStudentsByMentor
);

export const useAddSession = genericMutation<enrollmentsApis.AddSession>(
  enrollmentsApis.addSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useDeleteSession = genericMutation<enrollmentsApis.DeleteSession>(
  enrollmentsApis.deleteSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useEndEnrollment = genericMutation<enrollmentsApis.EndEnrollment>(
  enrollmentsApis.endEnrollment,
  enrollmentsApis.getActiveStudentsByMentor.endpoint,
  usersApis.getUserProfile.endpoint,
  enrollmentsApis.searchMentorEnrollments.endpoint
);

export const useResumeEnrollment = genericMutation<enrollmentsApis.ResumeEnrollment>(
  enrollmentsApis.resumeEnrollment,
  usersApis.getUserProfile.endpoint
);

export const useGetMentorEnrollmentsDetails =
  genericQueryWithParams<enrollmentsApis.GetMentorEnrollmentsDetails>(
    enrollmentsApis.getMentorEnrollmentsDetails
  );

export const useGetStudentEnrollmentsDetails =
  genericQueryWithParams<enrollmentsApis.GetStudentEnrollmentsDetails>(
    enrollmentsApis.getStudentEnrollmentsDetails
  );

export const useGetMentorTeachingAndEnrolledInCourses =
  genericQueryWithId<enrollmentsApis.GetMentorTeachingAndEnrolledInCourses>(
    enrollmentsApis.getMentorTeachingAndEnrolledInCourses
  );

export const useMinimumMentorCapacity = genericQuery<enrollmentsApis.GetMinimumMentorCapacity>(
  enrollmentsApis.getMinimumMentorCapacity
);

export const useHasMentorUpcomingStudents = genericQuery<enrollmentsApis.HasMentorUpcomingStudents>(
  enrollmentsApis.hasMentorUpcomingStudents
);

export const useUnenrollUser = genericMutation<enrollmentsApis.UnenrollUser>(
  enrollmentsApis.unenrollUser,
  usersApis.getUserProfile.endpoint
);

export const useMarkSessionAsCompleted = genericMutation<enrollmentsApis.MarkSessionAsCompleted>(
  enrollmentsApis.markSessionAsCompleted
);

export const useSearchAllSessions = genericQueryWithParams<enrollmentsApis.SearchSessions>(
  enrollmentsApis.searchSessions
);

export const useSearchSessionHistory = genericQueryWithParams<enrollmentsApis.SearchSessionHistory>(
  enrollmentsApis.searchSessionHistory
);

export const useGetSession = genericQueryWithId<enrollmentsApis.GetSession>(
  enrollmentsApis.getSession
);

export const useUpdateSession = genericMutationWithId<enrollmentsApis.UpdateSession>(
  enrollmentsApis.updateSession,
  enrollmentsApis.getEnrollment.endpoint,
  enrollmentsApis.getSession.endpoint
);

export const useGetSessionDetails = genericQueryWithId<enrollmentsApis.GetSessionDetails>(
  enrollmentsApis.getSessionDetails
);
