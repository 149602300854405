import { LanguageExam } from '@shared/common';
import { useState } from 'react';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import PastExamsCard from './PastExamsCard';
import PastExamsForm from './PastExamsForm';

interface PastExamsSectionProps {
  languageExams: LanguageExam[];
  userId: string;
}

const PastExamsSection = (props: PastExamsSectionProps) => {
  const { languageExams, userId } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'languageExams',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Past Exams"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <PastExamsForm userId={userId} isModeAddNew onCancel={addNewItemCancelHandler} />
      )}

      {!!languageExams.length &&
        languageExams.map((exam) => (
          <PastExamsCard
            key={exam._id}
            userId={userId}
            languageExam={exam}
            onDeleteEntry={deleteEntryHandler}
          />
        ))}
    </ProfileMainSection>
  );
};

export default PastExamsSection;
