import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { useState } from 'react';

const Licenses = () => {
  const [licenses, setLicenses] = useState('');

  apiClient
    .get('/assets/all-licences.txt')
    .then((r) => r.data)
    .then(setLicenses);

  return (
    <>
      <h1>The following open source components are used in accordance with their licenses</h1>
      <code style={{ whiteSpace: 'pre-line' }}>{licenses}</code>;
    </>
  );
};

export default Licenses;
