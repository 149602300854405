import React from 'react';
import MentorCard from '../MentorCard';
import RatingStars from '../Utils/RatingStars';
import './index.styles.scss';
import { Divider } from 'antd';
import { MentorFeedback } from '@shared/common';

type Props = {
  mentorFeedback: { feedback: MentorFeedback };
  selectedTab: string;
};

const StudentFeedbackCard = ({ mentorFeedback, selectedTab }: Props) => {
  return (
    <div style={{ marginBottom: '32px' }}>
      <MentorCard mentorFeedback={mentorFeedback} />
      {/*HOMEWORK*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Homework
          </p>
          <p>
            <RatingStars rating={mentorFeedback?.feedback?.homework?.rate} />
          </p>
        </div>
        <div>
          <p className="feedback-description">{mentorFeedback?.feedback?.homework?.explanation}</p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*ENGAGEMENT*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Engagement
          </p>
          <p>
            <RatingStars rating={mentorFeedback?.feedback?.engagement?.rate} />
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.engagement?.explanation}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*PROGRESS*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Progress
          </p>
          <p>
            <RatingStars rating={mentorFeedback?.feedback?.progress?.rate} />
          </p>
        </div>
        <div>
          <p className="feedback-description">{mentorFeedback?.feedback?.progress?.explanation}</p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*CURRENT STATUS*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Session Summary
          </p>
        </div>
        <div>
          <p className="feedback-description">{mentorFeedback?.feedback?.sessionSummary}</p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*ACTION PLAN*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Action Plan
          </p>
        </div>
        <div>
          <p className="feedback-description">{mentorFeedback?.feedback?.nextTopics}</p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*PARENT HELP*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Parent Help
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.parentHelp?.length > 0
              ? mentorFeedback?.feedback?.parentHelp
              : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentFeedbackCard;
