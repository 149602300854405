import { Collapse } from 'antd';
import { useQuery } from 'react-query';
import MentorSingleChecklist from './MentorSingleChecklist';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';

const { Panel } = Collapse;

const MentorAllChecklistsForUser = ({ userId }: { userId: string }) => {
  const fetchChecklistArray = async () => {
    const response = await apiClient.get(`/checklists/array-for-user/${userId}`);
    return response.data;
  };

  const { data } = useQuery<{ _id: string; title: string }[]>(
    `checklists-${userId}`,
    fetchChecklistArray
  );

  if (!data || data?.length === 0) {
    return <p>No checklists</p>;
  }

  return (
    <Collapse ghost>
      {data?.map((checklist) => (
        <Panel header={checklist.title} key={checklist._id}>
          <MentorSingleChecklist checklistId={checklist._id} />
        </Panel>
      ))}
    </Collapse>
  );
};

export default MentorAllChecklistsForUser;
