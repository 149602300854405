import FlagIconFilled from '@assets/icons/icon-awesome-flag-filled.png';
import { FLAG_TYPE } from '@shared/constants';
import { COLORS } from '../../../v3/global';
import './total-flags-banner.styles.scss';

type Props = {
  noOfFlagTypes: any;
};

const TotalFlagsBanner = ({ noOfFlagTypes }: Props) => {
  return (
    <div style={{ backgroundColor: COLORS.DARK_GREY4 }} className="total-flags-banner-container">
      <div className="total-flags-cards">
        {/*FLAG CARDS*/}
        {noOfFlagTypes?.map((flagCategory: any, index: number) => (
          <div key={index} className="total-flags-card-container">
            <div className="total-flags-title-icon-container">
              <h4
                style={{ color: COLORS.TEXT_PRIMARY, marginRight: '14px' }}
                className="total-flags-banner-title"
              >
                {flagCategory?.count ?? 'N/A'}
              </h4>
              <img className="total-flags-icon" src={FlagIconFilled} alt="flag" />
            </div>
            <div className="total-flags-banner-description">
              {FLAG_TYPE[flagCategory?.value] ?? 'N/A'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalFlagsBanner;
