import { ApiData, ApiDescription, RequestsMethod, EducationalInstitution } from '@shared/types';

export const createEducationalInstitution: ApiDescription = {
  endpoint: '/educational-institution',
  method: RequestsMethod.Post,
};

export type CreateEducationalInstitution = ApiData<
  Partial<EducationalInstitution>,
  EducationalInstitution
>;

export const getEducationalInstitution: ApiDescription = {
  endpoint: '/educational-institution/:id',
  method: RequestsMethod.Get,
};

export type GetEducationalInstitution = ApiData<unknown, EducationalInstitution>;

export const updateEducationalInstitution: ApiDescription = {
  endpoint: '/educational-institution/:id',
  method: RequestsMethod.Put,
};

export type UpdateEducationalInstitution = ApiData<
  Partial<EducationalInstitution>,
  EducationalInstitution
>;

export const deleteEducationalInstitution: ApiDescription = {
  endpoint: '/educational-institution/:id',
  method: RequestsMethod.Delete,
};

export type DeleteEducationalInstitution = ApiData<unknown, EducationalInstitution>;

export const getEducationalInstitutions: ApiDescription = {
  endpoint: '/educational-institutions',
  method: RequestsMethod.Get,
};

export type GetEducationalInstitutions = ApiData<unknown, EducationalInstitution[]>;

export const getAllEducationalInstitutionNames: ApiDescription = {
  endpoint: '/educational-institution-names',
  method: RequestsMethod.Get,
};

export type GetAllEducationalInstitutionNames = ApiData<unknown, string[]>;
