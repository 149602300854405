import { mergeClassNames } from '../../utils/helpers';
import { BasicProps } from '../../utils/types';

const SkeletonBox = (props: BasicProps) => {
  const { className, children } = props;

  return (
    <div
      className={mergeClassNames(
        'bg-skeletonBg rounded-xl space-y-4 overflow-hidden animate-skeleton bg-[length:400%]',
        className
      )}
    >
      {children}
    </div>
  );
};

export default SkeletonBox;
