export const formatMessage = (text: string) => {
  // Define the pattern to remove content within Japanese brackets 【】
  const japaneseBracketPattern = /【[^【】]*】/g;

  // Clean the text by removing content within Japanese brackets
  text = text.replace(japaneseBracketPattern, '');

  // Define the pattern to remove sources
  const sourcePattern = /【\d+:\d+†source】/g;

  // Clean the text by removing sources
  text = text.replace(sourcePattern, '');

  // Replace ***text*** or ___text___ with <strong><em>text</em></strong>
  text = text.replace(/\*\*\*(.*?)\*\*\*/g, '<strong><em>$1</em></strong>');
  text = text.replace(/___(.*?)___/g, '<strong><em>$1</em></strong>');

  // Replace ### Heading 3 with <h4>Heading 3</h4>
  text = text.replace(
    /^###\s+(.*)$/gm,
    '<h4 style="color: #FFFFFF" class="chat-message-heading-4">$1</h4>'
  );

  // Replace ## Heading 2 with <h3>Heading 2</h3>
  text = text.replace(
    /^##\s+(.*)$/gm,
    '<h3 style="color: #FFFFFF" class="chat-message-heading-3">$1</h3>'
  );

  // Replace # Heading 1 with <h2>Heading 1</h2>
  text = text.replace(
    /^#\s+(.*)$/gm,
    '<h2 style="color: #FFFFFF" class="chat-message-heading-2">$1</h2>'
  );

  // Replace **text** with <strong>text</strong>
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong class="user-message-box-text-bold">$1</strong>');

  // Replace *text* or _text_ with <em>text</em>
  text = text.replace(/\*(.*?)\*/g, '<em class="user-message-box-text">$1</em>');
  text = text.replace(/_(.*?)_/g, '<em class="user-message-box-text">$1</em>');

  // Handle blockquotes
  text = text.replace(
    /(?:^|\n)>\s+(.*?)(?=\n|$)/g,
    (match, p1) => `<blockquote>${p1}</blockquote>`
  );

  // Replace horizontal rules
  text = text.replace(/^\s*(---|\*\*\*)\s*$/gm, '<hr />');

  // Handle code blocks
  text = text.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

  // Handle inline code
  text = text.replace(/`([^`]+)`/g, '<code>$1</code>');

  // Handle unordered lists
  text = text.replace(/^\s*[-*]\s+(.*)$/gm, '<ul><li>$1</li></ul>');

  // Handle unordered lists
  text = text.replace(/^\s*[-*]\s+(.*)$/gm, (match, p1) => {
    return `<ul style="margin-bottom: 16px;"><li style="margin-bottom: 8px;">${p1}</li></ul>`;
  });

  // Handle ordered lists
  let listNumber = 1;
  text = text.replace(/^\s*\d+\.\s+(.*)$/gm, (match, p1) => {
    return `<ol style="margin-bottom: 16px;"><li style="margin-bottom: 8px;">${listNumber++}. ${p1}</li></ol>`;
  });

  // Handle paragraph spacing
  text = text.replace(
    /(?:^|\n)(?!(?:<h[2-4]|<ul|<ol|<li|<blockquote|<hr|<pre|<code))(.*?)(?=\n|$)/g,
    (match, p1) => `<p style="margin-bottom: 16px;">${p1}</p>`
  );

  return text;
};

export const transformMessages = (messages: any): any => {
  const transformed: any = [];

  messages.forEach((messagePair: any) => {
    if (messagePair.length === 2) {
      const [userMessage, aiMessage] = messagePair;
      transformed.push({ message: userMessage.content, isUser: true });
      transformed.push({ message: aiMessage.content, isUser: false });
    }
  });

  return transformed;
};
