import defaultAvatar from '@assets/default-user.png';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { MeetingUser } from '@shared/common';
import { useContext, useEffect, useState } from 'react';

type Participant = {
  role: string;
} & MeetingUser;

const SidebarParticipantsPage = () => {
  const [participantsList, setParticipantsList] = useState<Participant[]>();

  const { zoomClient, meetingDetails, isMeetingLoading } = useContext(ZoomContext);

  const getParticipantsList = () => {
    if (!meetingDetails) return;

    const currentZoomParticipants = zoomClient?.getAllUser();

    const participants = [
      { ...meetingDetails.mentor, role: 'Mentor' },
      ...meetingDetails.students.map((student) => ({
        ...student,
        role: 'Student',
      })),
    ];

    return participants?.filter((participant) =>
      currentZoomParticipants?.some(
        (zoomParticipant) => participant._id === zoomParticipant.userIdentity
      )
    );
  };

  useEffect(() => {
    if (isMeetingLoading) return;

    setParticipantsList(getParticipantsList());

    const updateParticipantsHandler = () => {
      setParticipantsList(getParticipantsList());
    };

    zoomClient?.on('user-added', updateParticipantsHandler);
    zoomClient?.on('user-removed', updateParticipantsHandler);

    return () => {
      zoomClient?.off('user-added', updateParticipantsHandler);
      zoomClient?.off('user-removed', updateParticipantsHandler);
    };
  }, [isMeetingLoading]);

  return (
    <div className="flex flex-col gap-4">
      {participantsList?.map((user) => (
        <div key={user._id} className="flex items-center gap-4">
          <img
            src={user.avatar ?? defaultAvatar}
            className="w-9 h-9  rounded-full"
            alt={user.fullName}
            crossOrigin={'anonymous'}
          />
          <div className="flex flex-col">
            <p className="font-raleway text-xsm font-bold">{user.fullName}</p>
            <p className="text-xs">{user.role}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SidebarParticipantsPage;
