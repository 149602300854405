import { Checklist, ChecklistItem } from '@shared/common';
import { ApiDescription, ApiData, RequestsMethod, RawObject } from '@shared/types';

export const createChecklist: ApiDescription = {
  endpoint: '/checklists',
  method: RequestsMethod.Post,
};
export type CreateChecklist = ApiData<RawObject<Checklist>, Checklist>;

export const getChecklist: ApiDescription = {
  endpoint: '/checklists/:id',
  method: RequestsMethod.Get,
};
export type GetChecklist = ApiData<unknown, Checklist>;

export const updateChecklist: ApiDescription = {
  endpoint: '/checklists/:id',
  method: RequestsMethod.Patch,
};
export type UpdateChecklist = ApiData<Partial<Checklist>, Checklist>;

export const createMilestone: ApiDescription = {
  endpoint: '/checklists/:id/create-milestone',
  method: RequestsMethod.Patch,
};
export type CreateMilestone = ApiData<Partial<ChecklistItem>, Checklist>;

export const deleteMilestone: ApiDescription = {
  endpoint: '/checklists/:id/delete-milestone',
  method: RequestsMethod.Patch,
};
export type DeleteMilestone = ApiData<
  {
    milestoneId: string;
  },
  Checklist
>;

export const deleteChecklistTemplate: ApiDescription = {
  endpoint: '/checklists/delete-template',
  method: RequestsMethod.Delete,
};
export type DeleteChecklistTemplate = ApiData<{ checklistId: string }, { message: string }>;

export const importChecklist: ApiDescription = {
  endpoint: '/checklists/:id/import-template/',
  method: RequestsMethod.Patch,
};

export type ImportChecklist = ApiData<{ templateId: string }, Checklist>;

export const getAllChecklistTemplates: ApiDescription = {
  endpoint: '/checklists/all-templates',
  method: RequestsMethod.Get,
};

export type GetAllChecklistTemplates = ApiData<unknown, Partial<Checklist>[]>;

export const getAllChecklistTemplatesForEnrollment: ApiDescription = {
  endpoint: '/checklists/all-templates/:id',
  method: RequestsMethod.Get,
};

export type GetAllChecklistTemplatesForEnrollment = ApiData<unknown, Partial<Checklist>[]>;

export const getAllActiveChecklists: ApiDescription = {
  endpoint: '/checklists/all-active',
  method: RequestsMethod.Get,
};

export type GetAllActiveChecklists = ApiData<unknown, Partial<Checklist>[]>;

export const getMyActiveChecklists: ApiDescription = {
  endpoint: '/checklists/my-active',
  method: RequestsMethod.Get,
};

export const getAllStudentsChecklistArrayForMentor: ApiDescription = {
  endpoint: '/checklists/array-for-mentor/:id',
  method: RequestsMethod.Get,
};

export type GetAllStudentsChecklistArrayForMentor = ApiData<
  unknown,
  { checklist: Partial<Checklist>; userId: string }[]
>;

export type GetMyActiveChecklists = ApiData<unknown, Partial<Checklist>[]>;

export const getChecklistArrayForUser: ApiDescription = {
  endpoint: '/checklists/array-for-user/:userId',
  method: RequestsMethod.Get,
};

export type GetChecklistArrayForUser = ApiData<
  unknown,
  { _id: string; title: string; items: any[] }[]
>;

export const toggleChecklistItemChecked: ApiDescription = {
  endpoint: '/checklists/:id/toggle',
  method: RequestsMethod.Patch,
};

export type ToggleChecklistItemChecked = ApiData<{ itemIndex: number }, Checklist>;
