import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { forwardRef } from 'react';

interface VideoPlayerContainerProps extends BasicProps {
  id?: string;
}

const VideoPlayerContainer = forwardRef((props: VideoPlayerContainerProps, ref: any) => {
  const { className, children, id } = props;

  return (
    <video-player-container id={id} className={mergeClassNames(className)} ref={ref}>
      {children}
    </video-player-container>
  );
});

export default VideoPlayerContainer;
