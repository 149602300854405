import { Question as QuestionType } from '@shared/common';
import { Button, Space } from 'antd';

type Props = {
  question?: QuestionType;
};

const QuestionFeedback = ({ question }: Props) => {
  if (!question) return <p>no question</p>;

  const choices = question.answers.map((answer, index) => (
    <Button
      key={answer._id}
      type={index === 0 ? 'primary' : undefined}
      style={{
        width: '100%',
        marginBottom: 10,
        whiteSpace: 'inherit',
        minHeight: '32px',
        height: 'auto',
      }}
    >
      {answer.text}
    </Button>
  ));
  const shuffledChoices = choices.sort(() => Math.random() - 0.5);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h4>{question.text}</h4>
      {question.imageUrl && <img src={question.imageUrl} alt="" style={{ maxWidth: '100%' }} />}
      {question.explanation && <h5>{question.explanation}</h5>}
      {shuffledChoices}
    </Space>
  );
};

export default QuestionFeedback;
