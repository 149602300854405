import { ReactComponent as CheckIconShorter } from '@assets/icons/V4/check-icon-shorter.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface NavigationItemProps extends BasicProps {
  isDisabled: boolean;
  isCompleted: boolean;
  isCurrentPage: boolean;
  bubbleText: string | number;
  label: string;
  isLast?: boolean;
  onClick: () => void;
}

const NavigationItem = (props: NavigationItemProps) => {
  const {
    isDisabled,
    isCompleted,
    isCurrentPage,
    bubbleText,
    label,
    isLast = false,
    onClick,
  } = props;

  return (
    <button disabled={isDisabled} onClick={onClick} className="flex flex-col gap-2">
      <div className="flex gap-4">
        <div
          className={mergeClassNames(
            'flex items-center justify-center h-8 min-w-8 border-2 border-customGrey text-customGrey rounded-full text-sbase font-sans font-semibold',
            {
              'bg-customPrimary border-customPrimary bg-opacity-20 border-opacity-0': isCompleted,
              'bg-customPrimary border-customPrimary text-black bg-opacity-100 border-opacity-100':
                isCurrentPage,
            }
          )}
        >
          {isCompleted && !isCurrentPage ? <CheckIconShorter className="text-white" /> : bubbleText}
        </div>
        <p className="flex items-center min-w-max">{label}</p>
      </div>
      {!isLast && (
        <div className="flex justify-center w-8">
          <div
            className={mergeClassNames('rounded-full bg-customGrey w-0.5 h-4', {
              'bg-customPrimary': isCompleted || isCurrentPage,
            })}
          />
        </div>
      )}
    </button>
  );
};

export default NavigationItem;
