import { lessonsApis } from '../../apis';
import { genericMutationWithId, genericQueryWithId } from './generics';

export const useLesson = genericQueryWithId<lessonsApis.GetLesson>(lessonsApis.getLesson);

export const useLessonTakeaways = genericQueryWithId<lessonsApis.ListLessonTakeaways>(
  lessonsApis.listLessonTakeaways
);

export const useMarkLessonComplete = genericMutationWithId<lessonsApis.MarkComplete>(
  lessonsApis.markComplete
);
