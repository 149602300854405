import {
  RenderRoutes,
  CourseDetailsEditorPage,
  CourseManagementPage,
  LessonEditor,
  PageEditor,
} from '@components';
import CustomerSuccessDashboard from '@components/Admin/CSDashboard';
import ActivityReport from '@modules/Admin/ActivityReport';
import ExtraServices from '@modules/Admin/ExtraServices';
import Flags from '@modules/Admin/Flags';
import KnowledgeBaseEditor from '@modules/Admin/KnowledgeBaseEditor';
import MentorsV3, { MentorPageSessionModal } from '@modules/Admin/MentorsV3';
import NPSDashboard from '@modules/Admin/NPSDashboard';
import Students from '@modules/Admin/Students';
import ManageUsersPage from '@modules/Admin/Users/ManageUsers';
import UsersV3 from '@modules/Admin/UsersV3';
import KnowledgeBase from '@modules/Mentor/KnowledgeBase';
import {
  CUSTOMER_SUCCESS,
  FINANCIAL_ROLE,
  MENTORS_MANAGER,
  STUDENTS_MANAGER,
  USERS_MANAGER,
} from '@shared/constants';
import { useAuthState } from '@shared/react';
import Checklists from './Checklists';
import AdminChecklistEditor from './Checklists/Editor';
import AllSessions from './pages/AllSessions/AllSessions';

const Admin = () => {
  const { roles } = useAuthState();

  const routes = [
    { path: '/meeting-management', component: AllSessions },
    { path: '/manage-courses/:id', component: CourseDetailsEditorPage },
    { path: '/manage-courses', component: CourseManagementPage },
    {
      path: '/lesson/:action/:courseId/:lessonId/:moduleId',
      component: PageEditor,
    },
    { path: '/lesson', component: LessonEditor },
    { path: '/checklists/new', component: AdminChecklistEditor },
    { path: '/checklists/:id/edit', component: AdminChecklistEditor },
    { path: '/checklist-management', component: Checklists },
    { path: '/flags', component: Flags },
    { path: '/manage-knowledge-base', component: KnowledgeBase },
    { path: '/knowledge-base/:id', component: KnowledgeBaseEditor },
    { path: '/knowledge-base', component: KnowledgeBaseEditor },
  ];

  if (roles.includes(FINANCIAL_ROLE)) {
    routes.push(
      { path: '/activity-report', component: ActivityReport },
      { path: '/extra-services', component: ExtraServices }
    );
  }

  if (roles.includes(CUSTOMER_SUCCESS)) {
    routes.push(
      {
        path: '/customer-success',
        component: CustomerSuccessDashboard,
      },
      {
        path: '/nps-dashboard',
        component: NPSDashboard,
      }
    );
  }

  if (roles.includes(STUDENTS_MANAGER)) {
    routes.push({ path: '/students', component: Students });
  }

  if (roles.includes(MENTORS_MANAGER)) {
    routes.push({ path: '/mentors-v3', component: MentorsV3 });
  }

  if (roles.includes(USERS_MANAGER)) {
    routes.push(
      { path: '/manage-users', component: ManageUsersPage },
      { path: '/manage-users-v3', component: UsersV3 }
    );
  }

  return <RenderRoutes routes={routes} redirectPath="/manage-users-v3" />;
};

export default Admin;

export { MentorPageSessionModal };
