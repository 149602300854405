import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import IconButton from '@components/V4/IconButton';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import React, { CSSProperties, ReactNode } from 'react';

interface SessionDetailsLayoutCardProps {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  title?: string;
  onClick?: () => void;
  titleClassName?: string;
  titleStyle?: CSSProperties;
  style?: CSSProperties;
}

const SessionDetailsLayoutCard = (props: SessionDetailsLayoutCardProps) => {
  const { children, className, icon, title, onClick, titleClassName, titleStyle, style } = props;
  return (
    <section
      className={mergeClassNames(
        'flex flex-col py-8 px-4 bg-surfaceBackground rounded-lg tablet:px-8 gap-8',
        className
      )}
      style={style}
    >
      {title && (
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            {icon}
            <h2
              className={mergeClassNames(
                'text-white text-xl font-raleway font-bold leading-6',
                titleClassName
              )}
              style={titleStyle}
            >
              {title}
            </h2>
          </div>
          {!!onClick && <IconButton icon={<EditIcon />} onClick={onClick} />}
        </div>
      )}
      {children}
    </section>
  );
};

export default SessionDetailsLayoutCard;
