import JoinIcon from '@assets/icons/Icon-material-video-call.svg';
import { Meeting } from '@shared/common';
import button from '@styles/components/reusables/buttons.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import MeetingListItemButton from './MeetingListItemButton';

interface JoinButtonProps {
  meeting: Meeting;
  afterSave?: () => void;
}

const JoinButtonStudent: React.FC<JoinButtonProps> = ({ meeting, afterSave }) => {
  return (
    <div className={button.joinButtonStudentContainer}>
      <span
        style={{
          color: COLORS.TEXT_PRIMARY,
        }}
        className={`${button.actionButton} gradient ${button.joinButtonMentorJoinContainer}`}
      >
        <MeetingListItemButton>
          <Link
            to={`/video-meeting/${meeting._id}`}
            className="flex items-center w-full h-full text-white py-2 px-4"
          >
            <img src={JoinIcon} alt="Join meeting" className={button.joinButtonStudentIcon} />
            <span className="text-white">Join meeting</span>
          </Link>
        </MeetingListItemButton>
      </span>
    </div>
  );
};

export default JoinButtonStudent;
