import React from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal.styles.scss';
import moment from 'moment';
import { MentorHistory } from '@shared/types';

type Props = {
  mentorHistory: MentorHistory[];
};

const EditEnrolmentMentorModalRow = ({ mentorHistory }: Props) => {
  return (
    <div>
      {mentorHistory?.map((mentor: MentorHistory) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '12px',
            marginBottom: '12px',
          }}
        >
          {/*MENTOR NAME*/}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: COLORS.TEXT_SECONDARY,
              width: '50%',
              marginRight: '8px',
            }}
          >
            <span style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
              {mentor?.fullName}
            </span>
          </div>
          {/*DATE ASSIGNED*/}
          <div
            style={{
              display: 'flex',
              color: COLORS.TEXT_SECONDARY,
              width: '30%',
              marginRight: '8px',
            }}
          >
            <span style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
              {mentor?.addedOn ? moment(mentor?.addedOn)?.format('YYYY.MM.DD') : 'N/A'}
            </span>
          </div>
          {/*STATUS*/}
          <div
            style={{
              display: 'flex',
              color: COLORS.TEXT_SECONDARY,
              width: '20%',
            }}
          >
            <span style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
              {mentor?.status ?? 'N/A'}
            </span>
          </div>
        </div>
      ))}

      <div style={{ borderTopWidth: '0.5px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default EditEnrolmentMentorModalRow;
