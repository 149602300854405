import { UseMutationOptions, UseQueryOptions, useQueryClient } from 'react-query';
import { useAuthAxios, useAuthMutation, useAuthQuery, useAuthQueryWithParams } from '../../react';
import { ApiData, ApiDescription } from '../../types';

const maxRetries = 1;

export function genericQuery<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  options?: UseQueryOptions<AD['response']>
) {
  return (optionsQuery?: UseQueryOptions<AD['response']>) =>
    useAuthQuery(desc.endpoint, {
      ...options,
      ...optionsQuery,
      retry: (failureCount, error: any) => {
        if (failureCount >= maxRetries) {
          return false;
        }

        return !(
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        );
      },
    });
}

export function genericQueryWithId<AD extends ApiData<unknown, unknown>>(desc: ApiDescription) {
  return (id: string | undefined, options?: UseQueryOptions<AD['response']>) =>
    useAuthQuery([desc.endpoint, id], {
      enabled: !!id,
      ...options,
      retry: (failureCount, error: any) => {
        if (failureCount >= maxRetries) {
          return false;
        }

        return !(
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        );
      },
    });
}

export function genericQueryWithParams<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  options?: UseQueryOptions<AD['response']>,
  noCache?: boolean
) {
  return (params?: AD['payload'], optionsQuery?: UseQueryOptions<AD['response']>) =>
    useAuthQueryWithParams(
      desc.endpoint,
      params,
      {
        ...options,
        ...optionsQuery,
        retry: (failureCount, error: any) => {
          if (failureCount >= maxRetries) {
            return false;
          }

          return !(
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403 ||
            error?.response?.status === 404
          );
        },
      },
      noCache
    );
}

/**
 *
 * @param desc
 * @param invalidations A list of strings on which queryClient.invalidateQueries to be called
 * @returns
 */
export function genericMutation<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (options?: UseMutationOptions<AD['response'], unknown, AD['payload']>) => {
    const [actionCreator] = useAuthAxios();
    const action = actionCreator(desc.endpoint, desc.method);

    const queryClient = useQueryClient();

    return useAuthMutation<AD['payload'], AD['response']>(action, {
      ...options,
      onSettled: (data, error, variable, context) => {
        options?.onSettled && options.onSettled(data, error, variable, context);

        invalidations.forEach((invalidation) => {
          queryClient.invalidateQueries(invalidation);
        });
      },
    });
  };
}

/**
 *
 * @param desc
 * @param invalidations A list of strings on which queryClient.invalidateQueries to be called
 * @returns
 */
export function genericMutationWithId<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (id: string, options?: UseMutationOptions<AD['response'], unknown, AD['payload']>) => {
    const [actionCreator] = useAuthAxios();
    const url = desc.endpoint.replace(/:\w+/, id);
    const action = actionCreator(url, desc.method);

    const queryClient = useQueryClient();

    return useAuthMutation<AD['payload'], AD['response']>(action, {
      ...options,
      onSettled: (data, error, variable, context) => {
        options?.onSettled && options.onSettled(data, error, variable, context);

        invalidations.forEach((invalidation) => {
          const query = invalidation.includes(':') ? [invalidation, id] : [invalidation];

          queryClient.invalidateQueries(query);
        });
      },
    });
  };
}

export function genericMutationWithParams<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (options?: UseMutationOptions<AD['response'], unknown, AD['payload']>) => {
    const [actionCreator] = useAuthAxios();
    const queryClient = useQueryClient();

    return useAuthMutation<AD['payload'], AD['response']>(
      (payload: AD['payload']) => {
        const queryParams = new URLSearchParams(payload as Record<string, string>).toString();
        const url = `${desc.endpoint}?${queryParams}`;
        const action = actionCreator(url, desc.method);

        return action(payload);
      },
      {
        ...options,
        onSettled: (data, error, variables, context) => {
          options?.onSettled && options.onSettled(data, error, variables, context);

          invalidations.forEach((invalidation) => {
            queryClient.invalidateQueries(invalidation);
          });
        },
      }
    );
  };
}
