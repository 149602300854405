import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import Modal from '@components/V4/Modal/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type ConfirmNoShowModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClick: (reason: string) => void;
  onSecondaryButtonClick: () => void;
};

const formDataSchema = z.object({
  reason: z.string().min(1, 'Please enter a valid message.'),
});

type FormData = z.infer<typeof formDataSchema>;

const ConfirmNoShowModal = (props: ConfirmNoShowModalProps) => {
  const { isOpen, onClose, onPrimaryButtonClick, onSecondaryButtonClick } = props;

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      onPrimaryButtonClick(data.reason);
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(onSubmit)(e);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Confirm No Show" className="text-center" />
      <Modal.Body className="flex flex-col justify-center items-center overflow-x-hidden mb-6 text-xs leading-5 ">
        <div className="flex flex-col gap-6">
          <p>
            Reminder: You are required to wait 15 minutes and attempt to contact the student before
            marking the session as a no-show.
          </p>
          <p>
            Afterward, please provide a brief explanation of the no-show so we can reach out to the
            student:
          </p>

          <TextArea
            isRequired
            id={'reason'}
            label={'Share what happened'}
            isTouched={touchedFields.reason}
            isValid={!errors.reason}
            errorText={errors.reason?.message}
            {...register('reason', { required: true })}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          className="h-6 min-h-6"
          size="large"
          variant="secondary"
          buttonText="Take me back"
          onClick={onSecondaryButtonClick}
        />
        <Button
          className="h-6 min-h-6 "
          size="large"
          variant="primary-error"
          buttonText="Mark as no show"
          onClick={submitHandler}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmNoShowModal;
