import { PageDisplay } from '@components';
import HeaderV3 from '@components/V3/HeaderV3';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import AccountPage from '@modules/Account';
import LessonDisplay from '@modules/Base/LessonDisplay';
import KnowledgeBaseAssistant from '@modules/knowledgeBaseAssistant/components/KnowledgeBaseAssistant';
import {
  ACCOUNT,
  HOME,
  COURSES,
  MY_STUDENTS,
  SESSION_HISTORY,
  KNOWLEDGE_BASE_ASSISTANT,
  VIDEO_MEETING,
  STUDENT_PROFILE,
  SESSION_DETAILS,
  MENTOR_ACTIVITY_REPORT,
} from '@routes';
import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState, useMyMentorEnrollments } from '@shared/react';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { COLORS } from '../../v3/global';
import Course from './Course';
import ChapterPage from './Course/ChapterPage';
import Courses from './Courses';
import MentorHome from './Home/Mentor';
import Home from './Home/Student';
import MeetingVideo from './MeetingVideo/MeetingVideo';

import './user-view.styles.scss';
import ActivityReport from './MentorView/ActivityReport';
import MyEnrollments from './MentorView/MyEnrollments';
import ViewStudentProfile from './MentorView/ViewStudentProfile';
import SessionDetails from './SessionDetails/SessionDetails';
import SessionHistory from './SessionHistory/SessionHistory';

const UserView: React.FC = () => {
  const userRole = localStorage.getItem('userRole');

  const { roles } = useAuthState();
  let isMentor: any = undefined;

  if (userRole === 'student') {
    isMentor = false;
  } else if (userRole === 'mentor') {
    isMentor = true;
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [showModal, setShowModal] = useState<boolean>(false);

  const { data: myStudentsData, refetch } = useMyMentorEnrollments({
    status: 'all',
    limit: 200,
  });

  function getYearFromDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    return year;
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const hasDocumentMatchingCriteria =
      myStudentsData?.documents.some(
        (document) =>
          getYearFromDate(document.startDate) < 2023 &&
          // TODO: Change this in 2025.
          (document.user?.graduationYear ?? 0) <= 2024 &&
          !document.ended &&
          document.user.isDezactivated === false
      ) || false;

    setShowModal(hasDocumentMatchingCriteria);
  }, [myStudentsData?.documents]);

  return (
    <div
      className="h-full"
      style={{
        display: 'flex',
        backgroundColor: COLORS.BLACK,
      }}
    >
      <HeaderV3>
        <div className="user-view-page-content">
          <Switch>
            <Route path={HOME}>{isMentor ? <MentorHome /> : <Home />}</Route>
            <Route path={`${COURSES}/:courseId/chapters/:chapterIndex`}>
              <ChapterPage />
            </Route>
            <Route path={`${COURSES}/:courseId`}>
              <Course />
            </Route>
            <Route path={`${COURSES}`}>
              <div style={{ marginTop: 32 }}>
                <Courses />
              </div>
            </Route>

            {isMentor && (
              <Route path={MY_STUDENTS}>
                <MyEnrollments />
              </Route>
            )}

            {isMentor && (
              <Route path={MENTOR_ACTIVITY_REPORT}>
                <ActivityReport />
              </Route>
            )}
            <Route path={SESSION_HISTORY}>
              <SessionHistory />
            </Route>

            <Route path={`${VIDEO_MEETING}/:meetingId`}>
              <MeetingVideo />
            </Route>

            <Route path={`${STUDENT_PROFILE}/:studentId`}>
              <ViewStudentProfile />
            </Route>

            <Route path={`${SESSION_DETAILS}/:sessionId`}>
              <SessionDetails />
            </Route>

            <Route path={KNOWLEDGE_BASE_ASSISTANT}>
              <KnowledgeBaseAssistant />
            </Route>

            <Route path={ACCOUNT}>
              <AccountPage />
            </Route>

            <Route path="/lesson/:id">
              <PageDisplay />
            </Route>
            <Route path="/lesson">
              <LessonDisplay />
            </Route>

            <Redirect to={HOME} />
          </Switch>
        </div>
      </HeaderV3>

      {/*TODO Remove this and it's usage if no longer needed*/}
      {isMentor && showModal && (
        <AgoraModal
          setModalOpen={() => setShowModal(!showModal)}
          isModalOpen={showModal}
          closable={!!impersonateId}
          maskClosable={!!impersonateId}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ fontWeight: 'bold', marginBottom: '16px' }}>Important Notice!</p>
            <p style={{ marginBottom: '16px' }}>
              For any students you've finished working with or are no longer actively mentoring,
              please proceed to the 'My Students' tab and click 'End' next to their course
              enrollment. This will shift former students to the alumni section instead of being
              listed as active.
            </p>
            <p style={{ marginBottom: '32px' }}>
              If you have any questions please contact us on Discord or via email at{' '}
              <a
                style={{ color: COLORS.PRIMARY }}
                href="mailto: education@upgrade.education"
                target="_blank"
                rel="noreferrer"
              >
                education@upgrade.education
              </a>
            </p>
            <Link
              to={MY_STUDENTS}
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              <Button>Take me there!</Button>
            </Link>
          </div>
        </AgoraModal>
      )}
    </div>
  );
};

export default UserView;
