import Accordion from '@components/V4/Accordion';
import { MentorFeedback } from '@shared/common';
import moment from 'moment';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import MeetingDetail from '../SessionMeetingsTab/MeetingDetail';
import FeedbackEntry from './FeedbackEntry';

interface MentorFeedbackAccordionProps {
  mentorFeedback?: MentorFeedback;
  defaultIsAccordionOpen: boolean;
}

const MentorFeedbackAccordion = (props: MentorFeedbackAccordionProps) => {
  const { mentorFeedback, defaultIsAccordionOpen } = props;
  return (
    <Accordion
      defaultIsOpen={defaultIsAccordionOpen}
      title={<p className="font-raleway font-medium text-xs text-white">Mentor Feedback</p>}
      titleWrapperClassName="bg-surfaceHover p-2"
      className="flex flex-col gap-4 my-2 px-2 max-h-[200px] laptop:max-h-[540px]"
    >
      {!mentorFeedback?.sessionSummary ? (
        <SessionModalEmptyState />
      ) : (
        <div className="flex flex-col gap-4">
          <MeetingDetail
            name="Feedback date:"
            value={
              mentorFeedback?.createdAt
                ? moment(mentorFeedback.createdAt).format('DD.MM.YYYY, HH:mm')
                : 'n/a'
            }
          />
          <FeedbackEntry
            title="Session Summary:"
            description={mentorFeedback?.sessionSummary ?? ''}
          />

          <FeedbackEntry title="Next Topics:" description={mentorFeedback?.nextTopics ?? ''} />

          <FeedbackEntry
            title="Homework:"
            score={`${mentorFeedback?.homework?.rate}/5`}
            description={mentorFeedback?.homework?.explanation ?? ''}
          />

          <FeedbackEntry
            title="Engagement:"
            score={`${mentorFeedback?.engagement?.rate}/5`}
            description={mentorFeedback?.engagement?.explanation ?? ''}
          />

          <FeedbackEntry
            title="Progress:"
            score={`${mentorFeedback?.progress?.rate}/5`}
            description={mentorFeedback?.progress?.explanation ?? ''}
          />

          {!!mentorFeedback?.parentHelp && (
            <FeedbackEntry title="Parent Help:" description={mentorFeedback?.parentHelp ?? ''} />
          )}
        </div>
      )}
    </Accordion>
  );
};

export default MentorFeedbackAccordion;
