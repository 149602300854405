import { PaginationProps } from '@components/V4/Pagination';
import { useState, useEffect } from 'react';

interface UseClientPaginationProps<T> extends Partial<PaginationProps> {
  data: T[];
  initialPageSize?: number;
}

const usePagination = <T>(
  props: UseClientPaginationProps<T>
): [paginatedData: T[], pagination: PaginationProps] => {
  const { data, size = 'small', shouldOnlyShowPages = false, initialPageSize = 10 } = props;

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);

  const total = data.length;

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const onCurrentPageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (!data.length) {
      setPaginatedData([]);
      return;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    setPaginatedData(data.slice(startIndex, endIndex));
  }, [data, currentPage, pageSize]);

  const pagination: PaginationProps = {
    size,
    shouldOnlyShowPages,
    pageSize,
    currentPage,
    total,
    onPageSizeChange,
    onCurrentPageChange,
  };

  return [paginatedData, pagination];
};

export default usePagination;
