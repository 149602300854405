import React, { useState } from 'react';
import './enrollments-modal-flags.styles.scss';
import EditEnrolmentPageFlags from '@components/V3/EnrollmentsModalFlags/EditEnrolmentPageFlags';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import { ModelFlagTypes } from '@shared/constants';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  currentEnrolmentId?: string;
  flagType: number;
  reference: any;
};

const EnrollmentsModalFlags = ({
  showModal,
  handleShowModal,
  currentEnrolmentId,
  flagType,
  reference,
}: Props) => {
  // const [currentEnrolment, setCurrentEnrolment] = useState<string>(
  //   currentEnrolmentId ?? ''
  // );

  // const [currentMeeting, setCurrentMeeting] = useState<string>();

  const [editEnrolmentCourseName, setEditEnrolmentCourseName] = useState('-');
  const [editEnrolmentStudent, setEditEnrolmentStudent] = useState('-');
  const [editEnrolmentMentor, setEditEnrolmentMentor] = useState('-');

  const handleEditEnrolmentTitle = (courseName: string, mentor: string, student: string) => {
    setEditEnrolmentCourseName(courseName);
    setEditEnrolmentMentor(mentor);
    setEditEnrolmentStudent(student);
  };

  return (
    <div>
      <AgoraModal
        backButton={false}
        title={`Edit - ${editEnrolmentCourseName ?? '-'}`}
        title2={`${editEnrolmentMentor ?? '-'} / ${editEnrolmentStudent ?? '-'}`}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        <EditEnrolmentPageFlags
          reference={reference}
          flagType={flagType}
          handleEditEnrolmentTitle={handleEditEnrolmentTitle}
        />
      </AgoraModal>
    </div>
  );
};

export default EnrollmentsModalFlags;
