import styles from '@styles/components/form.module.scss';
import { Select } from 'antd';
import { cloneElement, FC, JSXElementConstructor, ReactElement } from 'react';
import { Controller, FieldError, Path, UseFormReturn } from 'react-hook-form';

export const HookFormInput = ({
  form,
  title,
  label,
  disabled = false,
  type = 'text',
  placeholder = '',
  customError,
  customSuccess,
  style,
  className,
  validation,
  step,
}: Props) => {
  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const error = customError || errors[label];
  const success = customSuccess || (!errors[label] && !!watch(label) && watch(label).length > 0);

  return (
    <div
      className={`${styles.formInput} ${error ? styles.formInputError : ''} ${
        success ? styles.formInputSuccessBorder : ''
      }`}
    >
      {title && (
        <label
          htmlFor={title}
          className={` ${error ? styles.formLabelError : ''} ${
            success ? styles.formLabelSuccess : ''
          }`}
        >
          {title}
        </label>
      )}
      <input
        {...register(label, validation)}
        type={type}
        step={step}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        style={style}
      />

      {error && <div className={styles.formInputErrorMessage}>{error?.message}</div>}
    </div>
  );
};

export const HookFormControlled: FC<Props> = ({
  form,
  title,
  label,
  disabled = false,
  type = 'text',
  placeholder = '',
  customError,
  customSuccess,
  style,
  className,
  validation,
  children,
  hasController = true,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const error = customError || errors[label];
  const success = customSuccess || (!!watch(label) && watch(label).length > 0);

  return (
    <div
      className={`${styles.formInput} ${error ? styles.formInputError : ''} ${
        success ? styles.formInputSuccessBorder : ''
      }`}
    >
      {title && (
        <label
          htmlFor={title}
          className={` ${error ? styles.formLabelError : ''} ${
            success ? styles.formLabelSuccess : ''
          }`}
        >
          {title}
        </label>
      )}

      {hasController ? (
        <Controller
          name={label}
          rules={validation}
          control={control}
          render={({ field }) =>
            cloneElement(children as ReactElement<any, string | JSXElementConstructor<any>>, {
              ...field,
            })
          }
        />
      ) : (
        children
      )}

      {error && <div className={styles.formInputErrorMessage}>{error?.message}</div>}
    </div>
  );
};

export const HookFormSelect = ({
  form,
  label,
  title,
  options,
  filterOption,
  optionFilterProp,
  showSearch,
  placeholder,
  validation,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <HookFormControlled form={form} title={title} label={label} hasController={false}>
      <Controller
        name={label}
        control={control}
        rules={validation}
        render={({ field }) => (
          <Select
            {...field}
            status={errors[label] && 'error'}
            mode={
              label === 'contactOwner' || label === 'admissionExamPrep' ? undefined : 'multiple'
            }
            placeholder={placeholder}
            options={options}
            filterOption={filterOption}
            optionFilterProp={optionFilterProp}
            showSearch={showSearch}
          />
        )}
      />
    </HookFormControlled>
  );
};

type Props = {
  title?: string;
  label: Path<any>;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  customError?: FieldError | undefined;
  customSuccess?: boolean;
  style?: React.CSSProperties;
  className?: string;
  validation?: any;
  filterOption?: any;
  options?: any;
  showSearch?: boolean;
  optionFilterProp?: string;
  form: UseFormReturn<any, any>;
  hasController?: boolean;
  step?: string;
};
