import { ApiDescription, ApiData, RequestsMethod, MentorData } from '../types';

//checked
export const setInitialMeeting: ApiDescription = {
  endpoint: '/meeting-scheduler/initial/:enrollmentId',
  method: RequestsMethod.Post,
};

export type SetInitialMeeting = ApiData<{ userId: string; date: string; agenda?: string }, unknown>;

//checked
export const addMentorAvailability: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my/:mentorId',
  method: RequestsMethod.Post,
};

export type AddMentorAvailability = ApiData<
  {
    dayIndex: number;
    interval: number[];
  },
  unknown
>;

//checked
export const getMentorAvailability: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my',
  method: RequestsMethod.Get,
};

export type GetMentorAvailability = ApiData<{ mentorId: string }, MentorData['availability']>;

//checked
export const markMentorAvailabilityDays: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my/mark-days/:mentorId',
  method: RequestsMethod.Patch,
};

export type MarkMentorAvailabilityDays = ApiData<
  {
    dayIndex: number;
  },
  {
    isAvailable: boolean;
    intervals: number[][];
    _id: string;
  }
>;

//checked
export const resetMentorAvailability: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my/reset',
  method: RequestsMethod.Patch,
};

export type ResetMentorAvailability = ApiData<{ mentorId: string }, unknown>;

//checked
export const updateMentorAvailability: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my/:mentorId',
  method: RequestsMethod.Patch,
};

export type UpdateMentorAvailability = ApiData<
  {
    initialInterval: number[];
    newInterval: number[];
    dayIndex: number;
  },
  unknown
>;

//checked
export const deleteMentorAvailability: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/my/:mentorId',
  method: RequestsMethod.Delete,
};

export type DeleteMentorAvailability = ApiData<
  {
    dayIndex: number;
    interval: number[];
  },
  unknown
>;

//checked
export const getMentorAvailabilityInitialMeeting: ApiDescription = {
  endpoint: '/meeting-scheduler/availability/:mentorId',
  method: RequestsMethod.Get,
};

export type GetMentorAvailabilityInitialMeeting = ApiData<never, unknown>;
