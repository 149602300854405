import { npsApis } from '@shared/apis';
import { genericMutation, genericQuery, genericQueryWithParams } from './generics';

export const useCheckPendingNPS = genericQuery<npsApis.CheckPendingNPS>(npsApis.checkPendingNPS);

export const useSubmitNPS = genericMutation<npsApis.SubmitNPS>(npsApis.submitNPS);

export const useCurrentNPS = genericQueryWithParams<npsApis.GetCurrentNPS>(npsApis.getCurrentNPS);

export const useCurrentPlatformNPS = genericQueryWithParams<npsApis.GetCurrentPlatformNPS>(
  npsApis.getCurrentPlatformNPS
);

export const useGenerateCSVforNPS = genericMutation<npsApis.GenerateCSVforNPS>(
  npsApis.generateCSVforNPS
);

export const useCurrentDiagramNPS = genericQueryWithParams<npsApis.GetTrendDiagramData>(
  npsApis.getTrendDiagramData
);

export const useNpsByCategories =
  genericQueryWithParams<npsApis.GetEducationAndPlatformComputedNps>(
    npsApis.getEducationAndPlatformComputedNps
  );

export const useNpsByProblems = genericQueryWithParams<npsApis.GetNPSProblems>(
  npsApis.getNPSProblems
);

export const useGetDetractorsAndPassivesNPS =
  genericQueryWithParams<npsApis.GetDetractorsAndPassivesNPS>(npsApis.getDetractorsAndPassivesNPS);

export const useGetNPSDistribution = genericQueryWithParams<npsApis.GetNPSDistribution>(
  npsApis.getNPSDistribution
);

export const useGetNPSScores = genericQueryWithParams<npsApis.GetNPSScores>(npsApis.getNPSScores);

export const useGetPaginatedNPS = genericQueryWithParams<npsApis.GetPaginatedNPS>(
  npsApis.getPaginatedNPS
);
