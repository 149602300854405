import { ReactComponent as AIIcon } from '@assets/icons/V4/ai-icon.svg';
import ToggleButton from '@components/V4/ToggleButton';
import Tooltip from '@components/V4/Tooltip';
import { BasicProps } from 'apps/agora/src/utils/types';

interface AIGeneratedFeedbackToggleProps extends BasicProps {
  isChecked: boolean;
  didAIFeedbackGenerationFail?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const AIGeneratedFeedbackToggle = (props: AIGeneratedFeedbackToggleProps) => {
  const { isChecked, didAIFeedbackGenerationFail, isDisabled = false, onClick } = props;

  const getTooltipText = () => {
    if (!isDisabled) {
      return undefined;
    }

    if (didAIFeedbackGenerationFail) {
      return 'Could not generate AI feedback.';
    }

    return `We'll send you an email when the feedback is generated.`;
  };

  return (
    <Tooltip placement="top" overlayClassName="max-w-max" text={getTooltipText()}>
      <div className="flex items-center self-end tablet:self-auto">
        <AIIcon className="text-customPrimary" />
        <span className="text-white text-xs font-sans ml-2 mr-4">Enable AI-generated Feedback</span>
        <ToggleButton isDisabled={isDisabled} isChecked={isChecked} onClick={onClick} />
      </div>
    </Tooltip>
  );
};

export default AIGeneratedFeedbackToggle;
