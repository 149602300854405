import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { Input, Row, Card, Button, message } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const { TextArea } = Input;

const MentorAfterMeeting = () => {
  const query = useQuery();
  const meetingId = query.get('meetingId');

  const [workedOnText, setWorkedOnText] = useState('');
  const [wentWellText, setWentWellText] = useState('');
  const [couldImproveText, setCouldImproveText] = useState('');

  const canSubmit = workedOnText && wentWellText && couldImproveText;

  const postFeedback = async () => {
    const dateNow = new Date().toDateString();

    const textToAppend = `\n\n${dateNow} session:\n---\n\nWorked on: ${workedOnText}\n\nWent well: ${wentWellText}\n\nCould improve: ${couldImproveText}`;
    const response = await apiClient.post(`/meeting/${meetingId}/append-student-notes`, {
      text: textToAppend,
    });

    message.success(response.data.message);
    redirectToCalendar();
  };

  const redirectToCalendar = () => {
    const ONE_SECOND = 1000;
    setTimeout(() => {
      window.location.href = '/mentor/calendar';
    }, ONE_SECOND);
  };

  return (
    <Row justify="center">
      <Card style={{ height: '100%', width: '100%', marginTop: '10%' }}>
        <div style={{ margin: '0 auto', maxWidth: '500px', textAlign: 'center' }}>
          <h1>Good job, team!</h1>

          <p>
            Write a bit about how this session went. This information will be stored in the
            student's notes and will be available for other mentors to see.
          </p>
          <b>Worked on:</b>
          <TextArea
            value={workedOnText}
            onChange={({ target: { value } }) => setWorkedOnText(value)}
            placeholder="What did you work on this session?"
          />

          <b>Went well:</b>
          <TextArea
            value={wentWellText}
            onChange={({ target: { value } }) => setWentWellText(value)}
            placeholder="What went well this session?"
          />

          <b>Could improve:</b>
          <TextArea
            value={couldImproveText}
            onChange={({ target: { value } }) => setCouldImproveText(value)}
            placeholder="What could have happened better this session?"
          />

          <Button
            type="primary"
            onClick={postFeedback}
            style={{ marginTop: 10 }}
            disabled={!canSubmit}
          >
            Mark session as complete
          </Button>
        </div>
      </Card>
    </Row>
  );
};

export default MentorAfterMeeting;
