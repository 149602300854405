import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import SecondaryButtonV3 from '@components/V3/SecondaryButtonV3';
import { useGetMentorCalendarMeetings } from '@shared/react';
import { ChecklistItem } from '@shared/types';
import styles from '@styles/components/calendar.module.scss';
import useCalendar from 'apps/agora/src/hooks/useCalendar';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import MeetingButton from '../../Common/Buttons/MeetingButton';
import CalendarItem from './CalendarItem';
import SkeletonBox from '@components/V4/SkeletonBox';

const Calendar = ({
  milestonesForDate,
}: {
  milestonesForDate?: (date: Moment) => ChecklistItem[] | undefined;
}) => {
  const { calendar, goForward, goBackward, goToday, selected } = useCalendar();
  const [displayedMonth, setDisplayedMonth] = useState<Moment>(selected);

  const {
    data: meetings,
    isLoading,
    isError,
  } = useGetMentorCalendarMeetings({ date: moment(selected).toISOString() });

  useEffect(() => {
    setDisplayedMonth(selected);
  }, [selected]);

  if (isError) return <div>Error</div>;

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      className={styles.calendar}
    >
      <div className={styles.calendarHeader}>
        <div className={styles.calendarHeaderInnerContainer}>
          <div className={styles.calendarHeaderItems}>
            <div className={styles.arrowsContainer}>
              <div onClick={goBackward} className={`${styles.arrow} ${styles.arrowLeft}`} />
              <div onClick={goForward} className={`${styles.arrow} ${styles.arrowRight}`} />
            </div>

            <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.calendarDateTitle}>
              {selected.format('MMMM YYYY')}
            </div>

            <SecondaryButtonV3 onClick={goToday} buttonText="Today" />
          </div>
          <div className={styles.newMeetingButtonContainer}>
            <MeetingButton />
          </div>
        </div>
      </div>
      {isLoading ? (
        <section className="grid grid-cols-[repeat(7,1fr)] tablet:grid-cols-7 grid-rows-5 gap-2 overflow-x-auto">
          {[...Array(35).keys()].map((_, index) => (
            <SkeletonBox key={index} className="min-w-17 min-h-28" />
          ))}
        </section>
      ) : (
        <div className={styles.calendarGrid}>
          {calendar.map((week, weekIndex) =>
            week.map((day, dayIndex) => {
              const isToday = day.isSame(new Date(), 'day');
              const milestones = milestonesForDate?.(day) || [];

              return (
                <CalendarItem
                  allMeetings={meetings}
                  isToday={isToday}
                  day={day}
                  milestones={milestones}
                  displayedMonth={displayedMonth}
                  key={`${weekIndex}-${dayIndex}`}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default Calendar;
