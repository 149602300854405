import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { useGetHasMentorEntered } from '@shared/react';
import { useParams } from 'react-router-dom';

type StudentEndMeetingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
};

const StudentEndMeetingModal = (props: StudentEndMeetingModalProps) => {
  const { isOpen, onClose, onPrimaryButtonClick, onSecondaryButtonClick } = props;

  const { meetingId } = useParams<{ meetingId: string }>();

  const { data: hasMentorEntered } = useGetHasMentorEntered(meetingId);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Leave Meeting" className="text-center" />
      <Modal.Body className="flex flex-col justify-center items-center overflow-x-hidden mb-6 text-xs leading-5 text-center">
        <p>Are you sure you want to leave the meeting?</p>
        {hasMentorEntered && (
          <p>
            Please note that if you haven't stayed for at least 15 minutes in the meeting it may be
            recorded as a no-show.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          className="w-[180px]"
          variant="secondary"
          buttonText="Cancel"
          onClick={onSecondaryButtonClick}
        />
        <Button
          className="w-[180px]"
          variant="primary"
          buttonText="Leave the meeting"
          onClick={onPrimaryButtonClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default StudentEndMeetingModal;
