import { useLoginAndRedirectToCourse, useUserCourses } from '@shared/react';
import styles from '@styles/views/courses.module.scss';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { COLORS } from '../../../v3/global';

interface Props {
  setCourseClicked: (value: boolean) => void;
}

const CourseNav: React.FC<Props> = ({ setCourseClicked }) => {
  const { data: courses, isLoading, isError } = useUserCourses();
  const { courseId } = useParams<{ courseId: string }>();

  const { data: URL, mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  if (isLoading || isError) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={styles.nav}
    >
      <div className={styles.wrapper}>
        <div className={styles.list}>
          {(courses ?? []).map((course) => {
            return (
              <Link
                key={course._id}
                to={`/courses/${course._id}`}
                className={`${styles.item} ${courseId === course._id ? styles.selected : ''}`}
                style={{
                  backgroundColor: courseId === course._id ? course?.color : '#F4F4F6',
                  padding: '13.5px',
                  boxShadow: courseId === course._id ? '3px 3px 9px #00000052' : 'inherit',
                }}
                onClick={() => {
                  if (course?.lmsCourseId) {
                    setCourseClicked(true);
                    redirectToLMS({ courseId: course.lmsCourseId });
                  }
                }}
              >
                {course?.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseNav;
