import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface InputLabelProps extends BasicProps {
  htmlFor?: string;
  label: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
}
const InputLabel = (props: InputLabelProps) => {
  const { htmlFor, label, isRequired, isValid, isTouched, isDisabled = false, className } = props;

  return (
    <label
      htmlFor={htmlFor}
      className={mergeClassNames(
        'block my-2 ml-2 w-fit text-customGrey text-xsm',
        {
          'text-customPrimary': isTouched && isValid && !isDisabled,
          'text-customRed': isTouched && !isValid && !isDisabled,
          'text-customGrey': !isTouched && !isDisabled,
        },
        className
      )}
    >
      <p className="text-xxs text-inherit font-semibold">
        {label} {isRequired && <span className="text-customRed">*</span>}
      </p>
    </label>
  );
};

export default InputLabel;
