import Button from '@components/V4/Button';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { ReactNode } from 'react';

interface ResumeFormWrapperProps extends BasicProps {
  title: string;
  contentClassName?: string;
  isModeAddNew: boolean;
  isLoading: boolean;
  icon?: ReactNode;
  onCancel: () => void;
  editButtonHandler: () => void;
  submitHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ProfileFormWrapper = (props: ResumeFormWrapperProps) => {
  const {
    title,
    className,
    contentClassName,
    isModeAddNew,
    children,
    icon,
    onCancel,
    isLoading,
    editButtonHandler,
    submitHandler,
  } = props;

  return (
    <div className={mergeClassNames({ 'px-4 tablet:px-8': !isModeAddNew }, className)}>
      <div
        className={mergeClassNames(
          'flex flex-col gap-4 bg-surfaceHover rounded-lg w-full px-4 py-6 tablet:px-8 ',
          contentClassName
        )}
      >
        <div className="flex gap-4 items-center">
          {icon}
          <h4 className="font-raleway text-xsm font-bold text-customPrimary">
            {isModeAddNew ? 'Add' : 'Edit'} {title}
          </h4>
        </div>

        <div className="flex flex-col">{children}</div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={onCancel} />
          <Button
            buttonText={` ${isModeAddNew ? 'Add' : 'Edit'} ${title}`}
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileFormWrapper;
