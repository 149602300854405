import { ApiDescription, ApiData, RequestsMethod, LMSCourse } from '../types';

export const getAllLMSCourses: ApiDescription = {
  endpoint: '/lms/all-courses',
  method: RequestsMethod.Get,
};

export type GetAllLMSCourses = ApiData<unknown, LMSCourse[]>;

export const getAllLMSCoursesV2: ApiDescription = {
  endpoint: '/lms/all-courses-v2',
  method: RequestsMethod.Get,
};

export type GetAllLMSCoursesV2 = ApiData<unknown, any>;

export const loginAndRedirectToCourse: ApiDescription = {
  endpoint: '/lms/login-and-redirect',
  method: RequestsMethod.Post,
};

export type LoginAndRedirectToCourse = ApiData<{ courseId: string }, { url: string }>;

export const updateUsersWithLMSId: ApiDescription = {
  endpoint: '/lms/update-users-with-lmsid',
  method: RequestsMethod.Post,
};

export type UpdateUsersWithLMSId = ApiData<unknown, unknown>;

export const generateUsersAndEnrollXLS: ApiDescription = {
  endpoint: '/lms/generate-users-and-enroll-xls',
  method: RequestsMethod.Post,
};

export type GenerateUsersAndEnrollXLS = ApiData<unknown, unknown>;

export const generateMentorAndAdminXLS: ApiDescription = {
  endpoint: '/lms/generate-mentor-and-admin-xls',
  method: RequestsMethod.Post,
};

export type GenerateMentorAndAdminXLS = ApiData<unknown, unknown>;

export const cleanUsersFromLMSId: ApiDescription = {
  endpoint: '/lms/clean-users-from-lmsid',
  method: RequestsMethod.Post,
};

export type MakeUsersInstructorsAndEnroll = ApiData<unknown, unknown>;

export const makeUsersInstructorsAndEnroll: ApiDescription = {
  endpoint: '/lms/make-users-instructors-and-enroll',
  method: RequestsMethod.Post,
};

export type CleanUsersFromLMSId = ApiData<unknown, unknown>;

export const syncLmsBlockedUsers: ApiDescription = {
  endpoint: '/lms/sync-lms-blocked-users',
  method: RequestsMethod.Post,
};

export type SyncLmsBlockedUsers = ApiData<unknown, unknown>;

export const createLMSUser: ApiDescription = {
  endpoint: '/lms/create-user',
  method: RequestsMethod.Post,
};

export type CreateLMSUser = ApiData<{ userId: string }, unknown>;

export const syncUsersLmsCourses: ApiDescription = {
  endpoint: '/lms/sync-users-lms-courses',
  method: RequestsMethod.Post,
};

export type SyncUsersLmsCourses = ApiData<unknown, unknown>;
