import { InfoCircleOutlined } from '@ant-design/icons';
import Loading from '@components/Common/Loading';
import { useAuthState, useCourseProgress } from '@shared/react';
import pageStyles from '@styles/pages/page-defaults.module.scss';
import { Typography } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import CourseChapters from './CourseChapters';
import CourseNav from './CourseNav';

const Course: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const getUserId = () => {
    if (impersonateId) return impersonateId;
    return userId;
  };
  const {
    data: courseProgress,
    isLoading,
    isError,
  } = useCourseProgress({
    courseId,
    studentId: getUserId(),
  });

  const [courseClicked, setCourseClicked] = useState(false);

  if (isLoading) {
    return (
      <div>
        <CourseNav setCourseClicked={setCourseClicked} />
        <Loading />
      </div>
    );
  }

  if (courseProgress === undefined || isError) {
    return (
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <InfoCircleOutlined /> An error has occurred!
      </Typography.Paragraph>
    );
  }

  const { course } = courseProgress;

  return (
    <div className="h-full min-h-[calc(100vh-72px)] bg-surfaceObject">
      <CourseNav setCourseClicked={setCourseClicked} />
      <div
        style={{
          background: `transparent linear-gradient(180deg, ${
            course?.color + '12'
          } 0%, #FAFAFAFF 100%) 0% 0% no-repeat padding-box`,
        }}
      >
        <div
          style={{
            padding: '28px 42px',
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
          }}
        >
          <Typography.Title
            level={2}
            style={{
              opacity: 0.6,
              color: COLORS.TEXT_PRIMARY,
            }}
          >
            {course.title}
          </Typography.Title>
          <Typography.Paragraph
            style={{
              opacity: 0.6,
              color: COLORS.TEXT_PRIMARY,
            }}
          >
            {course.subtitle}
          </Typography.Paragraph>
        </div>
      </div>

      <div
        style={{
          backgroundColor: COLORS.COMPONENT_BACKGROUND,
        }}
        className={pageStyles.page}
      >
        {course?.lmsCourseId ? (
          <Typography.Paragraph
            style={{
              opacity: 0.6,
              color: COLORS.TEXT_PRIMARY,
            }}
          >
            {!courseClicked ? (
              <>Select a course to check resources!</>
            ) : (
              <>
                This course has been migrated to the new LMS. You should be redirected in a couple
                of seconds.
                <br />
                If you're not redirected automatically, please check the browser pop-up blocking
                settings or click{' '}
                <a
                  href={'https://lms.upgrade.education/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: COLORS.PRIMARY }}
                >
                  here
                </a>
              </>
            )}
          </Typography.Paragraph>
        ) : (
          <CourseChapters modules={course.modules} progress={courseProgress} />
        )}
      </div>
    </div>
  );
};

export default Course;
