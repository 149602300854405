import { PersonalizedPlanForm } from '@components';
import { HookFormInput } from '@components/Form/HookCustomForm';
import { useCreateNewUser, useExternalData } from '@shared/react';
import { NewUserType } from '@shared/types';
import styles from '@styles/pages/sales-onboarding.module.scss';
import { message, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { COLORS } from '../../v3/global';
import ActivateAccountForm from './ActivateAccountForm';
import CreateUserForm from './CreateUserForm';

const tabs = [
  { name: '1. Create', current: false, key: '0' },
  { name: '2. Plan', current: false, key: '1' },
  { name: '3. Activate', current: false, key: '2' },
];

const SalesToolsPopupPage = () => {
  const [activeTab, setActiveTab] = useState('0');

  const goToNextTab = () => {
    setActiveTab((prev) => `${Number(prev) + 1}`);
  };

  const [creatingUser, setCreatingUser] = useState(false);
  const [id, setId] = useState('');

  const form = useForm<NewUserType>({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = form;

  const { mutate: createNewUser } = useCreateNewUser({
    onSuccess: (newUser) => {
      clearFields();
      setId(newUser.id);
      message.success('User created successfully!');
      setCreatingUser(false);
      goToNextTab();
    },
    onError: (error) => {
      setCreatingUser(false);
    },
  });

  const onSubmit: SubmitHandler<NewUserType> = (data) => {
    setCreatingUser(true);
    createNewUser({ ...data });
  };

  const email = watch('email') || '';
  const emailSuccess = !errors.email && email.length > 0;

  const {
    data: externalUserData,
    isLoading,
    isError,
  } = useExternalData({
    email,
  });

  const clearFields = () => {
    setValue('fullName', '');
    setValue('phoneNumber', '');
    setValue('highSchool', '');
    setValue('graduationYear', undefined);
    setValue('city', '');
    setValue('country', '');
    setValue('ecl', '');
    setValue('contactOwner', '');
    setValue('gpa', undefined);
    setValue('standardisedTests', []);
    setValue('universities', []);
    setValue('admissionExamPrep', []);
    setValue('studySubjects', []);
    setValue('subjectsStudentLikes', []);
    setValue('subjectsStudentDoesntLike', []);
    setValue('learningStyle', []);
    setValue('countries', []);
    setValue('interests', []);
    setValue('parentFullName', '');
    setValue('parentPhoneNumber', '');
    setValue('parentEmail', '');
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    if (externalUserData && !isError && !isLoading && typeof externalUserData !== 'string') {
      message.destroy();
      message.success(`Auto-filled from Hubspot: ${externalUserData.fullName}`);

      Object.entries(externalUserData).forEach(([name, value]: any) => setValue(name, value));

      clearErrors('fullName');
    } else if (!externalUserData && !isError && !isLoading && emailSuccess) {
      clearFields();
    }
  }, [externalUserData, isLoading, isError]);

  const importData = (email: any) => {
    setValue('email', email);
  };

  const checkForImport = (changedFields: any) => {
    if (changedFields?.length !== 1) return;
    if (!changedFields[0]?.name) return;
    if (changedFields[0]?.errors?.length) return;
    if (changedFields[0]?.name[0] !== 'email') return;

    importData(changedFields[0]?.value);
  };

  return (
    <div className={styles.page}>
      <PageHeader ghost={true} title="Sales Onboarding"></PageHeader>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form} onChange={checkForImport}>
        <div>
          <HookFormInput
            form={form}
            label="email"
            placeholder="Add new e-mail address"
            validation={{
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'The input is not valid E-mail!',
              },
            }}
            customSuccess={emailSuccess}
            disabled={creatingUser}
            style={{ padding: '1rem', borderRadius: '12px' }}
          />
        </div>

        {emailSuccess ? (
          <div>
            {/*TAILWIND TABS*/}
            <div style={{ marginBottom: '16px' }}>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: activeTab === tab?.key ? COLORS.BLACK : COLORS.TEXT_SECONDARY,
                          borderColor: activeTab === tab?.key ? COLORS.BLACK : 'inherit',
                        }}
                        key={tab?.key}
                        onClick={() => setActiveTab(tab?.key)}
                        className={classNames(
                          tab?.key === activeTab
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                        )}
                        aria-current={tab?.current ? 'page' : undefined}
                      >
                        {tab?.name}
                      </span>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            {activeTab === '0' && (
              <div className={styles.tab}>
                <CreateUserForm parentForm={form} loading={creatingUser} isMentor={false} />
              </div>
            )}

            {id?.length > 0 && (
              <>
                {activeTab === '1' && (
                  <div className={styles.tab}>
                    <PersonalizedPlanForm email={email} />
                    <div
                      onClick={goToNextTab}
                      style={{
                        textAlign: 'right',
                        padding: '0.5rem 1rem 1rem',
                      }}
                      className="main-green-link"
                    >
                      Enrollments
                    </div>
                  </div>
                )}

                {activeTab === '2' && (
                  <div className={styles.tab}>
                    <ActivateAccountForm initialEmail={email} />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={styles.info}>
            <h2>For a quick onboarding follow the following steps:</h2>
            <ol>
              <li>Enter e-mail adress</li>
              <li>Create user profile by adding details</li>
              <li>Plan his journey by adding courses</li>
              <li>Activate the account </li>
              <li>Active accounts are found in the Admin - Users section</li>
            </ol>
          </div>
        )}
      </form>
    </div>
  );
};

export default SalesToolsPopupPage;
