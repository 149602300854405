import Tabs from '@components/V4/Tabs/Tabs';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import {
  AudioTransmissionQuality,
  Participant,
  VideoTransmissionQuality,
} from '@modules/MeetingVideo/utils/types';
import { useAuthState } from '@shared/react';
import { CommandChannel, CommandChannelMsg, ConnectionState } from '@zoom/videosdk';
import { useContext, useEffect, useRef, useState } from 'react';
import AudioTab from './AudioTab';
import NetworkTab from './NetworkTab';
import ShareTab from './ShareTab';
import VideoTab from './VideoTab';

type ConnectionTab = 'network' | 'audio' | 'video' | 'share';

interface SidebarNetworkPageProps {
  networkQuality?: Record<number, { uplink: number; downlink: number }>;
  connectionState: ConnectionState;
  isScreenBeingShared: boolean;
}

const SidebarConnectionPage = (props: SidebarNetworkPageProps) => {
  const { networkQuality, isScreenBeingShared, connectionState } = props;

  const { isMentor } = useAuthState();

  const [participantsList, setParticipantsList] = useState<Participant[]>();
  const [audioQuality, setAudioQuality] = useState<
    Record<number, Record<'sending' | 'receiving', AudioTransmissionQuality>>
  >({});
  const [videoQuality, setVideoQuality] = useState<
    Record<number, Record<'sending' | 'receiving', VideoTransmissionQuality>>
  >({});
  const [shareQuality, setShareQuality] = useState<
    Record<number, Record<'sending' | 'receiving', VideoTransmissionQuality>>
  >({});

  const commandChannelRef = useRef<typeof CommandChannel>();

  const { zoomClient, meetingDetails, isMeetingLoading } = useContext(ZoomContext);

  const getParticipantsList = () => {
    if (!meetingDetails) return;

    const currentZoomParticipants = zoomClient?.getAllUser();

    const participants = [
      ...meetingDetails.students.map((student) => ({
        ...student,
        role: 'Student',
      })),
    ];

    if (isMentor) {
      participants.unshift({ ...meetingDetails.mentor, role: 'Mentor' });
    }

    return participants
      .map((participant) => {
        const zoomParticipant = currentZoomParticipants?.find(
          (zp) => participant._id === zp.userIdentity
        );

        if (zoomParticipant) {
          return { ...participant, zoomUserId: zoomParticipant.userId };
        } else {
          return null;
        }
      })
      .filter((participant) => participant !== null);
  };

  useEffect(() => {
    if (isMeetingLoading || !zoomClient || connectionState !== ConnectionState.Connected) return;

    const myUserId = zoomClient?.getSessionInfo().userId;
    commandChannelRef.current = zoomClient.getCommandClient();

    setParticipantsList(getParticipantsList());

    const updateParticipantsHandler = () => {
      setParticipantsList(getParticipantsList());
    };

    const audioQualityChangeHandler = (payload: any) => {
      const data = payload.data;

      const direction = data.encoding ? 'sending' : 'receiving';

      const audioQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        direction,
        type: 'audio-quality-change',
      };

      setAudioQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: audioQuality,
        },
      }));

      const stringifiedAudioQuality = JSON.stringify(audioQuality);

      commandChannelRef.current?.send(stringifiedAudioQuality);
    };

    const videoQualityChangeHandler = (payload: any) => {
      const data = payload.data;

      const direction = data.encoding ? 'sending' : 'receiving';

      const videoQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        width: data.width,
        height: data.height,
        fps: data.fps,
        direction,
        type: 'video-quality-change',
      };

      setVideoQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: videoQuality,
        },
      }));

      const stringifiedVideoQuality = JSON.stringify(videoQuality);

      commandChannelRef.current?.send(stringifiedVideoQuality);
    };

    const shareScreenQualityChangeHandler = (payload: any) => {
      const data = payload.data;
      const direction = data.encoding ? 'sending' : 'receiving';

      const shareQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        width: data.width,
        height: data.height,
        fps: data.fps,
        direction,
        type: 'share-quality-change',
      };

      setShareQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: shareQuality,
        },
      }));

      const stringifiedShareQuality = JSON.stringify(shareQuality);

      commandChannelRef.current?.send(stringifiedShareQuality);
    };

    const commandChannelHandler = (payload: CommandChannelMsg) => {
      try {
        const parsedPayload = JSON.parse(payload.text);

        switch (parsedPayload.type) {
          case 'audio-quality-change':
            setAudioQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
          case 'video-quality-change':
            setVideoQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
          case 'share-quality-change':
            setShareQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
        }
      } catch (error) {
        return;
      }
    };

    zoomClient.on('user-added', updateParticipantsHandler);
    zoomClient.on('user-removed', updateParticipantsHandler);
    zoomClient.on(`command-channel-message`, commandChannelHandler);
    zoomClient.on('video-statistic-data-change', videoQualityChangeHandler);
    zoomClient.on('audio-statistic-data-change', audioQualityChangeHandler);
    zoomClient.on('share-statistic-data-change', shareScreenQualityChangeHandler);

    return () => {
      zoomClient?.off('user-added', updateParticipantsHandler);
      zoomClient?.off('user-removed', updateParticipantsHandler);
      zoomClient.off(`command-channel-message`, commandChannelHandler);
      zoomClient.off('video-statistic-data-change', videoQualityChangeHandler);
      zoomClient.off('audio-statistic-data-change', audioQualityChangeHandler);
      zoomClient.off('share-statistic-data-change', shareScreenQualityChangeHandler);
    };
  }, [isMeetingLoading, connectionState]);

  const participantsLength = zoomClient?.getAllUser().length || 0;

  const tabs: { label: string; value: ConnectionTab }[] = [
    { label: 'Network', value: 'network' },
    {
      label: 'Audio',
      value: 'audio',
    },
    { label: 'Video', value: 'video' },
    { label: 'Share', value: 'share' },
  ];

  const renderContent = (currentTab: ConnectionTab) => {
    switch (currentTab) {
      case 'network':
        return <NetworkTab participantsList={participantsList} networkQuality={networkQuality} />;
      case 'audio':
        return (
          <AudioTab
            hasEmptyState={participantsLength < 2}
            participantsList={participantsList}
            audioQuality={audioQuality}
          />
        );
      case 'video':
        return (
          <VideoTab
            hasEmptyState={participantsLength < 2}
            participantsList={participantsList}
            videoQuality={videoQuality}
          />
        );
      case 'share':
        return (
          <ShareTab
            hasEmptyState={!isScreenBeingShared}
            participantsList={participantsList}
            shareQuality={shareQuality}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Tabs
      className="h-[calc(100%-48px)]"
      contentWrapperClassName="overflow-auto max-h-[calc(100%-88px)] tablet:max-h-[calc(100%-48px)]"
      tabs={tabs}
      initialTab="network"
      renderContent={renderContent}
    />
  );
};

export default SidebarConnectionPage;
