import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './course-card.styles.scss';

type Props = {
  title?: string;
  subtitle?: string;
  color?: string;
  image?: any;
  imageWidth?: any;
  imageHeight?: any;
};

const CourseCard = ({ title, subtitle, color, image, imageWidth, imageHeight }: Props) => {
  const [hover, setHover] = useState(false);

  const getCourseImage = () => {
    if (image) {
      return image;
    }

    if (title?.toLowerCase().includes('coach') || title?.toLowerCase().includes('training')) {
      return USBigPrimary;
    }

    if (title?.toLowerCase().includes('academic')) {
      return USBigPurple;
    }
    return USBigGold;
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: hover ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
        padding: '0px',
      }}
      className="course-card-container"
    >
      <div style={{ width: '100%' }} className="course-card-content">
        <img
          className="course-image"
          style={{ width: imageWidth ?? '', height: imageHeight ?? '' }}
          src={getCourseImage()}
          alt="course"
          crossOrigin={'anonymous'}
        />

        <div
          style={{
            height: '100%',
            width: '60%',
          }}
          className="course-card-text-container"
        >
          <div className="course-card-title">
            <div
              style={{
                color: COLORS.TEXT_PRIMARY,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <span>{title}</span>
            </div>
          </div>

          <div className="course-card-subtitle">
            <div
              style={{
                color: COLORS.TEXT_SECONDARY,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 'calc(100%)',
                textOverflow: 'ellipsis',
              }}
            >
              <span>{subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
