import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateMentorCapacity } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../types';
import ProfileFormWrapper from '../ProfileFormWrapper';

interface CapacityTabFormProps extends ExamFormProps {
  maxEnrollments?: number;
  currentNoEnrollments?: number;
}

const formDataSchema = z.object({
  maxEnrollments: z.number().min(0, { message: 'Negative numbers are not allowed.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const CapacityTabForm = (props: CapacityTabFormProps) => {
  const { userId, maxEnrollments, currentNoEnrollments, setIsEditMode, setIsAddNewItem, onCancel } =
    props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    reset,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      maxEnrollments: maxEnrollments,
    },
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateMentorCapacity, isLoading } = useUpdateMentorCapacity(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateMentorCapacity({ capacity: data.maxEnrollments });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (maxEnrollments) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <ProfileFormWrapper
      title="Maximum Number Of Enrolments"
      isModeAddNew={false}
      onCancel={onCancel}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Input
        type="number"
        id={'maxEnrollments'}
        placeholder={''}
        label={'Maximum Enrollments'}
        isRequired
        isTouched={touchedFields.maxEnrollments}
        isValid={!errors.maxEnrollments}
        errorText={errors.maxEnrollments?.message}
        {...register('maxEnrollments', { required: true, valueAsNumber: true })}
      />
      <p className="text-sm text-customGrey mt-4">
        Current number of Enrollments: {currentNoEnrollments}
      </p>
    </ProfileFormWrapper>
  );
};

export default CapacityTabForm;
