import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { subjectsOptions, User } from '@shared/common';
import { useController, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../types';
import ProfileFormWrapper from '../ProfileFormWrapper';

interface SubjectsCanTeachFormProps extends ExamFormProps {
  majors?: string[];
  updateMyMentorData: UseMutateFunction<User, Error, Partial<any>, unknown>;
  isLoading: boolean;
}

const formDataSchema = z.object({
  subjectsCanTeach: z.string().refine((value) => subjectsOptions.includes(value), {
    message: 'Please select a valid subject.',
  }),
});

type FormData = z.infer<typeof formDataSchema>;

const SubjectsCanTeachForm = (props: SubjectsCanTeachFormProps) => {
  const {
    userId,
    setIsEditMode,
    majors,
    setIsAddNewItem,
    onCancel,
    updateMyMentorData,
    isLoading,
  } = props;

  const majorsOptions = subjectsOptions.filter((major: string) => {
    return !majors?.find((m: string) => m === major);
  });

  const { handleSubmit, trigger, setValue, getValues, reset, control } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: subjectsCanTeachField, fieldState: subjectsCanTeachState } = useController({
    name: 'subjectsCanTeach',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && majors) {
      updateMyMentorData({
        majors: [...majors, data.subjectsCanTeach],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <ProfileFormWrapper
      title="Subject"
      isModeAddNew={true}
      onCancel={onCancel}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Select
        options={majorsOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        onSelect={subjectsCanTeachField.onChange}
        onBlur={subjectsCanTeachField.onBlur}
        value={subjectsCanTeachField.value}
        size="large"
        id={'subjects'}
        label={'Subjects'}
        isRequired
        allowClear={false}
        isTouched={subjectsCanTeachState.isTouched}
        isValid={!subjectsCanTeachState.error}
        errorText={subjectsCanTeachState.error?.message}
      />
    </ProfileFormWrapper>
  );
};

export default SubjectsCanTeachForm;
