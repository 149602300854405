import { mentorApis, usersApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useGetMentorReliabilityScoreHistory =
  genericQueryWithParams<mentorApis.GetMentorMRSHistory>(mentorApis.getMentorMRSHistory);

export const useDeleteMentorRaliabilityScore = genericMutationWithId<mentorApis.DeleteMentorMRS>(
  mentorApis.deleteMentorMRS
);

export const useUpdateMentorRaliabilityScore =
  genericMutationWithId<mentorApis.UpdateMentorMRSHistory>(mentorApis.updateMentorMRSHistory);

export const useGetDefaultMentorReliabilityScore =
  genericQuery<mentorApis.GetDefaultMentorReliabilityScore>(
    mentorApis.getDefaultMentorReliabilityScore
  );

export const useUpdateDefaultReliabilityScore = genericMutation<mentorApis.UpdateDefaultMRS>(
  mentorApis.updateDefaultMRS
);

export const useDeleteDefaultReliabilityScore = genericMutation<mentorApis.DeleteDefaultMRS>(
  mentorApis.deleteDefaultMRS
);

export const useUpdateMentorCapacity = genericMutationWithId<mentorApis.UpdateMentorCapacity>(
  mentorApis.updateMentorCapacity,
  usersApis.getUserProfile.endpoint
);

export const useGetMentorCapacity = genericQueryWithId<mentorApis.GetMentorCapacity>(
  mentorApis.getMentorCapacity
);

export const useGetMentorWidgetStatistics = genericQuery<mentorApis.GetMentorWidgetStatistics>(
  mentorApis.getMentorWidgetStatistics
);

export const useSearchMentors = genericQueryWithParams<mentorApis.SearchMentors>(
  mentorApis.searchMentors
);

export const useGetAllCoursesCanTeach = genericQueryWithId<mentorApis.GetAllCoursesCanTeach>(
  mentorApis.getAllCoursesCanTeach
);

export const useReorderCoursesCanTeach =
  genericMutationWithParams<mentorApis.ReorderCoursesCanTeach>(
    mentorApis.reorderCoursesCanTeach,
    mentorApis.getAllCoursesCanTeach.endpoint
  );
