import NPSDetractorsAndPassivesCard from '@components/V3/NPSDetractorsAndPassives';
import NPSMonthSelector from '@components/V3/NPSMonthSelector';
import NPSOverviewCard from '@components/V3/NPSOverviewCard';
import NPSProblemsCard from '@components/V3/NPSProblemsCard';
import {
  useGetDetractorsAndPassivesNPS,
  useGetNPSDistribution,
  useGetNPSScores,
  useNpsByCategories,
  useNpsByProblems,
} from '@shared/react';

import AgoraSpinner from '@utils/AgoraSpinner';
import React, { useEffect, useRef, useState } from 'react';

import { COLORS } from '../../../v3/global';

import './nps-dashboard.styles.scss';
import NPSLineChart from '@components/V3/NPSLineChart';
import NPSDistributionCard from '@components/V3/NPSDistributionCard';

interface NPSDetractorsAndPassivesData {
  studentEduDetractors: any;
  studentEduPassives: any;
  mentorEduDetractors: any;
  mentorEduPassives: any;
  parentEduDetractors: any;
  parentEduPassives: any;
  studentPlatformDetractors: any;
  studentPlatformPassives: any;
  mentorPlatformDetractors: any;
  mentorPlatformPassives: any;
}

interface NPSDistributionData {
  studentEdu: any;
  mentorEdu: any;
  parentEdu: any;
  studentPlatform: any;
  mentorPlatform: any;
}

interface NPSScoresData {
  eduTrendPoints: any;
  platformTrendPoints: any;
}

const NPSDashboard = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handlePrevMonth = () => {
    const prevMonth = new Date(selectedDate);
    prevMonth.setMonth(selectedDate.getMonth() - 1);
    setSelectedDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(selectedDate);
    nextMonth.setMonth(selectedDate.getMonth() + 1);
    setSelectedDate(nextMonth);
  };

  const { data: npsOverviewData, isLoading: isLoadingNpsOverviewData } = useNpsByCategories({
    date: selectedDate.toISOString(),
  });

  const { data: npsProblemsData, isLoading: isLoadingNpsProblems } = useNpsByProblems({
    date: selectedDate.toISOString(),
  });

  const { data: npsDetractorsAndPassivesDataRaw, isLoading: isLoadingDetractorsAndPassivesData } =
    useGetDetractorsAndPassivesNPS({
      date: selectedDate.toISOString(),
    });

  const npsDetractorsAndPassivesData =
    npsDetractorsAndPassivesDataRaw as NPSDetractorsAndPassivesData;

  const { data: npsDistributionDataRaw, isLoading: isLoadingNpsDistributionData } =
    useGetNPSDistribution({
      date: selectedDate.toISOString(),
    });

  const npsDistributionData = npsDistributionDataRaw as NPSDistributionData;

  const { data: npsScoresDataRaw, isLoading: isLoadingScoresData } = useGetNPSScores({
    date: selectedDate.toISOString(),
  });

  const npsScoresData = npsScoresDataRaw as NPSScoresData;

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setContainerWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoadingNpsOverviewData]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
          color: COLORS.TEXT_PRIMARY,
          minHeight: 'calc(100vh - 74px)',
          width: '100%',
        }}
        className="nps-dashboard-content-container"
      >
        {/*NPS PAGE TITLE AND MONTH SELECTOR*/}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!isLoadingNpsOverviewData && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: containerWidth,
              }}
            >
              <div></div>
              <div>
                <NPSMonthSelector
                  selectedDate={selectedDate}
                  nextMonthHandler={handleNextMonth}
                  prevMonthHandler={handlePrevMonth}
                />
              </div>
            </div>
          )}
        </div>

        {/*NPS OVERVIEW*/}
        <div style={{ marginTop: '40px' }}>
          <div>
            {isLoadingNpsOverviewData ? (
              <div
                style={{
                  height: '182px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <AgoraSpinner fontSize={40} color={COLORS.WHITE} />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  ref={containerRef}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(224px, 1fr))',
                    gap: '24px',
                    alignItems: 'flex-start',
                    width: '1450px',
                    boxSizing: 'border-box',
                  }}
                >
                  <div
                    style={{
                      flex: '1',
                      maxWidth: '290px',
                    }}
                  >
                    <h3
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                        marginBottom: '16px',
                        marginLeft: '24px',
                        marginTop: '-40px',
                        whiteSpace: 'nowrap',
                      }}
                      className="nps-dashboard-section-title"
                    >
                      Overview
                    </h3>

                    <NPSOverviewCard
                      title={
                        <h4
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                            marginBottom: '24px',
                          }}
                          className="nps-overview-card-title"
                        >
                          Edu <span style={{ color: COLORS.TEXT_PRIMARY }}>Student</span> NPS
                        </h4>
                      }
                      info={npsOverviewData}
                      type="studentEdu"
                    />
                  </div>
                  <div
                    style={{
                      // marginRight: '24px',
                      flex: '1',
                      maxWidth: '290px',
                    }}
                  >
                    <NPSOverviewCard
                      title={
                        <h4
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                            marginBottom: '24px',
                          }}
                          className="nps-overview-card-title"
                        >
                          Edu <span style={{ color: COLORS.TEXT_PRIMARY }}>Mentor</span> NPS
                        </h4>
                      }
                      info={npsOverviewData}
                      type="mentorEdu"
                    />
                  </div>
                  <div
                    style={{
                      // marginRight: '24px',
                      flex: '1',
                      maxWidth: '290px',
                    }}
                  >
                    <NPSOverviewCard
                      title={
                        <h4
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                            marginBottom: '24px',
                          }}
                          className="nps-overview-card-title"
                        >
                          Edu <span style={{ color: COLORS.TEXT_PRIMARY }}>Parent</span> NPS
                        </h4>
                      }
                      info={npsOverviewData}
                      type="parentEdu"
                    />
                  </div>
                  <div
                    style={{
                      // marginRight: '24px',
                      flex: '1',
                      maxWidth: '290px',
                    }}
                  >
                    <NPSOverviewCard
                      title={
                        <h4
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                            marginBottom: '24px',
                          }}
                          className="nps-overview-card-title"
                        >
                          Agora <span style={{ color: COLORS.TEXT_PRIMARY }}>Student</span> NPS
                        </h4>
                      }
                      info={npsOverviewData}
                      type="studentPlatform"
                    />
                  </div>
                  <div
                    style={{
                      // marginRight: '24px',
                      flex: '1',
                      maxWidth: '290px',
                    }}
                  >
                    <NPSOverviewCard
                      title={
                        <h4
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                            marginBottom: '24px',
                          }}
                          className="nps-overview-card-title"
                        >
                          Agora <span style={{ color: COLORS.TEXT_PRIMARY }}>Mentor</span> NPS
                        </h4>
                      }
                      info={npsOverviewData}
                      type="mentorPlatform"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/*NPS PROBLEMS*/}
        <div style={{ marginBottom: '24px', marginTop: '40px' }}>
          {isLoadingNpsProblems ? (
            <div
              style={{
                height: '182px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <AgoraSpinner fontSize={40} color={COLORS.WHITE} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(224px, 1fr))',
                  gap: '24px',
                  alignItems: 'flex-start',
                  width: '1450px',
                  boxSizing: 'border-box',
                }}
              >
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <h3
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginLeft: '24px',
                      marginBottom: '16px',
                      marginTop: '-40px',
                      whiteSpace: 'nowrap',
                    }}
                    className="nps-dashboard-section-title"
                  >
                    Problems
                  </h3>
                  <NPSProblemsCard
                    problemTitle="Edu"
                    problemRole="Student"
                    statistics={npsProblemsData?.studentEdu}
                    problemType="education"
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    flex: '1',
                    minWidth: '224px',
                  }}
                >
                  <NPSProblemsCard
                    problemTitle="Edu"
                    problemRole="Mentor"
                    statistics={npsProblemsData?.mentorEdu}
                    problemType="education"
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSProblemsCard
                    problemTitle="Edu"
                    problemRole="Parent"
                    statistics={npsProblemsData?.parentEdu}
                    problemType="education"
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSProblemsCard
                    problemTitle="Agora"
                    problemRole="Student"
                    statistics={npsProblemsData?.studentPlatform}
                    problemType="platform"
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSProblemsCard
                    problemTitle="Agora"
                    problemRole="Mentor"
                    statistics={npsProblemsData?.mentorPlatform}
                    problemType="platform"
                    date={selectedDate.toISOString()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/*NPS DISTRIBUTION*/}
        <div style={{ marginBottom: '24px', marginTop: '40px' }}>
          {isLoadingNpsDistributionData ? (
            <div
              style={{
                height: '182px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <AgoraSpinner fontSize={40} color={COLORS.WHITE} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(224px, 1fr))',
                  gap: '24px',
                  alignItems: 'flex-start',
                  width: '1450px',
                  boxSizing: 'border-box',
                }}
              >
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <h3
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginLeft: '24px',
                      marginBottom: '16px',
                      marginTop: '-40px',
                      whiteSpace: 'nowrap',
                    }}
                    className="nps-dashboard-section-title"
                  >
                    Distribution
                  </h3>
                  <NPSDistributionCard
                    problemTitle="Edu"
                    problemRole="Student"
                    statistics={npsDistributionData?.studentEdu}
                    problemType="education"
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDistributionCard
                    problemTitle="Edu"
                    problemRole="Mentor"
                    statistics={npsDistributionData.mentorEdu}
                    problemType="education"
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDistributionCard
                    problemTitle="Edu"
                    problemRole="Parent"
                    statistics={npsDistributionData.parentEdu}
                    problemType="education"
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDistributionCard
                    problemTitle="Agora"
                    problemRole="Student"
                    statistics={npsDistributionData.studentPlatform}
                    problemType="platform"
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDistributionCard
                    problemTitle="Agora"
                    problemRole="Mentor"
                    statistics={npsDistributionData.mentorPlatform}
                    problemType="platform"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/*DETRACTORS AND PASSIVES*/}
        <div style={{ marginBottom: '24px', marginTop: '40px' }}>
          {isLoadingDetractorsAndPassivesData ? (
            <div
              style={{
                height: '182px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <AgoraSpinner fontSize={40} color={COLORS.WHITE} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(224px, 1fr))',
                  gap: '24px',
                  alignItems: 'flex-start',
                  width: '1450px',
                  boxSizing: 'border-box',
                }}
              >
                <div
                  style={{
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <h3
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginLeft: '24px',
                      marginBottom: '16px',
                      marginTop: '-40px',
                      whiteSpace: 'nowrap',
                    }}
                    className="nps-dashboard-section-title"
                  >
                    Detractors & Passives
                  </h3>
                  <NPSDetractorsAndPassivesCard
                    problemTitle="Edu"
                    problemRole="Student"
                    detractors={npsDetractorsAndPassivesData.studentEduDetractors}
                    passives={npsDetractorsAndPassivesData.studentEduPassives}
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDetractorsAndPassivesCard
                    problemTitle="Edu"
                    problemRole="Mentor"
                    detractors={npsDetractorsAndPassivesData.mentorEduDetractors}
                    passives={npsDetractorsAndPassivesData.mentorEduPassives}
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDetractorsAndPassivesCard
                    problemTitle="Edu"
                    problemRole="Parent"
                    detractors={npsDetractorsAndPassivesData.parentEduDetractors}
                    passives={npsDetractorsAndPassivesData.parentEduPassives}
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    // marginRight: '24px',
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDetractorsAndPassivesCard
                    problemTitle="Agora"
                    problemRole="Student"
                    detractors={npsDetractorsAndPassivesData.studentPlatformDetractors}
                    passives={npsDetractorsAndPassivesData.studentPlatformPassives}
                    date={selectedDate.toISOString()}
                  />
                </div>
                <div
                  style={{
                    marginBottom: '16px',
                    flex: '1',
                    maxWidth: '290px',
                  }}
                >
                  <NPSDetractorsAndPassivesCard
                    problemTitle="Agora"
                    problemRole="Mentor"
                    detractors={npsDetractorsAndPassivesData.mentorPlatformDetractors}
                    passives={npsDetractorsAndPassivesData.mentorPlatformPassives}
                    date={selectedDate.toISOString()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/*EDUCATION NPS*/}
        <div style={{ marginBottom: '24px' }}>
          {isLoadingScoresData ? (
            <div
              style={{
                height: '182px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <AgoraSpinner fontSize={40} color={COLORS.WHITE} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: containerWidth }}>
                <NPSLineChart
                  title="Education NPS"
                  trendPoints={npsScoresData.eduTrendPoints}
                  type="education"
                />
              </div>

              <div style={{ width: containerWidth }}>
                <NPSLineChart
                  title="Agora NPS"
                  trendPoints={npsScoresData.platformTrendPoints}
                  type="platform"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NPSDashboard;
