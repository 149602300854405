import { useAllMyMeetings, useAuthState, useStudentChecklistArrayForMentor } from '@shared/react';
import { ChecklistItem } from '@shared/types';
import { Spin } from 'antd';
import { Moment } from 'moment';
import Calendar from './Calendar';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

const MentorCalendar = () => {
  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const { data: studentChecklists } = useStudentChecklistArrayForMentor(impersonateId ?? userId);

  const studentChecklistItems =
    studentChecklists && Array.isArray(studentChecklists) && studentChecklists.length > 0
      ? studentChecklists?.reduce(
          (acc: ChecklistItem[], curr: any) => [...acc, ...curr.checklist.items],
          []
        )
      : [];

  const milestonesForDate = (date: Moment) => {
    return studentChecklistItems?.filter((item) => date.isSame(item.deadline, 'date'));
  };

  return <Calendar milestonesForDate={milestonesForDate} />;
};

export default MentorCalendar;
