import { ReactComponent as WarningIcon } from '@assets/icons/toast-icons/warning.svg';
import Button from '@components/V4/Button';
import InfoBar from '@components/V4/InfoBar';
import Select from '@components/V4/Select/Select';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import useLocalTracks from '@modules/MeetingVideo/hooks/useLocalTracks';
import { useAuthState } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useContext, useRef, useEffect } from 'react';
import VideoWidget from './VideoWidget';

const VideoMeetingPreview = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { avatar } = useAuthState();
  const {
    zoomClient,
    activeCamera,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    setActiveCamera,
    setActiveMicrophone,
    setIsMicrophoneActive,
    setIsCameraActive,
  } = useContext(ZoomContext);

  const [showToast] = useToast({ duration: 'infinite' });

  useEffect(() => {
    if (!zoomClient) return;

    zoomClient
      .init('en-US', 'Global', {
        patchJsMedia: true,
        stayAwake: true,
        enforceMultipleVideos: true,
        leaveOnPageUnload: true,
        enforceVirtualBackground: true,
      })
      .catch((error) => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: `Zoom SDK initialization failed: ${error.message}`,
        });
      });
  }, []);

  const {
    error: localTracksError,
    camerasWithErrors,
    microphonesWithErrors,
    isMicrophoneSwitchLoading,
    isCameraSwitchLoading,
    isCameraStateSwitchLoading,
    isMicrophoneStateSwitchLoading,
    micList,
    cameraList,
    retryInitializeDevices,
    toggleMicrophone,
    toggleCamera,
    changeMicrophone,
    changeCamera,
  } = useLocalTracks({
    videoRef,
    isCameraActive,
    isMicrophoneActive,
    activeCamera,
    activeMicrophone,
    onActiveMicrophoneChange: setActiveMicrophone,
    onActiveCameraChange: setActiveCamera,
    onSetIsCameraActive: setIsCameraActive,
    onSetIsMicrophoneActive: setIsMicrophoneActive,
  });

  const microphonesWithErrorCount = Object.keys(microphonesWithErrors).length;
  const camerasWithErrorCount = Object.keys(camerasWithErrors).length;

  const didAllMicrophonesFail =
    !!microphonesWithErrorCount && microphonesWithErrorCount === micList.length;
  const didAllCamerasFail = !!camerasWithErrorCount && camerasWithErrorCount === cameraList.length;

  const shouldShowRetryButton = didAllMicrophonesFail || didAllCamerasFail;

  return (
    <div className="flex flex-col gap-5 laptop:order-2 laptop:min-w-1/2 laptop:w-1/2 laptop:max-w-[calc(50%-clamp(4rem,7.2vw,6.5rem)/2)]">
      <VideoWidget
        onCameraClick={() => toggleCamera(isCameraActive)}
        onMicrophoneClick={() => toggleMicrophone(isMicrophoneActive)}
        ref={videoRef}
        isCameraButtonLoading={isCameraStateSwitchLoading}
        isMicrophoneButtonLoading={isMicrophoneStateSwitchLoading}
        isCameraActive={isCameraActive}
        isMicrophoneActive={isMicrophoneActive}
        isCameraDisabled={!cameraList.length}
        isMicrophoneDisabled={!micList.length}
        avatarUrl={avatar}
      />
      <div className="hidden flex-row gap-5 justify-between laptop:flex laptop:w-full">
        <Select
          isValid
          isTouched
          options={micList.map((item) => ({
            label: item.label,
            value: item.deviceId,
            icon: microphonesWithErrors[item.deviceId] && (
              <WarningIcon className="min-w-3 h-3 w-3 ml-2" />
            ),
          }))}
          value={activeMicrophone}
          onSelect={(value) => changeMicrophone(value as string)}
          label="Audio Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isLoading={isMicrophoneSwitchLoading}
          isDisabled={
            !micList.length || isMicrophoneSwitchLoading || isMicrophoneStateSwitchLoading
          }
        />
        <Select
          isValid
          isTouched
          options={cameraList.map((item) => ({
            label: item.label,
            value: item.deviceId,
            icon: camerasWithErrors[item.deviceId] && (
              <WarningIcon className="min-w-3 h-3 w-3 ml-2" />
            ),
          }))}
          value={activeCamera}
          onSelect={(value) => changeCamera(value as string)}
          label="Video Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isLoading={isCameraSwitchLoading}
          isDisabled={!cameraList.length || isCameraSwitchLoading || isCameraStateSwitchLoading}
        />
      </div>
      <InfoBar
        variant="info"
        messageBody="Your virtual background and noise suppression settings can be customized in the meeting settings modal after you join. Any changes you make there will be saved and applied to future meetings as well!"
      />
      {shouldShowRetryButton && (
        <Button
          buttonText="Retry"
          size="large"
          isLoading={isCameraStateSwitchLoading || isMicrophoneStateSwitchLoading}
          onClick={retryInitializeDevices}
        />
      )}
      {localTracksError && (
        <InfoBar
          variant="error"
          messageTitle={localTracksError.message}
          messageBody="Please enable Camera and Microphone permissions and reload the page."
        />
      )}
      {Object.keys(camerasWithErrors).map((cameraId) => (
        <InfoBar
          key={`camera-error-${cameraId}`}
          variant="error"
          messageTitle={camerasWithErrors[cameraId].message}
          messageBody={`Couldn't start camera ${
            camerasWithErrors[cameraId]?.deviceLabel || ''
          }, please select another Video Device.`}
        />
      ))}
      {Object.keys(microphonesWithErrors).map((microphoneId) => (
        <InfoBar
          key={`microphone-error-${microphoneId}`}
          variant="error"
          messageTitle={microphonesWithErrors[microphoneId].message}
          messageBody={`Couldn't start microphone ${
            microphonesWithErrors[microphoneId]?.deviceLabel || ''
          }, please select another Input Audio Device.`}
        />
      ))}
    </div>
  );
};

export default VideoMeetingPreview;
