import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import { ReactComponent as AudioSettingsIcon } from '@assets/icons/video-icons/audio-settings.svg';
import { ReactComponent as ExpandArrowIcon } from '@assets/icons/video-icons/expand-arrow.svg';
import { ReactComponent as VideoSettingsIcon } from '@assets/icons/video-icons/video-settings.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import Modal, { ModalProps } from '@components/V4/Modal/Modal';
import Select from '@components/V4/Select/Select';
import ToggleButton from '@components/V4/ToggleButton';
import VideoWidget from '@modules/MeetingVideo/components/VideoWaitingRoom/VideoWidget';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import useLocalTracks from '@modules/MeetingVideo/hooks/useLocalTracks';
import { canStartCamera, getStartVideoToastErrors } from '@modules/MeetingVideo/utils/helpers';
import {
  useGetVirtualBackgrounds,
  useAddVirtualBackground,
  useDeleteVirtualBackground,
  useUpdateVideoSettings,
} from '@shared/react';
import { MediaDevice, VideoPlayer as VideoPlayerType } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useState, ChangeEvent, useRef, useEffect, useCallback } from 'react';
import VideoPlayerContainer from '../../VideoPlayerContainer';
import { startVideo } from '../../helpers';
import MicrophoneLevelIcon from './MicrophoneLevelIcon';

interface SettingsModalProps extends ModalProps {
  micList: MediaDevice[];
  cameraList: MediaDevice[];
  isSharing?: boolean;
  backgroundSuppression: boolean;
  virtualBackground?: string;
  onBackgroundSuppressionChange: (value: boolean) => void;
  onVirtualBackgroundChange: (value?: string) => void;
}

const SettingsModal = (props: SettingsModalProps) => {
  const {
    isOpen,
    micList,
    cameraList,
    isSharing,
    backgroundSuppression,
    virtualBackground,
    onBackgroundSuppressionChange,
    onVirtualBackgroundChange,
    onClose,
  } = props;

  const {
    zoomClient,
    activeCamera,
    activeMicrophone,
    stream,
    isCameraActive,
    setActiveCamera,
    setActiveMicrophone,
  } = useContext(ZoomContext);

  const [isAudioSectionCollapsed, setIsAudioSectionCollapsed] = useState(false);
  const [isVideoSectionCollapsed, setIsVideoSectionCollapsed] = useState(true);
  const [isVirtualBackgroundSupported, setIsVirtualBackgroundSupported] = useState(
    stream?.isSupportVirtualBackground()
  );
  const [isCameraSwitchLoading, setIsCameraSwitchLoading] = useState(false);
  const [isMicrophoneSwitchLoading, setIsMicrophoneSwitchLoading] = useState(false);
  const [shouldRenderCanvas, setShouldRenderCanvas] = useState(true);
  const [isCanvasLoading, setIsCanvasLoading] = useState(false);
  const [isShareTabAudioToggleLoading, setIsShareTabAudioToggleLoading] = useState(false);

  const videoPreviewRef = useRef<VideoPlayerType | null>(null);
  const isChangingVirtualBackground = useRef(false);
  const isChangingNoiseSuppression = useRef(false);
  const localVideoPreviewRef = useRef<HTMLVideoElement | null>(null);

  const { data: virtualBackgrounds } = useGetVirtualBackgrounds();

  const { mutate: addVirtualBackground, isLoading: isUpdatingVirtualBackgrounds } =
    useAddVirtualBackground();

  const { mutate: updateVideoSettings } = useUpdateVideoSettings();

  const { mutate: deleteVirtualBackground, isLoading: isDeletingVirtualBackgrounds } =
    useDeleteVirtualBackground();

  const [showToast] = useToast({ duration: 'infinite' });

  const reinitializeCanvas = useCallback(
    async (camera: string) => {
      if (!stream) return;

      const { isCameraUsable, error } = await canStartCamera(camera);

      if (!isCameraUsable) {
        showToast({ variant: 'error', messageBody: error.message });
        return;
      }

      setIsCanvasLoading(true);

      try {
        await stream.stopPreviewVirtualBackground();
      } catch (error) {
        console.log({ error });
      }

      setShouldRenderCanvas(false);

      await new Promise((resolve) => setTimeout(() => resolve(setShouldRenderCanvas(true))));

      setTimeout(async () => {
        if (videoPreviewRef.current) {
          try {
            await stream.previewVirtualBackground(
              videoPreviewRef.current,
              virtualBackground,
              false,
              camera
            );
          } catch (error) {
            setIsVirtualBackgroundSupported(false);
            console.log(error);
          }
        }

        setIsCanvasLoading(false);
      });
    },
    [virtualBackground]
  );

  useEffect(() => {
    if (isVirtualBackgroundSupported && activeCamera) {
      reinitializeCanvas(activeCamera);
    }
  }, [activeCamera, isVirtualBackgroundSupported, reinitializeCanvas]);

  useEffect(() => {
    if (!stream || !zoomClient || !videoPreviewRef.current || !isVirtualBackgroundSupported) return;

    let shouldExecuteCleanup = true;

    stream
      .previewVirtualBackground(videoPreviewRef.current, virtualBackground, false, activeCamera)
      .catch((error) => {
        shouldExecuteCleanup = false;
        setIsVirtualBackgroundSupported(false);
        console.log(error);
      });

    return () => {
      if (shouldExecuteCleanup) {
        stream.stopPreviewVirtualBackground().catch();
      }
    };
  }, []);

  const startVideoFailureHandler = (error: any) => {
    const toast = getStartVideoToastErrors(error);

    showToast(toast);
    setIsVirtualBackgroundSupported(false);
  };

  const cameraChangeCallback = useCallback(
    async (value: string, shouldStartCamera = false) => {
      const myUserId = zoomClient?.getSessionInfo().userId;

      if (!stream || !myUserId) return;

      if (!shouldStartCamera) return;

      try {
        await stream.switchCamera(value);
      } catch (error) {
        console.log({ error });
      }

      try {
        await stream.stopVideo();
        await stream.detachVideo(myUserId);
      } catch (error) {
        console.log({ error });
      }

      try {
        await startVideo({
          stream,
          cameraId: value,
          virtualBackground,
          onFailure: startVideoFailureHandler,
        });

        setActiveCamera(value);
      } catch (error) {
        console.log(error);
      }
    },
    [isCameraActive, activeCamera, stream, virtualBackground]
  );

  const cameraChangeHandler = async (value: string) => {
    if (!stream || value === activeCamera || !videoPreviewRef.current) return;

    const { isCameraUsable, error } = await canStartCamera(value);

    if (!isCameraUsable) {
      showToast({ variant: 'error', messageBody: error.message });
      return;
    }

    if (!isCameraActive) {
      setActiveCamera(value);

      return;
    }

    setIsCameraSwitchLoading(true);

    await cameraChangeCallback(value, true);

    setIsCameraSwitchLoading(false);
  };

  const microphoneChangeHandler = async (value: string) => {
    if (!stream || value === activeMicrophone) return;

    setIsMicrophoneSwitchLoading(true);
    setActiveMicrophone(value);

    try {
      await stream?.switchMicrophone(value);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.reason,
      });
    }

    setIsMicrophoneSwitchLoading(false);
  };

  const { isCameraSwitchLoading: isLocalTrackCameraSwitchLoading, changeCamera } = useLocalTracks({
    initializeAudio: false,
    isCameraActive: true,
    activeCamera,
    activeMicrophone,
    onActiveCameraChange: cameraChangeCallback,
    videoRef: localVideoPreviewRef,
  });

  const localCameraChangeHandler = async (value: string) => {
    if (!stream || value === activeCamera) return;

    const { isCameraUsable, error } = await canStartCamera(value);

    if (!isCameraUsable) {
      showToast({ variant: 'error', messageBody: error.message });
      return;
    }

    setActiveCamera(value);
    changeCamera(value, isCameraActive);
  };

  const fileChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!stream || !event.target.files) return;

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    addVirtualBackground(formData, {
      onSuccess: (url) => virtualBackgroundChangeHandler(url),
    });
  };

  const deleteVirtualBackgroundHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    background: string
  ) => {
    e.stopPropagation();

    if (virtualBackground === background) {
      virtualBackgroundChangeHandler(undefined);
    }

    deleteVirtualBackground(
      { background },
      {
        onError: (error: any) => {
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: error.reason,
          });
        },
      }
    );
  };

  const virtualBackgroundChangeHandler = async (background?: string) => {
    if (!stream || isChangingVirtualBackground.current === true) return;

    isChangingVirtualBackground.current = true;

    const newVirtualBackground = background === virtualBackground ? undefined : background;

    try {
      await stream.updateVirtualBackgroundImage(newVirtualBackground);

      onVirtualBackgroundChange(newVirtualBackground);
      updateVideoSettings({ virtualBackground: newVirtualBackground ?? null });
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.reason,
      });
    }

    isChangingVirtualBackground.current = false;
  };

  const backgroundNoiseSuppressionHandler = async () => {
    if (!stream || isChangingNoiseSuppression.current) return;

    isChangingNoiseSuppression.current = true;

    try {
      await stream.enableBackgroundNoiseSuppression(!backgroundSuppression);
      onBackgroundSuppressionChange(!backgroundSuppression);

      updateVideoSettings({ noiseSuppression: !backgroundSuppression });
    } catch (error) {
      console.log(error);
    }

    isChangingNoiseSuppression.current = false;
  };

  const toggleSectionHandler = (section: 'audio' | 'video') => {
    if (section === 'audio') {
      setIsAudioSectionCollapsed(!isAudioSectionCollapsed);
      if (isAudioSectionCollapsed) setIsVideoSectionCollapsed(true);
    } else {
      setIsVideoSectionCollapsed(!isVideoSectionCollapsed);
      if (isVideoSectionCollapsed) setIsAudioSectionCollapsed(true);
    }
  };

  const isMicrophoneAndShareAudioSimultaneouslySupported =
    !!stream?.isSupportMicrophoneAndShareAudioSimultaneously();

  const shareAudioStatus = stream?.getShareAudioStatus();

  const shareTabAudioToggle = async () => {
    if (isShareTabAudioToggleLoading) return;

    setIsShareTabAudioToggleLoading(true);

    if (shareAudioStatus?.isSharingAudio) {
      try {
        await stream?.muteShareAudio();
      } catch (error) {
        console.log({ error });
      }

      return setIsShareTabAudioToggleLoading(false);
    }

    try {
      await stream?.unmuteShareAudio();
    } catch (error) {
      console.log({ error });
    }

    setIsShareTabAudioToggleLoading(false);
  };

  return (
    <Modal className="h-full max-h-189" isOpen={isOpen} onClose={onClose} hasFullMaxHeight>
      <Modal.Header title="Settings" />
      <Modal.Body className="overflow-x-hidden overflow-y-auto h-full">
        {/* Audio Section */}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col overflow-hidden">
            <div className="flex justify-between">
              <div className="flex items-center gap-5">
                <AudioSettingsIcon />
                <h3 className="text-base font-bold">Audio Settings</h3>
              </div>
              <IconButton
                icon={
                  <ExpandArrowIcon
                    className={mergeClassNames('transition-all ease-in-out duration-300', {
                      'rotate-180': !isAudioSectionCollapsed,
                    })}
                  />
                }
                variant="ghost"
                onClick={() => toggleSectionHandler('audio')}
              />
            </div>
            <div
              className={mergeClassNames(
                'flex flex-col transition-all ease-in-out duration-300 w-full gap-6',
                {
                  'max-h-0 my-0 opacity-0': isAudioSectionCollapsed,
                  'max-h-[320px] laptop:max-h-[300px] my-6 opacity-100': !isAudioSectionCollapsed,
                }
              )}
            >
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-col gap-3 w-full">
                  <p className="text-xs font-bold">Audio Device</p>
                  <Select
                    options={micList.map((mic) => ({
                      value: mic.deviceId,
                      label: mic.label,
                    }))}
                    value={activeMicrophone}
                    onSelect={(value) => microphoneChangeHandler(value as string)}
                    isLoading={isMicrophoneSwitchLoading}
                    className="max-w-1/2"
                    allowClear={false}
                    isDisabled={!micList.length || isMicrophoneSwitchLoading}
                  />
                </div>
                <MicrophoneLevelIcon activeMicrophone={activeMicrophone} />
              </div>
              {isVirtualBackgroundSupported && (
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-col gap-3 w-full">
                    <p className="text-xs font-bold">Background Noise Suppression</p>
                    <p className="text-xs">Mutes Background Noise for better voice clarity</p>
                  </div>
                  <ToggleButton
                    isChecked={backgroundSuppression}
                    onClick={backgroundNoiseSuppressionHandler}
                  />
                </div>
              )}
              {!isMicrophoneAndShareAudioSimultaneouslySupported && isSharing && (
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-col gap-3 w-full">
                    <p className="text-xs font-bold">Share Tab Audio</p>
                    <p className="text-xs">
                      Tab audio and microphone audio cannot be shared simultaneously on this
                      device/browser. If you choose to share Tab Audio, participants won't be able
                      to hear you.
                    </p>
                  </div>
                  <ToggleButton
                    isChecked={!!shareAudioStatus?.isSharingAudio}
                    onClick={shareTabAudioToggle}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Divider */}
          <div className="h-px w-full bg-white" />

          {/* Video Section */}
          <div className="flex flex-col overflow-hidden">
            <div className="flex justify-between">
              <div className="flex items-center gap-5">
                <VideoSettingsIcon />
                <h3 className="text-base font-bold">Video Settings</h3>
              </div>
              <IconButton
                icon={
                  <ExpandArrowIcon
                    className={mergeClassNames('transition-all ease-in-out duration-300', {
                      'rotate-180': !isVideoSectionCollapsed,
                    })}
                  />
                }
                variant="ghost"
                onClick={() => toggleSectionHandler('video')}
              />
            </div>
            <div
              className={mergeClassNames(
                'flex flex-col items-center transition-all ease-in-out duration-300 h-auto w-full gap-6',
                {
                  'max-h-0 my-0 opacity-0': isVideoSectionCollapsed,
                  'max-h-[670px] laptop:max-h-[660px] my-6 opacity-100': !isVideoSectionCollapsed,
                }
              )}
            >
              {isVirtualBackgroundSupported ? (
                <div className="relative mx-auto w-full max-w-[512px] h-auto aspect-video">
                  {shouldRenderCanvas && (
                    <VideoPlayerContainer>
                      <video-player
                        className="mx-auto w-full max-w-[512px]"
                        ref={(ref) => (videoPreviewRef.current = ref as any)}
                      ></video-player>
                    </VideoPlayerContainer>
                  )}
                  {(isCameraSwitchLoading || isCanvasLoading) && (
                    <LoadingSpinner className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-9999" />
                  )}
                </div>
              ) : (
                <VideoWidget
                  ref={localVideoPreviewRef}
                  className="max-w-[520px] w-full"
                  videoClassName="tablet:rounded-none"
                  isCameraActive={true}
                  isCameraDisabled={!cameraList.length}
                  isMicrophoneDisabled={!micList.length}
                />
              )}

              <div className="flex flex-col gap-4 w-full">
                <p className="text-xs font-bold">Video Device</p>
                <Select
                  options={cameraList.map((camera) => ({
                    value: camera.deviceId,
                    label: camera.label,
                  }))}
                  value={activeCamera}
                  onSelect={(value) =>
                    isVirtualBackgroundSupported
                      ? cameraChangeHandler(value as string)
                      : localCameraChangeHandler(value as string)
                  }
                  isLoading={
                    isCameraSwitchLoading || isLocalTrackCameraSwitchLoading || isCanvasLoading
                  }
                  className="w-full"
                  allowClear={false}
                  isDisabled={
                    !micList.length ||
                    isCameraSwitchLoading ||
                    isLocalTrackCameraSwitchLoading ||
                    isCanvasLoading
                  }
                />
              </div>
              {isVirtualBackgroundSupported && (
                <>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col gap-3 w-full">
                      <p className="text-xs font-bold">Blur Background</p>
                      <p className="text-xs">Blur Video Background</p>
                    </div>
                    <ToggleButton
                      isChecked={virtualBackground === 'blur'}
                      onClick={() => virtualBackgroundChangeHandler('blur')}
                    />
                  </div>
                  <div className="flex justify-between gap-4 items-center w-full">
                    <div className="flex flex-col gap-3 w-full">
                      <p className="text-xs font-bold">Virtual Background</p>
                      <p className="text-xs">Upload a Virtual Background from your computer</p>
                    </div>
                    <div className="relative cursor-pointer">
                      <input
                        disabled={isUpdatingVirtualBackgrounds}
                        type="file"
                        accept="image/jpeg, image/png"
                        className="opacity-0 absolute top-0 left-0 w-full h-full"
                        onChange={fileChangeHandler}
                      />
                      <Button
                        isLoading={isUpdatingVirtualBackgrounds}
                        buttonText="Upload Background"
                      />
                    </div>
                  </div>
                  {!!virtualBackgrounds?.length && (
                    <div className="flex self-start items-center gap-2 max-w-full overflow-x-auto overflow-y-hidden py-2.5">
                      {virtualBackgrounds.toReversed().map((url) => (
                        <div
                          key={url}
                          className={mergeClassNames(
                            'flex items-center justify-center group relative rounded aspect-video h-[100px] w-auto border-solid border-2 border-transparent cursor-pointer overflow-hidden',
                            {
                              'border-customPrimary': virtualBackground === url,
                            }
                          )}
                          onClick={() => virtualBackgroundChangeHandler(url)}
                        >
                          <img
                            className="object-cover object-center w-full h-full"
                            crossOrigin={'anonymous'}
                            alt={'virtual-background'}
                            src={url}
                          />
                          <IconButton
                            isDisabled={isDeletingVirtualBackgrounds}
                            onClick={(e) => deleteVirtualBackgroundHandler(e, url)}
                            className="absolute top-2 right-2 flex opacity-100 laptop:group-hover:flex laptop:group-hover:opacity-100 laptop:hidden laptop:opacity-0"
                            icon={<TrashIcon />}
                            color="danger"
                            variant="ghost"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SettingsModal;
