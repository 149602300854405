import { flagApis } from '../../apis';

import { genericMutation, genericQueryWithParams } from './generics';

export const useSearchFlags = genericQueryWithParams<flagApis.SearchFlags>(flagApis.searchFlags);

export const useCloseMultipleFlags = genericMutation<flagApis.CloseMultipleFlags>(
  flagApis.closeMultipleFlags
);

export const useGetNoOfFlagsByFlagTypes = genericQueryWithParams<flagApis.GetNoOfFlagsByFlagTypes>(
  flagApis.getNoOfFlagsByFlagTypes
);

export const useSearchFlagsForLinearChart =
  genericQueryWithParams<flagApis.SearchFlagsForLinearChart>(flagApis.searchFlagsForLinearChart);
