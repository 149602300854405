import { ApiDescription, ApiData, RequestsMethod, Homework } from '../common';

export const createHomework: ApiDescription = {
  endpoint: '/homeworks',
  method: RequestsMethod.Post,
};
export type CreateHomework = ApiData<
  {
    title: string;
    description: string;
    student: string;
    meeting: string;
    enrollment: string;
  },
  Homework
>;

export const listMentorHomework: ApiDescription = {
  endpoint: '/homeworks',
  method: RequestsMethod.Get,
};
export type ListMentorHomework = ApiData<{ completed?: boolean; student?: string }, Homework[]>;

export const listMyHomework: ApiDescription = {
  endpoint: '/homeworks/my',
  method: RequestsMethod.Get,
};
export type ListMyHomework = ApiData<
  { completed?: boolean; mentor?: string; enrollmentId?: string },
  Homework[]
>;

export const updateHomework: ApiDescription = {
  endpoint: '/homeworks/:id',
  method: RequestsMethod.Patch,
};
export type UpdateHomework = ApiData<Partial<Homework>, Homework>;
