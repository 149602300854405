import { CloseModalProps } from '@shared/common';
import { useUpdateUserNote, useUserDetails, useUserNotes } from '@shared/react';
import styles from '@styles/components/notes-modal.module.scss';
import { Modal, Tabs, Typography, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import './notes-modal.style.scss';
import { COLORS } from '../../v3/global';
import SecondaryButtonV3 from '@components/V3/SecondaryButtonV3';

const { TabPane } = Tabs;
const { Text } = Typography;

type Props = CloseModalProps & {
  userId: string;
};

const NotesModal = ({ visible, hide, userId }: Props) => {
  const [texts, setTexts] = useState<string[]>(['']);
  const [currentIndex, setCurrentIndex] = useState(0);
  const titles =
    texts !== undefined ? texts?.map((text) => text?.split('\n')[0] || 'New note') : ['New note'];

  const { mutate: updateUserNote, isLoading: updatingNotes } = useUpdateUserNote(userId, {
    onSuccess: () => {
      message.success('Successfully updated your notes.');
    },
  });
  const { data: notes } = useUserNotes(userId);
  const { data: user } = useUserDetails(userId);

  const changeText = (index: number, text: string) => {
    const newTexts = [...texts];
    newTexts[index] = text;
    setTexts([...newTexts]);
  };

  const add = () => {
    setTexts([...texts, '']);
  };

  const onEdit = (index: any, action: 'add' | 'remove') => {
    const indexNumber = Number(index);
    if (action === 'remove') {
      setTexts(texts.filter((_, i) => i !== indexNumber));
      setCurrentIndex(currentIndex - 1);
    }
    if (action === 'add') {
      add();
      setCurrentIndex(texts.length);
    }
  };

  useEffect(() => {
    setTexts(notes || ['']);
  }, [notes]);

  const tabTitle = (title: string) => <span className={styles.modalTabsTitle}>{title}</span>;

  return (
    <Modal
      className={`${styles.modal} ${'agora-modal-content'}`}
      title={<span className={'agoraModalTitle'}>{`Mentor's Notes - ${user?.fullName}`}</span>}
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      visible={visible}
      onCancel={hide}
      centered
      footer={
        <Space size="large" className="notes-modal-footer">
          <Text style={{ color: COLORS.TEXT_SECONDARY }} type="secondary">
            Only mentors and staff see notes.
          </Text>
          <SecondaryButtonV3
            className="save-button"
            isLoading={updatingNotes}
            buttonText="Save"
            onClick={() => updateUserNote({ notes: texts })}
          />
        </Space>
      }
      width={600}
    >
      <Tabs
        onChange={(index) => setCurrentIndex(Number(index))}
        onEdit={onEdit}
        activeKey={currentIndex.toString()}
        type="editable-card"
        centered
        className={styles.modalTabs}
        style={{ color: COLORS.PRIMARY }}
      >
        {titles.map((title, index) => (
          <TabPane tab={tabTitle(title)} key={index} closable={titles.length !== 1} />
        ))}
      </Tabs>
      <div className="mt-2">
        <textarea
          rows={10}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
          // className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-1 focus:ring-inset focus:customPrimary hover:ring-1 hover:ring-inset hover:customPrimary sm:text-sm sm:leading-6"
          value={texts[currentIndex]}
          onChange={(e) => changeText(currentIndex, e.target.value)}
          style={{ backgroundColor: COLORS.DARK_GREY_ALT }}
        />
      </div>
    </Modal>
  );
};

export default NotesModal;
