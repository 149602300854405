import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { degreeTypes } from '@modules/Account/Profile/components/AccountProfile/constants';
import SessionModalFormWrapper from '@modules/Admin/components/SessionModal/SessionModalFormWrapper';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface UniversitySectionFormProps extends BasicProps {
  userId: string;
  isModeAddNew?: boolean;
  universityData?: any;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid school name.' }),
  degreeName: z.string().min(1, { message: 'Please enter a valid degree type.' }),
  majorName: z.string().min(1, { message: 'Please enter a valid major name.' }),
  startDate: z.string().min(1, { message: 'Please enter a valid start date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid end date.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const UniversitySectionForm = (props: UniversitySectionFormProps) => {
  const { userId, className, isModeAddNew = false, universityData, onCloseEditForm } = props;

  const [showToast] = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      onCloseEditForm();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: universityData?.educationalInstitution.name,
      degreeName: universityData?.degreeType,
      majorName: universityData?.major?.name,
      startDate: universityData?.startDate,
      endDate: universityData?.endDate,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } = useController({
    name: 'startDate',
    control,
  });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degreeName',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const newUniversityData: any = {
        educationalInstitution: {
          name: data.name,
          type: 'University',
        },
        major: {
          name: data.majorName,
        },
        degreeType: data.degreeName,
        startDate: data.startDate,
        endDate: data.endDate,
        _id: universityData?._id,
      };

      updateUserProfile({
        educationalBackground: [newUniversityData],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add University' : 'Edit University'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText={isModeAddNew ? 'Create University' : 'Save University'}
    >
      <Input
        isRequired
        type="text"
        id="university_name"
        label="University Name"
        isTouched={touchedFields.name}
        isValid={!errors.name}
        errorText={errors.name?.message}
        autofocus
        {...register('name')}
      />

      <Select
        isRequired
        value={degreeField.value}
        onSelect={(value) => degreeField.onChange(value)}
        options={degreeTypes?.map((degree) => ({
          label: degree,
          value: degree,
        }))}
        onBlur={degreeField.onBlur}
        size="large"
        label="Degree"
        isValid={!degreeFieldState.error}
        isTouched={degreeFieldState.isTouched}
        errorText={degreeFieldState.error?.message}
      />
      <Input
        isRequired
        type="text"
        id="major"
        label="Major"
        isTouched={touchedFields.majorName}
        isValid={!errors.majorName}
        errorText={errors.majorName?.message}
        {...register('majorName')}
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          isRequired
          value={startDateField.value ? moment(startDateField.value) : undefined}
          onChange={(value) => startDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={startDateField.onBlur}
          allowClear={false}
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          isRequired
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) => endDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={endDateField.onBlur}
          allowClear={false}
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default UniversitySectionForm;
