import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';

type CancelMeetingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
};

const CancelMeetingModal = (props: CancelMeetingModalProps) => {
  const { isOpen, onClose, onPrimaryButtonClick, onSecondaryButtonClick } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Cancel Meeting" className="text-center" />
      <Modal.Body className="flex flex-col justify-center items-center overflow-x-hidden mb-6 text-xs leading-5 text-center">
        <p>Would you like to reschedule this meeting for another time?</p>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          className="w-[180px]"
          variant="secondary"
          buttonText="No, don't reschedule"
          onClick={onSecondaryButtonClick}
        />
        <Button
          className="w-[180px]"
          variant="primary"
          buttonText="Yes, reschedule the meeting"
          onClick={onPrimaryButtonClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CancelMeetingModal;
