import { KnowledgeBaseArticle } from '@shared/common';
import { ApiDescription, ApiData, RequestsMethod, RawObject } from '@shared/types';

export const createKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/create',
  method: RequestsMethod.Post,
};
export type CreateKnowledgeBaseArticle = ApiData<
  RawObject<KnowledgeBaseArticle>,
  KnowledgeBaseArticle
>;

export const getLessonPlans: ApiDescription = {
  endpoint: '/knowledge-base/lesson-plans',
  method: RequestsMethod.Get,
};
export type GetLessonPlans = ApiData<unknown, Array<Partial<KnowledgeBaseArticle>>>;

export const searchKnowledgeBaseArticles: ApiDescription = {
  endpoint: '/knowledge-base/search/all',
  method: RequestsMethod.Get,
};

export type SearchKnowledgeBaseArticles = ApiData<
  { search: string; page?: number; limit?: number },
  { totalPages: number; articles: KnowledgeBaseArticle[] }
>;

export const exportKnowledgeBase: ApiDescription = {
  endpoint: '/knowledge-base/export/all',
  method: RequestsMethod.Get,
};

export type ExportKnowledgeBase = ApiData<unknown, unknown>;

export const getKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/:id',
  method: RequestsMethod.Get,
};
export type GetKnowledgeBaseArticle = ApiData<unknown, KnowledgeBaseArticle>;

export const updateKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/:id/update',
  method: RequestsMethod.Post,
};
export type UpdateKnowledgeBaseArticle = ApiData<
  Partial<KnowledgeBaseArticle>,
  KnowledgeBaseArticle
>;

export const deleteKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/:id/delete',
  method: RequestsMethod.Delete,
};
export type DeleteKnowledgeBaseArticle = ApiData<unknown, { message: string }>;

export const likeKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/:id/like',
  method: RequestsMethod.Post,
};
export type LikeKnowledgeBaseArticle = ApiData<unknown, { message: string }>;

export const dislikeKnowledgeBaseArticle: ApiDescription = {
  endpoint: '/knowledge-base/:id/dislike',
  method: RequestsMethod.Post,
};
export type DislikeKnowledgeBaseArticle = ApiData<unknown, { message: string }>;
