import { LeftOutlined } from '@ant-design/icons';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { Modal } from 'antd';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { COLORS } from '../../../../v3/global';
import './agora-modal-style.scss';

type OverflowY = 'visible' | 'hidden' | 'scroll' | 'auto';

export type AgoraModalProps = {
  title?: string;
  title2?: string;
  subtitle?: string;
  footer?: ReactNode;
  children: ReactNode;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  isModalOpen: boolean;
  closable?: boolean;
  maskClosable?: boolean;
  width?: string;
  height?: string;
  backButton?: boolean;
  backRoute?: string;
  onBack?: (backRoute: string) => any;
  onClose?: () => any;
  overflowY?: OverflowY;
  mobile?: boolean;
  className?: string;
};

const AgoraModal = ({
  title,
  title2,
  subtitle,
  footer,
  children,
  setModalOpen,
  isModalOpen,
  closable,
  maskClosable,
  width,
  height,
  backButton,
  backRoute,
  onBack,
  onClose,
  overflowY,
  mobile,
  className,
}: AgoraModalProps) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <Modal
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: onBack && backButton ? 'space-between' : 'center',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          {onBack && backButton && (
            <div
              style={{
                marginLeft: '-40px',
                marginTop: '-32px',
              }}
            >
              <SecondaryButton
                backgroundColor={COLORS.TRANSPARENT}
                height="27px"
                padding="12px 14px 12px 14px"
                onClick={() => {
                  if (onBack && backRoute) {
                    onBack(backRoute);
                  }
                }}
              >
                <LeftOutlined style={{ color: COLORS.TEXT_PRIMARY, fontSize: '11px' }} />
                <span
                  className="button-text"
                  style={{
                    marginLeft: '8px',
                    color: COLORS.TEXT_PRIMARY,
                    fontSize: '11px',
                    lineHeight: '15px',
                  }}
                >
                  Back
                </span>
              </SecondaryButton>
            </div>
          )}
        </div>
      }
      centered
      visible={isModalOpen}
      onOk={() => (setModalOpen ? setModalOpen(false) : {})}
      onCancel={() => {
        setModalOpen && setModalOpen(false);
        onClose && onClose();
      }}
      width={width ? width : screenWidth < 830 ? '90vw' : '52vw'}
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        borderRadius: '26px',
        height: height ?? '',
        maxWidth: '1024px',
      }}
      className={classNames('agora-modal-content', className)}
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        maxHeight: '80vh',
        overflowY: (overflowY as OverflowY) || 'auto',
        width: 'calc(100% - 11x2px)',
        marginLeft: mobile ? '16px' : '56px',
        marginRight: mobile ? '16px' : '56px',
        paddingLeft: '16px',
        paddingRight: '16px',
        marginTop: '16px',
        marginBottom: '16px',
      }}
      closable={closable ?? true}
      maskClosable={maskClosable ?? true}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {footer}
        </div>
      }
    >
      <div style={{ width: '100%' }} className="modal-content">
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          <h3 className={'agora-modal-title'}>{title}</h3>
          <h3 className={'agora-modal-title'}>{title2}</h3>
          <p className={'agora-modal-subtitle '}>{subtitle}</p>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default AgoraModal;
