import { InfoCircleOutlined } from '@ant-design/icons';
import Loading from '@components/Common/Loading';
import { COURSES } from '@routes';
import { useUserCourses } from '@shared/react';
import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../v3/global';

const Courses: React.FC = () => {
  const { data: courses, isLoading, isError } = useUserCourses();
  const history = useHistory();

  useEffect(() => {
    if (!courses?.length) {
      return;
    }
    const activeCourse = courses[0];
    history.push(`${COURSES}/${activeCourse._id}`);
  }, [courses]);

  if (isLoading) {
    return <Loading />;
  }

  if (courses === undefined || isError) {
    return (
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <InfoCircleOutlined /> An error has occurred!
      </Typography.Paragraph>
    );
  }

  if (!courses.length) {
    return (
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <InfoCircleOutlined /> You do not have access to any courses at the moment!
      </Typography.Paragraph>
    );
  }

  return null;
};

export default Courses;
