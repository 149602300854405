import styles from '@styles/components/sider.module.scss';
import { ReactNode } from 'react';
import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

const DropdownItem = ({
  route,
  icon,
  title,
  backgroundColor,
}: {
  route: string;
  title: string | ReactNode;
  icon?: ReactNode;
  backgroundColor?: string;
}) => {
  const { pathname } = useLocation();
  const isActive = matchPath(pathname, {
    path: route,
    strict: false,
    exact: false,
  });

  return (
    <Link
      to={route}
      className={`${styles.navItem} ${isActive ? styles.navItemActive : ''} ${styles.dropdownItem}`}
      style={{ backgroundColor: isActive ? backgroundColor : '' }}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.title}>{title}</div>

      {backgroundColor && (
        <div className={styles.dropdownItemBackground} style={{ backgroundColor }}></div>
      )}
    </Link>
  );
};

export default DropdownItem;
