import MeetingEditor from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/MeetingEditor';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { Enrollment, Session } from '@shared/types';
import styles from '@styles/components/sessions-menu.module.scss';
import React, { useEffect, useState } from 'react';

type Props = {
  enrollment: Enrollment;
  session: Session;
  sessionIndex: number;
};

const SessionsEditor: React.FC<Props> = ({ session, sessionIndex }) => {
  const [meetings, setMeetings] = useState(session?.meetings);
  const [totalMeetings, setTotalMeetings] = useState<any>([]);

  const getMeetingData = () => {
    if (session?.meetings.length === 0) {
      setTotalMeetings([]);
      return;
    }
    setTotalMeetings([]);

    session?.meetings.map(async (meeting) => {
      const response = await apiClient.get(`/meeting/${meeting}`);

      setTotalMeetings([response.data]);
    });
  };

  useEffect(() => {
    getMeetingData();
  }, [session, sessionIndex]);

  const onDelete = (meetingId: string) => {
    const newmeetings = meetings.filter((m) => m !== meetingId);
    setMeetings(newmeetings);
  };

  useEffect(() => {
    if (session?.meetings?.length > 0) {
      setMeetings(session?.meetings);
    } else {
      setMeetings([]);
    }
  }, [session?.meetings]);

  return (
    <div className={styles.editorSession}>
      <div>
        <div className={styles.editorTitle}>Meeting: </div>
        <div>
          {meetings.length > 0 &&
            meetings
              .map((meeting, index) => {
                return (
                  <MeetingEditor
                    meetingId={meeting}
                    meeting={totalMeetings[index]}
                    onDelete={onDelete}
                    refetch={getMeetingData}
                  />
                );
              })
              .reverse()}
        </div>
      </div>
    </div>
  );
};

export default SessionsEditor;
