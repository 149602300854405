import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { PageLayout } from '@components';
import { COURSES } from '@routes';
import { useCourse } from '@shared/react';
import { Quiz } from '@shared/types';
import { Button, Card, Progress } from 'antd';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import Breadcrumbs from '../../../views/UserView/Course/Breadcrumbs';
import EdjDisplay from './EdjDisplay';
import { LessonProvider, useLessonActions, useLessonState } from './LessonContext';
import LessonTakeaways from './LessonTakeaways';

const LessonDisplay = () => {
  const {
    title,
    gettingLesson,
    slidePercent,
    content,
    isLastSlide,
    markingComplete,
    isFirstSlide,
    slideHasQuiz,
  } = useLessonState();
  const actions = useLessonActions();
  const search = useLocation().search;
  const studentId = new URLSearchParams(search).get('studentId');

  if (!gettingLesson && content.blocks.length === 0)
    window.location.href = window.location.href.replace('undefined', 'Quiz');

  const { id } = useParams<{ [key: string]: string }>();
  const location = useLocation();
  const { data } = useQuery<Quiz>(['page/$?refType=Lesson', id]);
  const { data: courseData } = useCourse(data?.parentCourse);

  const m2 = new URLSearchParams(search).get('m');

  const { data: course } = useCourse(data?.parentCourse);

  const chapterName = m2 ? course?.modules[Number(m2)].title : '';

  const crumbs =
    courseData && data
      ? [
          { path: COURSES, name: 'Courses' },
          { path: `${COURSES}/${courseData._id}`, name: courseData.title },
          {
            path: `${COURSES}/${courseData._id}/chapters/${m2}`,
            name: `${Number(m2) + 1}. ${chapterName}`,
          },
          { path: location.pathname, name: data.title },
        ]
      : [];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageLayout
        isEmpty
        // backUrl={studentId ? `${moduleUrl}?studentId=${studentId}` : moduleUrl}
        backButton
        headerTitle={title}
      >
        <Card
          style={{
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
          }}
          className="lesson-card"
          loading={gettingLesson}
        >
          {/*{skippedAhead && (*/}
          {/*  <Alert*/}
          {/*    message="While you're free to skip ahead, progress won't be saved unless you take lessons in order."*/}
          {/*    type="warning"*/}
          {/*    closable*/}
          {/*  />*/}
          {/*)}*/}
          <Progress percent={slidePercent * 100} showInfo={false} />
          <EdjDisplay data={content} />
          {isLastSlide ? (
            <div>
              {!studentId && finishButtons(actions.lessonDone, markingComplete, actions.resetSlide)}
            </div>
          ) : (
            <div>
              {!isFirstSlide && backButton(actions.prevSlide)}
              {!slideHasQuiz && nextButton(actions.nextSlide)}
            </div>
          )}
        </Card>
        {isLastSlide && <LessonTakeaways />}
      </PageLayout>
    </>
  );
};

const backButton = (fn: () => void) => (
  <Button
    onClick={() => {
      fn();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }}
    shape="round"
    icon={<ArrowLeftOutlined />}
    size="large"
    style={{ marginTop: 15 }}
  >
    Back
  </Button>
);

const nextButton = (fn: () => void) => (
  <Button
    onClick={() => {
      fn();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }}
    shape="round"
    icon={<ArrowRightOutlined />}
    size="large"
    type="primary"
    style={{ marginTop: 15, float: 'right' }}
  >
    Next
  </Button>
);

const finishButtons = (doneFn: () => void, doneLoading: boolean, retakeFn: () => void) => (
  <div>
    <Button
      style={{ marginTop: 15, marginRight: 15 }}
      shape="round"
      icon={<CheckOutlined />}
      size="large"
      type="primary"
      onClick={doneFn}
      loading={doneLoading}
    >
      Finish Lesson
    </Button>

    <Button
      style={{ marginTop: 15, marginRight: 15 }}
      shape="round"
      icon={<RedoOutlined />}
      size="large"
      onClick={() => {
        retakeFn();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      Retake Lesson
    </Button>
  </div>
);

const Wrapper = () => {
  return (
    <LessonProvider>
      <LessonDisplay />
    </LessonProvider>
  );
};

export default Wrapper;
