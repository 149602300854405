import { PageLayout } from '@components';
import LessonPreview from '@modules/Admin/LessonEditor/LessonPreview';
import MainEditor from '@modules/Admin/LessonEditor/MainEditor';
import LessonCRUD from '@modules/Admin/LessonEditor/lesson-crud';
import { Button, Card, Input, message, Popconfirm, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MainTakeawaysEditor from './MainTakeawaysEditor';

const LessonQuizEditor = () => {
  const [lessonData, setLessonData] = useState<any>({});
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const { action, courseId, lessonId, moduleId } = useParams<{
    action: string;
    courseId: string;
    lessonId: string;
    moduleId: string;
  }>();

  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState(false);

  const saveLesson = () => {
    if (action.includes('new')) {
      LessonCRUD.add({ title, courseId, moduleId, content: lessonData });
    } else if (action.includes('edit')) {
      LessonCRUD.edit({ title, courseId, lessonId, content: lessonData });
    }
  };

  const deleteLesson = async () => {
    await LessonCRUD.deleteEverywhere({ lessonId, courseId, moduleId });
    message.success('Lesson deleted successfully.');
    setTimeout(() => {
      window.location.href = '/admin/manage-courses';
    }, 1000);
  };

  const onUpdate = (api: any, newLessonData: any) => {
    setLessonData(newLessonData);
  };

  const initializeLessonEditing = async () => {
    const data = await LessonCRUD.get({ lessonId });
    setTitle(data.title);
    setLessonData(data.content);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const setSaveReminders = () => {
    const FIVE_MINUTES = 5 * 60 * 1000;
    const TEN_SECONDS = 10;
    const interval = setInterval(() => {
      message.info(
        "It's been a while, you might want to save before continuing to write. Don't lose your data.",
        TEN_SECONDS
      );
    }, FIVE_MINUTES);

    return interval;
  };

  const autoSave = () => {
    const FIVE_MINUTES = 5 * 60 * 1000;
    const interval = setInterval(() => {
      saveLesson();
    }, FIVE_MINUTES);

    return interval;
  };

  useEffect(() => {
    if (action.includes('edit')) {
      initializeLessonEditing();
      notification.info({
        message: `Auto-save is enabled`,
        description: 'This feature is experimental. Always save manually before you leave.',
        placement: 'bottomLeft',
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (action.includes('edit')) {
      interval = autoSave();
    } else {
      interval = setSaveReminders();
    }
    return () => clearInterval(interval);
  }, [lessonData]);

  const headerExtra = [
    <Popconfirm
      title={`This action is irreversible. Are you sure you want to delete ${title}?`}
      visible={showDeletePopConfirm}
      onConfirm={deleteLesson}
      onCancel={() => setShowDeletePopConfirm(false)}
    >
      <Button key="delete-page" onClick={() => setShowDeletePopConfirm(true)}>
        Delete
      </Button>
    </Popconfirm>,
    <Button onClick={() => setShowPreview(true)} disabled={!lessonData.blocks}>
      Preview
    </Button>,
    <Button type="primary" onClick={saveLesson} disabled={!lessonData.blocks}>
      Save Lesson
    </Button>,
  ];

  return (
    <div>
      <PageLayout headerTitle="Lesson Editor" headerExtra={headerExtra} isEmpty>
        <Card style={{ margin: 15 }}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Lesson Title"
            style={{ maxWidth: '675px', margin: '0 auto', display: 'block' }}
          />
          {!loading && <MainEditor onUpdate={onUpdate} data={lessonData} />}
        </Card>
        <Card style={{ margin: 15 }} title="Takeaways">
          <MainTakeawaysEditor lessonId={lessonId} courseId={courseId} />
        </Card>
      </PageLayout>
      <LessonPreview visible={showPreview} setVisible={setShowPreview} data={lessonData} />
    </div>
  );
};

export default LessonQuizEditor;
