import { CloseModalProps } from '@shared/common';
import { FC, ReactNode, useState } from 'react';

type OmitModalProps<T> = Omit<T, 'visible' | 'hide'>;

const useModal = <T extends CloseModalProps>(
  Modal: FC<T>,
  defaultProps?: OmitModalProps<T>
): [ReactNode, (data: OmitModalProps<T>) => void, boolean] => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [props, setProps] = useState<OmitModalProps<T>>(defaultProps || ({} as T));

  const showModal = (data: OmitModalProps<T>) => {
    setProps(data);
    setVisibleModal(true);
  };

  const hide = () => setVisibleModal(false);

  const RenderedModal = visibleModal && (
    <Modal {...(props as T)} visible={visibleModal} hide={hide} />
  );

  return [RenderedModal, showModal, visibleModal];
};

export default useModal;
