import Modal from '@components/V4/Modal/Modal';
import { useGetAllCoursesLite, useGetUserProfile } from '@shared/react';
import { GetAllCoursesLiteDTO } from '@shared/types/DTOs/CoursesDTO';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import { createContext } from 'react';
import MentorDetailsSection from './MentorDetailsSection/MentorDetailsSection';
import MentorTabsSection, { MentorTab } from './MentorTabsSection/MentorTabsSection';

interface MentorDetailsPageProps {
  mentorId: string;
  tab?: MentorTab;
  onEnrollmentModalOpen: (id: string) => void;
  onSessionModalOpen: (id: string) => void;
  onStudentModalOpen: (id: string) => void;
}

interface MentorDetailsPageContext {
  mentor?: Partial<UserProfileDTO>;
  courses?: GetAllCoursesLiteDTO[];
  onEnrollmentModalOpen: (id: string) => void;
  onSessionModalOpen: (id: string) => void;
  onStudentModalOpen: (id: string) => void;
}

export const MentorDetailsPageContext = createContext<MentorDetailsPageContext>({
  onEnrollmentModalOpen: () => console.warn('onEnrollmentModalOpen not implemented'),
  onSessionModalOpen: () => console.warn('onSessionModalOpen not implemented'),
  onStudentModalOpen: () => console.warn('onStudentModalOpen not implemented'),
});

const MentorDetailsPage = (props: MentorDetailsPageProps) => {
  const { mentorId, tab, onEnrollmentModalOpen, onSessionModalOpen, onStudentModalOpen } = props;
  const { data: mentor } = useGetUserProfile(mentorId);
  const { data: courses } = useGetAllCoursesLite();

  return (
    <>
      <Modal.Header className="font-raleway text-xl font-bold text-center mb-6">
        <h3>Mentor Profile</h3>
        <h3>{mentor?.fullName}</h3>
      </Modal.Header>
      <Modal.Body>
        <MentorDetailsPageContext.Provider
          value={{
            mentor,
            courses,
            onEnrollmentModalOpen,
            onSessionModalOpen,
            onStudentModalOpen,
          }}
        >
          {mentor && <MentorDetailsSection />}
          <MentorTabsSection tab={tab} />
        </MentorDetailsPageContext.Provider>
      </Modal.Body>
    </>
  );
};

export default MentorDetailsPage;
