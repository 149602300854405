import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import { ConnectionState, Participant, VideoPlayer as VideoPlayerType } from '@zoom/videosdk';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { Ref } from 'react';
import { Link, useLocation } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import React from 'react';

interface VideoContentProps extends BasicProps {
  connectionState: ConnectionState;
  ownId?: number;
  isViewingScreenShare: boolean;
  isSharing: boolean;
  isScreenShareLoading: boolean;
  participantsLength?: number;
  isStartShareScreenWithVideoElement?: boolean;
  participants: Participant[];
  thumbsUpList: { senderId: number; senderName: string; timestamp: number }[];
  speakingParticipants: Record<number, boolean>;
  raisedHands: Record<number, boolean>;
  screenShareVideoContainerRef: Ref<HTMLVideoElement>;
  screenShareViewCanvasContainerRef: Ref<HTMLCanvasElement>;
  screenShareCanvasContainerRef: Ref<HTMLCanvasElement>;
  onSetVideoRef: (userId: number, element: VideoPlayerType | null) => void;
}

const VideoContent = React.memo((props: VideoContentProps) => {
  const {
    ownId,
    isSharing,
    raisedHands,
    participants,
    thumbsUpList,
    connectionState,
    participantsLength,
    speakingParticipants,
    isViewingScreenShare,
    isScreenShareLoading,
    isStartShareScreenWithVideoElement,
    screenShareVideoContainerRef,
    screenShareCanvasContainerRef,
    screenShareViewCanvasContainerRef,
    onSetVideoRef,
  } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const getVideoPlayerClassName = (userId: number) => {
    const isOwnVideo = userId === ownId;
    const isOwnIdNaN = Number.isNaN(ownId);
    const isTwoWayConversation = participants.length === 2;

    if (isSharing || isViewingScreenShare) {
      return 'w-auto laptop:h-[120px]';
    }

    if (!isTwoWayConversation) {
      return '';
    }

    return {
      'h-auto': true,
      'laptop:ml-auto laptop:absolute laptop:-bottom-8 laptop:right-6 laptop:h-28 laptop:w-50 laptop:z-50 laptop:w-auto laptop:m-auto':
        (isOwnVideo || isOwnIdNaN) && isTwoWayConversation,
    };
  };

  const getVideoSize = (userId: number) => {
    const isOwnVideo = userId === ownId;
    const isOwnIdNaN = Number.isNaN(ownId);
    const isTwoWayConversation = participants.length === 2;

    if ((isTwoWayConversation && (isOwnVideo || isOwnIdNaN)) || isSharing || isViewingScreenShare) {
      return 'small';
    }

    return 'default';
  };

  const getHasThumbsUp = (senderId: number) => {
    const index = thumbsUpList.findIndex((thumbsUp) => thumbsUp.senderId === senderId);

    return index > -1;
  };

  if (params.get('inMeeting') !== 'true') {
    return (
      <div className="flex flex-col items-center">
        <h2 className="text-customGrey font-bold text-2xl font-raleway">This meeting has ended.</h2>

        <Link className="mt-4" to="/home">
          <Button buttonText="Go Home" size="large" />
        </Link>
      </div>
    );
  }

  if (connectionState === ConnectionState.Fail) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <h1 className="text-xsm leading-4">
          Couldn't reconnect. Please reload the page and re-enter the meeting.
        </h1>
        <Button
          className="w-28"
          size="large"
          buttonText="Reload Page"
          onClick={() => window.location.reload()}
        />
      </section>
    );
  }

  if (connectionState === ConnectionState.Reconnecting) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <LoadingSpinner />
        <h1 className="text-xsm leading-4">
          Encountered some network issues. Trying to reconnect...
        </h1>
      </section>
    );
  }

  if (participantsLength === 0) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <LoadingSpinner />
        <h1 className="text-xsm leading-4">Loading...</h1>
      </section>
    );
  }

  return (
    <div
      id="video-content-wrapper"
      className="relative flex flex-col w-full h-full items-center justify-center gap-4"
    >
      <div
        className={mergeClassNames(
          'hidden relative h-full max-h-full w-full max-w-max laptop:max-h-[calc(100%-136px)] items-center rounded-lg',
          { flex: isViewingScreenShare || isSharing }
        )}
      >
        {isScreenShareLoading && (
          <LoadingSpinner
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            size="large"
          />
        )}

        <video
          ref={screenShareVideoContainerRef}
          className={mergeClassNames('hidden max-h-full w-full max-w-max rounded-lg', {
            flex: isSharing && !!isStartShareScreenWithVideoElement,
          })}
        />

        <canvas
          ref={screenShareCanvasContainerRef}
          className={mergeClassNames(
            'hidden max-h-full w-full max-w-max rounded-lg object-contain',
            {
              flex: isSharing && !isStartShareScreenWithVideoElement,
            }
          )}
        />

        <canvas
          ref={screenShareViewCanvasContainerRef}
          className={mergeClassNames(
            'hidden max-h-full w-full max-w-max rounded-lg object-contain',
            {
              flex: isViewingScreenShare,
            }
          )}
        />
      </div>

      <div
        className={mergeClassNames(
          'relative flex flex-col-reverse gap-2 items-center justify-center w-full laptop:w-full laptop:flex-row short:flex-row short:max-w-[85%]',
          {
            'laptop:aspect-video laptop:h-auto laptop:max-w-[137vh]':
              participants.length > 1 && !(isSharing || isViewingScreenShare),
            'short:max-w-[40%]': participants.length === 1,
            'max-w-[500px]': participants.length > 1,
            'max-w-[300px] tablet:max-w-[350px] laptop:max-w-full': participants.length > 2,
            'hidden laptop:flex': isSharing || isViewingScreenShare,
          }
        )}
      >
        {participants.map((participant: Participant) => (
          <VideoPlayer
            id={`video-player-container-${participant.userId}`}
            className={mergeClassNames(getVideoPlayerClassName(participant.userId))}
            size={getVideoSize(participant.userId)}
            displayName={participant.displayName}
            isCameraActive={participant.bVideoOn}
            isMicrophoneActive={!participant.muted}
            isSpeaking={speakingParticipants[participant.userId]}
            hasRaisedHand={raisedHands[participant.userId]}
            hasThumbsUp={getHasThumbsUp(participant.userId)}
            key={participant.userId}
            ref={(element: VideoPlayerType) => onSetVideoRef(participant.userId, element)}
          />
        ))}
      </div>
    </div>
  );
});

export default VideoContent;
