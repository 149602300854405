import { extraServicesApis } from '@shared/apis';

import { genericMutation, genericQueryWithId, genericQueryWithParams } from './generics';

export const useCreateExtraService = genericMutation<extraServicesApis.CreateExtraService>(
  extraServicesApis.createExtraService
);

export const useSearchExtraServices = genericQueryWithParams<extraServicesApis.SearchExtraServices>(
  extraServicesApis.searchExtraServices
);

export const useGetExtraService = genericQueryWithId<extraServicesApis.GetExtraService>(
  extraServicesApis.getExtraService
);

export const useUpdateExtraService = genericMutation<extraServicesApis.UpdateExtraService>(
  extraServicesApis.updateExtraService
);

export const useDeleteExtraService = genericMutation<extraServicesApis.DeleteExtraService>(
  extraServicesApis.deleteExtraService
);

export const useGetTotalPaymentForExtraServiceByMonth =
  genericQueryWithParams<extraServicesApis.GetTotalPaymentForExtraServiceByMonth>(
    extraServicesApis.getTotalPaymentForExtraServiceByMonth
  );
