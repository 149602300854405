import Tabs from '@components/V4/Tabs/Tabs';

import CapacityTab from './CapacityTab/CapacityTab';
import CoursesAndSubjectsTab from './CoursesAndSubjectsTab/CoursesAndSubjectsTab';
import EnrollmentsTab from './EnrollmentsTab/EnrollmentsTab';
import PaymentsTab from './PaymentsTab/PaymentsTab';
import ResumeTab from './ResumeTab/ResumeTab';

export type MentorTab =
  | 'active-enrollments'
  | 'ended-enrollments'
  | 'courses-and-subjects'
  | 'capacity'
  | 'payments'
  | 'resume';

interface MentorTabsSectionProps {
  tab?: MentorTab;
}

const MentorTabsSection = (props: MentorTabsSectionProps) => {
  const { tab = 'active-enrollments' } = props;

  const tabs: { label: string; value: MentorTab }[] = [
    { label: 'Active Enrollments', value: 'active-enrollments' },
    { label: 'Ended Enrollments', value: 'ended-enrollments' },
    { label: 'Courses & Subjects', value: 'courses-and-subjects' },
    { label: 'Capacity', value: 'capacity' },
    { label: 'Payments', value: 'payments' },
    { label: 'Resume', value: 'resume' },
  ];

  const renderContent = (currentTab: MentorTab) => {
    switch (currentTab) {
      case 'active-enrollments':
        return <EnrollmentsTab areEnrollmentsActive />;
      case 'ended-enrollments':
        return <EnrollmentsTab />;
      case 'courses-and-subjects':
        return <CoursesAndSubjectsTab />;
      case 'capacity':
        return <CapacityTab />;
      case 'payments':
        return <PaymentsTab />;
      case 'resume':
        return <ResumeTab />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-6">
      <Tabs tabs={tabs} initialTab={tab} renderContent={renderContent} />
    </div>
  );
};

export default MentorTabsSection;
