import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { message } from 'antd';

const IS_FREE = false; // constant due to current business model

const edit = ({ content, title, courseId, lessonId }: any) => {
  apiClient
    .post(`/lesson/edit/${lessonId}`, {
      title,
      content,
      isFree: IS_FREE,
    })
    .then((response) => {
      message.success('Lesson saved.');
    })
    .catch((error) => {
      message.error(error.response?.data?.message || error?.message);
      message.error('An error occurred. Check console for details.');
      console.error(error);
    });
};

const add = ({ content, title, courseId, moduleId }: any) => {
  apiClient
    .post('/lesson/new', {
      title,
      content,
      isFree: IS_FREE,
      module: moduleId,
      parentCourse: courseId,
    })
    .then((response) => {
      message.success(response.data.message);
      setTimeout(() => {
        window.location.href = `/admin/lesson/edit-lesson/${courseId}/${response.data.id}/${moduleId}`;
      }, 1000);
    })
    .catch((error) => {
      message.error(error.response?.data?.message || error?.message);
      message.error('An error occurred. Check console for details.');
      console.error(error);
    });
};

const get = async ({ lessonId }: any) => {
  const response = await apiClient.get(`/page/${lessonId}?refType=Lesson`);

  return {
    title: response.data.title,
    content: response.data.content,
  };
};

const deleteEverywhere = async ({ lessonId, courseId, moduleId }: any) => {
  await apiClient.post(`/lesson/delete`, { lessonId, courseId, moduleId });
};

const LessonCRUD = { edit, add, get, deleteEverywhere };

export default LessonCRUD;
