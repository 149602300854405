import { ReactComponent as IconDisabled } from '@assets/icons/icon-button-disabled.svg';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { mergeClassNames } from '../../utils/helpers';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

type Props = {
  icon: ReactNode;
  className?: string;
  isActive?: boolean;
  size?: 'small' | 'normal' | 'large';
  variant?: 'primary' | 'secondary' | 'ghost';
  isDisabled?: boolean;
  hasWarning?: boolean;
  isLoading?: boolean;
  color?: 'default' | 'danger';
  popupText?: string | number;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = (props: Props) => {
  const {
    icon,
    isActive,
    size = 'normal',
    variant = 'primary',
    isDisabled,
    hasWarning = false,
    isLoading = false,
    className,
    color = 'default',
    popupText,
    ...buttonProps
  } = props;

  const baseButtonStyles = 'relative text-white rounded-full flex items-center justify-center';

  const variantStyles = {
    primary: {
      'text-surfaceBackground laptop:bg-transparent laptop:hover:text-surfaceBackground': true,
      'bg-customRed  laptop:text-customRed laptop:hover:bg-customRed ': color === 'danger',
      'bg-customGrey  laptop:text-customGrey laptop:hover:bg-customGrey': color === 'default',
      'bg-customGrey laptop:bg-customGrey laptop:text-surfaceBackground':
        isActive && color !== 'danger',
      'cursor-not-allowed': isDisabled,
    },
    secondary: {
      'bg-surfaceHover hover:bg-customGrey hover:text-surfaceBackground': true,
      'bg-customRed hover:bg-customRedAccent hover:text-white': isActive && color === 'danger',
      'bg-customWhite text-surfaceBackground hover:bg-white': isActive && color !== 'danger',
      'cursor-not-allowed bg-surfaceHover text-customGrey hover:bg-surfaceHover hover:text-customGrey':
        isDisabled,
    },
    ghost: {
      'text-customGrey hover:text-white': true,
      'text-customRed hover:text-customRedAccent': color === 'danger',
      'cursor-not-allowed text-disabled hover:text-disabled': isDisabled,
    },
  };

  const sizeStyles = {
    'w-10 h-10': size === 'large',
    'w-6 h-6': size === 'normal',
    'w-4 h-4': size === 'small',
  };

  const componentClass = mergeClassNames(
    baseButtonStyles,
    variantStyles[variant],
    sizeStyles,
    className
  );

  return (
    <button {...buttonProps} type="button" disabled={isDisabled} className={componentClass}>
      {!!popupText && (
        <p className="absolute top-0 right-0 bg-customRed rounded-full text-xxs w-5 h-5 text-center p-[3px]">
          {popupText}
        </p>
      )}
      {!isLoading && icon}
      {isLoading && <LoadingSpinner size="normal" />}
      {hasWarning && <IconDisabled className="absolute top-0 right-0" />}
    </button>
  );
};

export default IconButton;
