import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import Modal from '@components/V4/Modal/Modal';
import { PaginationContext } from '@components/V4/Modal/MultiPageModal/MultiPageModal';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import AIGeneratedFeedbackToggle from '../AIGeneratedFeedbackToggle';

export type SessionSummary = {
  aboutSession: string;
  nextTopics: string;
};

export const sessionSummarySchema = z.object({
  aboutSession: z.string().min(100, { message: 'Please enter at least 100 characters' }),
  nextTopics: z.string().min(1, { message: 'Please fill topics to be covered next' }),
});

export type SessionSummaryFormData = z.infer<typeof sessionSummarySchema>;

interface SessionSummaryPageProps extends BasicProps {
  isAIFeedbackReady: boolean;
  didAIFeedbackGenerationFail?: boolean;
  isFeedbackAIGenerationEnabled: boolean;
  sessionSummaryFormData: UseFormReturn<SessionSummaryFormData, any, undefined>;
  onNextClick: (sessionSummary: SessionSummary) => void;
  onIsFeedbackAIGenerationEnabledToggle: (isAIGenerated: boolean) => void;
}

const SessionSummaryPage = (props: SessionSummaryPageProps) => {
  const {
    isAIFeedbackReady,
    isFeedbackAIGenerationEnabled,
    sessionSummaryFormData,
    didAIFeedbackGenerationFail,
    onNextClick,
    onIsFeedbackAIGenerationEnabledToggle,
  } = props;

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = sessionSummaryFormData;

  const { goToNextPage } = useContext(PaginationContext);

  const touchAllFields = (fields: Record<keyof SessionSummaryFormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(
        key as keyof SessionSummaryFormData,
        getValues()[key as keyof SessionSummaryFormData],
        {
          shouldTouch: true,
          shouldValidate: true,
        }
      );
    });
  };

  const onSubmit = async (data: SessionSummaryFormData) => {
    const isValid = await trigger();
    if (isValid) {
      goToNextPage();
      onNextClick(data);
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <Modal.Body className="tablet:px-0 gap-6">
      <div className="flex flex-col gap-2 tablet:gap-0 tablet:flex-row tablet:justify-between tablet:items-center">
        <h3 className="text-customGrey text-2xl font-semibold">Session Summary</h3>
        <AIGeneratedFeedbackToggle
          isDisabled={!isAIFeedbackReady}
          didAIFeedbackGenerationFail={didAIFeedbackGenerationFail}
          isChecked={isFeedbackAIGenerationEnabled && isAIFeedbackReady}
          onClick={() => onIsFeedbackAIGenerationEnabledToggle(!isFeedbackAIGenerationEnabled)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <TextArea
          isRequired
          className="text-customGrey"
          id="aboutSession"
          label="Describe what the session was about (formerly 'Topics Covered')"
          isTouched={touchedFields.aboutSession}
          isValid={!errors.aboutSession}
          errorText={errors.aboutSession?.message}
          {...register('aboutSession')}
        />
        {isAIFeedbackReady && (
          <span className="text-xsm text-customRed">
            This feature is in beta, so please review and adjust the feedback to ensure accuracy.
          </span>
        )}
      </div>
      <h3 className="text-customGrey text-2xl font-semibold">Topics to be covered next</h3>
      <TextArea
        isRequired
        className="text-customGrey"
        id="nextTopics"
        label="Outline the topics to be covered next"
        isTouched={touchedFields.nextTopics}
        isValid={!errors.nextTopics}
        errorText={errors.nextTopics?.message}
        {...register('nextTopics')}
      />
      <div className="flex justify-end gap-4">
        <Button className="w-24" size="large" buttonText="Next" onClick={submitHandler} />
      </div>
    </Modal.Body>
  );
};

export default SessionSummaryPage;
