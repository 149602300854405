import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { MentorDetails } from '@shared/common';
import { MENTOR_STATUS_V3 } from '@shared/constants';
import { useGetAllCourses } from '@shared/react';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';
import { CreateMentorDetails } from '.';

type Props = {
  handleCurrentPage: (page: string) => void;
  mentorDetails: CreateMentorDetails;
  handleInputChangeMentor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleArrayChangeMentor: (name: keyof CreateMentorDetails, value: string) => void;
  handleArrayRemoveMentor: (name: keyof CreateMentorDetails, index: number) => void;
  handleCoursesCanTeachSelect: (courses: any[]) => void;
  handleStatusMentor: (status: any) => void;
};

const CreateNewUserMentorStepOne = ({
  handleCurrentPage,
  mentorDetails,
  handleInputChangeMentor,
  handleArrayChangeMentor,
  handleArrayRemoveMentor,
  handleCoursesCanTeachSelect,
  handleStatusMentor,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const [touched, setTouched] = useState<Record<keyof MentorDetails, boolean>>({
    fullName: false,
    phoneNumber: false,
    coursesCanTeach: false,
    mentorStatus: false,
    payRate: false,
    currency: false,
    billingCurrency: false,
    vatPayer: false,
    company: false,
    companyCountry: false,
    companyAddress: false,
    fiscalRegistrationNumber: false,
    companyIBAN: false,
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getColor = (name: keyof typeof mentorDetails) => {
    if (
      !touched[name] &&
      (name === 'coursesCanTeach' ? !mentorDetails?.[name]?.length : !mentorDetails?.[name])
    ) {
      return COLORS.TEXT_SECONDARY;
    } else {
      if (name === 'coursesCanTeach') {
        return mentorDetails?.[name]?.length ? COLORS.PRIMARY : COLORS.ERROR;
      } else {
        return mentorDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
      }
    }
  };

  const areDetailsFilled = !!(
    mentorDetails.fullName &&
    mentorDetails.phoneNumber &&
    mentorDetails.coursesCanTeach &&
    mentorDetails.coursesCanTeach.length >= 1 &&
    mentorDetails.mentorStatus
  );

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
            }}
            className="modal-page-section-title"
          >
            Basic Info/ Contact Info:
          </h3>

          <AgoraInput
            htmlFor="fullName"
            type="text"
            name="fullName"
            id="fullName"
            placeholder=""
            label="Full Name"
            color={getColor('fullName')}
            labelColor={getColor('fullName')}
            borderColor={getColor('fullName')}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.fullName}
            margin="0px 0px 24px 0px"
            onChange={handleInputChangeMentor}
            onBlur={handleBlur}
            isRequired
          />

          <AgoraInput
            htmlFor="phoneNumber"
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder=""
            label="Phone Number"
            color={getColor('phoneNumber')}
            labelColor={getColor('phoneNumber')}
            borderColor={getColor('phoneNumber')}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.phoneNumber}
            margin="0px 0px 24px 0px"
            onChange={handleInputChangeMentor}
            onBlur={handleBlur}
            isRequired
          />

          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
              // marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Mentoring Information:
          </h3>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: 'calc(70% - 12px)' }}>
              <AgoraBigSelector
                label="Courses can teach"
                labelColor={getColor('coursesCanTeach')}
                labelMargin="0 0 8px 0"
                onSelect={(value) => {
                  handleCoursesCanTeachSelect([...mentorDetails.coursesCanTeach, value]);
                  setTouched((prev) => ({
                    ...prev,
                    coursesCanTeach: true,
                  }));
                }}
                onDeselect={(value) => {
                  const updatedCourses = mentorDetails?.coursesCanTeach?.filter(
                    (course) => course._id !== value
                  );
                  handleCoursesCanTeachSelect(updatedCourses);
                }}
                options={allCourses?.map((course) => ({
                  label: course?.title,
                  value: course?._id,
                }))}
                onClear={() => {
                  handleCoursesCanTeachSelect([]);
                }}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                minHeight="38px"
                width="100%"
                borderRadius="8px"
                borderColor={getColor('coursesCanTeach')}
                mode="multiple"
                value={mentorDetails.coursesCanTeach}
                isRequired
              />
            </div>

            <div
              style={{
                width: 'calc(30% - 12px)',
                marginTop: '-9px',
              }}
            >
              <AgoraBigSelector
                label="Mentor Status"
                labelColor={getColor('mentorStatus')}
                onSelect={(value) => {
                  handleStatusMentor(value);
                  setTouched((prev) => ({
                    ...prev,
                    mentorStatus: true,
                  }));
                }}
                options={MENTOR_STATUS_V3}
                showOptionsBy="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={getColor('mentorStatus')}
                isRequired
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <span style={{ marginRight: '16px' }} className="create-new-user-modal-steps-text">
              Step 1 of 2
            </span>
            <div
              style={{
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>

              <div
                style={{
                  backgroundColor: COLORS.TEXT_SECONDARY,
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                }}
              ></div>
            </div>
          </div>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) handleCurrentPage('create-new-user-mentor-step-two');
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserMentorStepOne;
