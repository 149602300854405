import { ReactComponent as OptionsIcon } from '@assets/icons/V4/options-icon.svg';
import { ReactComponent as BinIcon } from '@assets/icons/bin-scaled-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { DropdownItem } from '@components/V4/Dropdown';
import IconButtonDropdown from '@components/V4/IconButtonDropdown';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { ReactElement } from 'react';

interface ItemContentProps extends BasicProps {
  icon: ReactElement;
  onEdit: () => void;
  onDelete: () => void;
}

const Title = (props: BasicProps) => {
  const { children } = props;
  return <p className="font-raleway bold">{children}</p>;
};

const SubTitle = (props: BasicProps) => {
  const { children } = props;
  return <p className="text-sm leading-5 text-customGrey">{children}</p>;
};

const Description = (props: BasicProps) => {
  const { children, className } = props;
  return (
    <p className={mergeClassNames('text-sm leading-5 text-customGrey', className)}>{children}</p>
  );
};

const ItemContent = (props: ItemContentProps) => {
  const { icon, children, onEdit, onDelete } = props;

  const dropdownItems: DropdownItem[] = [
    {
      icon: <EditIcon className="w-3 h-3" />,
      text: 'Edit',
      onClick: onEdit,
    },
    {
      icon: <BinIcon className="w-3 text-customRed" />,
      text: 'Delete',
      onClick: onDelete,
    },
  ];

  return (
    <div className="flex gap-4 px-4 py-6 justify-between tablet:px-8">
      {icon}
      <div className="flex flex-col gap-3 tablet:w-full">
        <div className="flex flex-col gap-2">{children}</div>
      </div>
      <IconButtonDropdown icon={<OptionsIcon className="min-w-6" />} items={dropdownItems} />
    </div>
  );
};

ItemContent.Title = Title;
ItemContent.SubTitle = SubTitle;
ItemContent.Description = Description;
export default ItemContent;
