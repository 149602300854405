import { ReactComponent as SummaryIcon } from '@assets/icons/V4/session-details/summary-icon.svg';
import { useAuthState } from '@shared/react';
import { convertHexToRGBA } from 'apps/agora/src/utils/helpers';
import SessionDetailsEmptyState from '../components/SessionDetailsEmptyState';

interface SessionSummaryCardProps {
  sessionSummary?: string;
  accentColor: string;
  needsFeedback: boolean;
  setIsMentorFeedbackOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SessionSummaryCard = (props: SessionSummaryCardProps) => {
  const { sessionSummary, accentColor, needsFeedback, setIsMentorFeedbackOpen } = props;

  const { isMentor } = useAuthState();

  const getEmptyStateText = () => {
    if (!needsFeedback) return 'No Session Summary Available.';

    return isMentor
      ? `No Session Summary Provided.
  Make sure to add it by submitting the session's feedback!`
      : 'No Session Summary provided by mentor.';
  };

  return (
    <section
      className="flex flex-col gap-6 py-6 px-8 rounded-lg "
      style={{ backgroundColor: convertHexToRGBA(accentColor, 50) }}
    >
      <div className="flex gap-4">
        <SummaryIcon className="text-white" />
        <h3 className="font-raleway font-bold text-xl">Session Summary</h3>
      </div>

      {sessionSummary ? (
        <p className="text-sm text-white leading-5 font-semibold break-words">{sessionSummary}</p>
      ) : (
        <SessionDetailsEmptyState
          icon={<SummaryIcon className="text-white" />}
          textClassName="text-white"
          text={getEmptyStateText()}
          buttonText={isMentor && needsFeedback ? 'Submit Feedback' : undefined}
          onClick={isMentor ? () => setIsMentorFeedbackOpen?.(true) : undefined}
        />
      )}
    </section>
  );
};

export default SessionSummaryCard;
