import FinishFlagIcon from '@assets/icons/Icon-awesome-flag-checkered.svg';
import ExternalLinkIconPrimary from '@assets/icons/external-link-primary.svg';
import ExternalLinkIcon from '@assets/icons/external-link.svg';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './flags-table-row.styles.scss';
import { useCloseMultipleFlags } from '@shared/react';
import EnrollmentsModalFlags from '@components/V3/EnrollmentsModalFlags';

type Props = {
  key?: string | number;
  flagData?: any;
  refetchSearchFlags: () => void;
  currentUserId: string;
  handleAddFlag: (flag: string) => void;
  handleRemoveFlag: (flag: string) => void;
};

const FlagsTableRow = ({
  key,
  flagData,
  refetchSearchFlags,
  currentUserId,
  handleAddFlag,
  handleRemoveFlag,
}: Props) => {
  const { mutate: markAsClosed } = useCloseMultipleFlags({
    onSuccess: () => {
      refetchSearchFlags();
    },
  });

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const flag = event.target.value;
    if (event.target.checked) {
      handleAddFlag(flag);
    } else {
      handleRemoveFlag(flag);
    }
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {/*ENROLLMENT MODAL*/}
      {flagData && (
        <EnrollmentsModalFlags
          showModal={showModal}
          handleShowModal={handleShowModal}
          flagType={flagData?.modelType}
          reference={flagData?.ref}
          // currentEnrolmentId={
          //   flagData?.ref?.enrollment?._id ?? flagData?.ref?._id
          // }
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {/*FLAG NAME*/}
        <div
          style={{
            width: '35%',
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
          }}
          className="flags-table-row-text"
        >
          <div
            style={{
              marginRight: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <div className="flex h-6 items-center">
              <input
                onChange={handleCheckboxChange}
                value={flagData?._id}
                style={{
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  border: '1px solid white',
                }}
                id="flags"
                aria-describedby="comments-description"
                name="flags"
                type="checkbox"
                className="h-4 w-4 rounded border-customPrimary text-customPrimary focus:ring-customPrimary"
              />
            </div>
          </div>
          <span>{flagData?.description ?? '-'}</span>
        </div>

        {/*STUDENT*/}
        <div style={{ width: '10%' }} className="flags-table-row-text">
          <span>
            {flagData?.ref?.students?.[0]?.fullName ?? flagData?.ref?.user?.fullName ?? '-'}
          </span>
        </div>

        {/*MENTOR*/}
        <div style={{ width: '10%' }} className="flags-table-row-text">
          <span>{flagData?.ref?.mentor?.fullName ?? 'TBD'}</span>
        </div>

        {/*COURSE*/}
        <div style={{ width: '10%' }} className="flags-table-row-text">
          <span>{flagData?.ref?.course?.title ?? '-'}</span>
        </div>

        {/*/!*DATE*!/*/}
        <div style={{ width: '10%' }} className="flags-table-row-text">
          <span>
            {flagData?.createdAt ? moment(flagData?.createdAt).format('DD.MM.YYYY') : '-'}
          </span>
        </div>

        {/*ACCESS*/}
        <div style={{ width: '18%', display: 'flex' }}>
          <div
            onClick={() => setShowModal(!showModal)}
            style={{ display: 'flex', marginRight: '12px' }}
            className="enrollments-action"
          >
            <img
              style={{ marginRight: '8px', minWidth: '14px', maxWidth: '14px' }}
              className="external-link-icon"
              src={ExternalLinkIcon}
              alt="enrollments"
            />
            <img
              style={{ marginRight: '8px', minWidth: '14px', maxWidth: '14px' }}
              className="external-link-icon-primary"
              src={ExternalLinkIconPrimary}
              alt="enrollments"
            />
            <span className="mentors-table-access-button">View More</span>
          </div>
          <div
            onClick={() => {
              markAsClosed({
                flagIds: [flagData?._id],
                closedBy: currentUserId,
              });
            }}
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <img
              style={{ marginRight: '8px' }}
              className="files-icon"
              src={FinishFlagIcon}
              alt="flag"
            />
            <span style={{ color: COLORS.ERROR }} className="flags-table-access-button">
              Mark as closed
            </span>
          </div>
        </div>
      </div>
      {/*DIVIDERS*/}
      <div style={{ borderTopWidth: '1px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default FlagsTableRow;
