import { MIXPANEL_TOKEN } from '@shared/frontendEnv';
import { Location as RouterLocation } from 'history';
import mixpanel from 'mixpanel-browser';
import { Types } from 'mongoose';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN || 'UNKNOWN', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const useMixpanelPageView = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<RouterLocation | null>(null);

  useEffect(() => {
    // we only send the page view if the location has changed
    if (prevLocation?.pathname === location.pathname) return;
    mixpanel.track('Page View', {
      'Page Path': getPathWithIdPlaceholders(location.pathname),
      'Page Search': getPathWithIdPlaceholders(location.search),
      'Referrer Path': prevLocation ? getPathWithIdPlaceholders(prevLocation.pathname) : '',
    });

    // Update the previous location after the current one has been tracked
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setPrevLocation(location);
  }, [location, prevLocation]);

  return null;
};

function isValidObjectId(id: string): boolean {
  return Types.ObjectId.isValid(id);
}

function getPathWithIdPlaceholders(path: string): string {
  const segments = path.split('/');
  for (let i = 0; i < segments.length; i++) {
    if (isValidObjectId(segments[i])) {
      segments[i] = '{id}';
    }
  }
  return segments.join('/');
}

export default useMixpanelPageView;
