import React from 'react';
import { COLORS } from '../../../v3/global';
import './nps-detractors-passives-modal.styles.scss';
import ArrowUp from '@assets/icons/ic-arrow-narrow-up.svg';
import ArrowDown from '@assets/icons/ic-arrow-narrow-down.svg';
import { NpsStringKeys } from '@shared/types';

type Props = {
  handleCurrentPage: (page: number) => void;
  nps: any;
  refetchNps: () => any;
  role: string;
  npsType: string;
  date: any;
};

const DetratorsAndPassivesRow = ({
  handleCurrentPage,
  nps,
  refetchNps,
  role,
  npsType,
  date,
}: Props) => {
  type NpsReasonKeys = keyof typeof NpsStringKeys['en'];

  function isWithinMonth(dateStr1: string, dateStr2: string) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    let previousMonth = month1 - 1;
    let previousMonthYear = year1;

    if (previousMonth < 0) {
      previousMonth = 11;
      previousMonthYear -= 1;
    }

    return year2 === previousMonthYear && month2 === previousMonth;
  }

  const getPhoneNumberAnchor = () => {
    const phoneNumber = nps?.userRateCurrentMonth?.from?.phoneNumber;
    const parentPhoneNumber = nps?.userRateCurrentMonth?.from?.parentData?.phoneNumber;

    if (role === 'parent' && (parentPhoneNumber || phoneNumber)) {
      return (
        <a
          href={`tel:${parentPhoneNumber ? parentPhoneNumber : phoneNumber}`}
          target="_blank"
          rel="noreferrer"
        >
          {parentPhoneNumber ? parentPhoneNumber : `${phoneNumber} (student)`}
        </a>
      );
    }

    if (phoneNumber) {
      return (
        <a href={`tel:${phoneNumber}`} target="_blank" rel="noreferrer">
          {phoneNumber}
        </a>
      );
    }

    return '-';
  };

  return (
    <tr>
      {/*NAME*/}
      <td>
        <div style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
          {role === 'parent'
            ? (nps?.userRateCurrentMonth?.from?.parentData.fullName ?? '-') +
              ' (' +
              (nps?.userRateCurrentMonth?.from?.fullName ?? '-') +
              ')'
            : nps?.userRateCurrentMonth?.from?.fullName ?? '-'}
        </div>
      </td>

      {/*RATING*/}
      <td>
        <span style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              width: '24%',
              marginRight: '1%',
            }}
          >
            <span
              style={{
                color:
                  npsType === 'education' &&
                  nps?.userRateCurrentMonth?.rating > nps?.userRateLastMonth?.rating
                    ? COLORS.GREEN
                    : npsType === 'education' &&
                      nps?.userRateCurrentMonth?.rating < nps?.userRateLastMonth?.rating
                    ? COLORS.RED
                    : npsType === 'platform' &&
                      nps?.userRateCurrentMonth?.platformRating >
                        nps?.userRateLastMonth?.platformRating
                    ? COLORS.GREEN
                    : npsType === 'platform' &&
                      nps?.userRateCurrentMonth?.platformRating <
                        nps?.userRateLastMonth?.platformRating
                    ? COLORS.RED
                    : COLORS.TEXT_SECONDARY,
              }}
              className="nps-detractors-passives-card-value"
            >
              {npsType === 'education'
                ? nps?.userRateCurrentMonth?.rating
                : npsType === 'platform'
                ? nps?.userRateCurrentMonth?.platformRating
                : '-'}
            </span>{' '}
            {npsType === 'education' &&
            nps?.userRateCurrentMonth?.rating > nps?.userRateLastMonth?.rating ? (
              <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
            ) : npsType === 'education' &&
              nps?.userRateCurrentMonth?.rating < nps?.userRateLastMonth?.rating ? (
              <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
            ) : npsType === 'platform' &&
              nps?.userRateCurrentMonth?.platformRating > nps?.userRateLastMonth?.platformRating ? (
              <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
            ) : npsType === 'platform' &&
              nps?.userRateCurrentMonth?.platformRating < nps?.userRateLastMonth?.platformRating ? (
              <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
            ) : (
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  marginLeft: '2px',
                  marginRight: '2px',
                }}
                className="nps-detractors-passives-card-value"
              >
                =
              </span>
            )}
            {/*<img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />{' '}*/}
            <span
              style={{
                color:
                  npsType === 'education' &&
                  nps?.userRateCurrentMonth?.rating > nps?.userRateLastMonth?.rating
                    ? COLORS.GREEN
                    : npsType === 'education' &&
                      nps?.userRateCurrentMonth?.rating < nps?.userRateLastMonth?.rating
                    ? COLORS.RED
                    : npsType === 'platform' &&
                      nps?.userRateCurrentMonth?.platformRating >
                        nps?.userRateLastMonth?.platformRating
                    ? COLORS.GREEN
                    : npsType === 'platform' &&
                      nps?.userRateCurrentMonth?.platformRating <
                        nps?.userRateLastMonth?.platformRating
                    ? COLORS.RED
                    : COLORS.TEXT_SECONDARY,
              }}
              className="nps-detractors-passives-card-value"
            >
              {npsType === 'education'
                ? nps?.userRateLastMonth?.rating
                : npsType === 'platform'
                ? nps?.userRateLastMonth?.platformRating
                : '-'}

              {!nps?.userRateLastMonth
                ? '?'
                : npsType === 'education' && isWithinMonth(date, nps?.userRateLastMonth?.createdAt)
                ? ''
                : npsType === 'education' && !isWithinMonth(date, nps?.userRateLastMonth?.createdAt)
                ? '*'
                : npsType === 'platform' && isWithinMonth(date, nps?.userRateLastMonth?.createdAt)
                ? ''
                : npsType === 'platform' && !isWithinMonth(date, nps?.userRateLastMonth?.createdAt)
                ? '*'
                : '?'}
            </span>
          </div>
        </span>
      </td>

      {/*REASONS*/}
      <td>
        <p style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
          {nps?.userRateCurrentMonth?.reasons?.education?.length > 0 &&
            npsType === 'education' &&
            `Edu: ${nps?.userRateCurrentMonth?.reasons?.education
              .map((reason: NpsReasonKeys) => NpsStringKeys['en'][reason])
              .join('; ')}`}

          {nps?.userRateCurrentMonth?.reasons?.education?.length > 0 &&
            nps?.userRateCurrentMonth?.reasons?.otherEducation?.length > 0 &&
            npsType === 'education' && <br />}

          {nps?.userRateCurrentMonth?.reasons?.otherEducation?.length > 0 &&
            npsType === 'education' &&
            `Other: ${nps?.userRateCurrentMonth?.reasons?.otherEducation}`}

          {nps?.userRateCurrentMonth?.reasons?.platform?.length > 0 &&
            npsType === 'platform' &&
            `Platform: ${nps?.userRateCurrentMonth?.reasons?.platform
              .map((reason: NpsReasonKeys) => NpsStringKeys['en'][reason])
              .join('; ')}`}

          {nps?.userRateCurrentMonth?.reasons?.platform?.length > 0 &&
            nps?.userRateCurrentMonth?.reasons?.otherPlatform?.length > 0 &&
            npsType === 'platform' && <br />}

          {nps?.userRateCurrentMonth?.reasons?.otherPlatform?.length > 0 &&
            npsType === 'platform' &&
            `Other: ${nps?.userRateCurrentMonth?.reasons?.otherPlatform}`}
        </p>
      </td>

      {/*CONTACT*/}
      <td>
        <div
          style={{ color: COLORS.TEXT_PRIMARY }}
          className="modal-row-column-text NPS-modal-table-contact-span"
        >
          {getPhoneNumberAnchor()}
        </div>
        <div style={{ color: COLORS.TEXT_PRIMARY }} className="modal-row-column-text">
          {nps?.userRateCurrentMonth?.from?.email ? (
            <a
              href={`mailto:${
                role === 'parent'
                  ? nps?.userRateCurrentMonth?.from?.parentData.email
                  : nps?.userRateCurrentMonth?.from?.email
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {role === 'parent'
                ? nps?.userRateCurrentMonth?.from?.parentData.email
                : nps?.userRateCurrentMonth?.from?.email}
            </a>
          ) : (
            '-'
          )}
        </div>
      </td>
    </tr>
  );
};

export default DetratorsAndPassivesRow;
