import { LanguageExam } from '@shared/common';
import moment from 'moment';
import PastExamsSection from './PastExamsSection/PastExamsSection';
import UpcomingExamsSection from './UpcomingExamsSection/UpcomingExamsSection';

interface ExamsTabProps {
  languageExams: LanguageExam[];
  userId: string;
  isMentorViewer: boolean;
}

const ExamsTab = (props: ExamsTabProps) => {
  const { languageExams, userId, isMentorViewer } = props;

  const pastLanguageExams = languageExams.filter((exam) => moment(exam.date).isBefore(moment()));

  const upcomingLanguageExams = languageExams.filter((exam) =>
    moment(exam.date).isSameOrAfter(moment())
  );

  return (
    <section className="flex flex-col gap-4">
      <UpcomingExamsSection
        isMentorViewer={isMentorViewer}
        userId={userId}
        languageExams={upcomingLanguageExams}
      />

      <PastExamsSection userId={userId} languageExams={pastLanguageExams} />
    </section>
  );
};

export default ExamsTab;
