import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import styles from '@styles/components/buttons-v3.module.scss';
import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { COLORS } from '../../../v3/global';

type Props = {
  buttonText?: string;
  icon?: string | ReactNode;
  disabledIcon?: string | ReactNode;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SecondaryButtonV3 = ({
  buttonText,
  icon,
  disabledIcon,
  disabled,
  className,
  isLoading = false,
  ...buttonProps
}: Props) => {
  return (
    <button
      {...buttonProps}
      style={{
        backgroundColor: COLORS.TRANSPARENT,
        color: COLORS.WHITE,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.25 : 1,
      }}
      type="button"
      className={classNames(`${styles.buttonV3} ${styles.buttonV3Secondary}`, className)}
    >
      <div className={styles.buttonV3Content}>
        {disabled && disabledIcon ? (
          typeof disabledIcon === 'string' ? (
            <img src={disabledIcon} className={styles.buttonV3CustomIcon} />
          ) : (
            <span className={styles.buttonV3AntIcon}>{disabledIcon}</span>
          )
        ) : icon ? (
          typeof icon === 'string' ? (
            <img src={icon} className={styles.buttonV3CustomIcon} />
          ) : (
            <span className={styles.buttonV3AntIcon}>{icon}</span>
          )
        ) : null}
        <span style={{ color: COLORS.TEXT_PRIMARY }} className={styles.buttonV3Text}>
          {isLoading ? <LoadingSpinner /> : buttonText}
        </span>
      </div>
    </button>
  );
};

export default SecondaryButtonV3;
