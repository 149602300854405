import { CaretDownOutlined, CaretUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import FlagsOverview from '@components/V3/FlagsOverview';
import FlagsTableHeader from '@components/V3/FlagsTableHeader';
import FlagsTableRow from '@components/V3/FlagsTableRow';
import TableColumns from '@components/V3/TableColumns';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useAuthState, useCloseMultipleFlags, useSearchFlags } from '@shared/react';
import { message, Pagination } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './flags.styles.scss';

const Flags = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<'desc' | 'asc'>('desc');
  const [isActive, setIsActive] = useState('0');
  const [flagType, setFlagType] = useState<string | undefined>(undefined);
  const [startRange, setStartRange] = useState();
  const [endRange, setEndRange] = useState();
  const [courseId, setCourseId] = useState();
  const [studentId, setStudentId] = useState();
  const [mentorId, setMentorId] = useState();
  const [selectedFlags, setSelectedFlags] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState('Flags');
  const [userStatus, setUserStatus] = useState('indeterminate');

  const defaultStartDate = moment().subtract(3, 'months').startOf('month').toISOString();
  const defaultEndDate = moment().toISOString();

  const {
    data: flags,
    isLoading: isLoadingSearchFlags,
    refetch: refetchSearchFlags,
  } = useSearchFlags({
    page: currentPage,
    limit: pageSize,
    sort: sort,
    search: search,
    isActive: isActive,
    flagType: flagType,
    startRange: startRange,
    endRange: endRange,
    courseId: courseId,
    studentId: studentId,
    mentorId: mentorId,
    userStatus: userStatus,
  });

  const { mutate: markAsClosed } = useCloseMultipleFlags({
    onSuccess: () => {
      message.success('Selected flags are now marked as closed');
      refetchSearchFlags();
    },
    onError: () => {
      message.error("Error: Couldn't mark selected flags as closed");
    },
  });

  const currentUser = useAuthState();

  const handleFlagType = (flagType: string | undefined) => {
    setFlagType(flagType);
    setCurrentPage(1);
  };

  const handleStartRange = (startRange: any) => {
    setStartRange(startRange);
    setCurrentPage(1);
  };

  const handleEndRange = (endRange: any) => {
    setEndRange(endRange);
    setCurrentPage(1);
  };

  const handleCourseId = (courseId: any) => {
    setCourseId(courseId);
    setCurrentPage(1);
  };

  const handleStudentId = (studentId: any) => {
    setStudentId(studentId);
    setCurrentPage(1);
  };

  const handleMentorId = (mentorId: any) => {
    setMentorId(mentorId);
    setCurrentPage(1);
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    setCurrentPage(1);
  };

  const handleActiveFlags = (active: string) => {
    setIsActive(active);
    setCurrentPage(1);
  };

  const handleAddFlag = (flag: string) => {
    setSelectedFlags((prevSelectedFlags) => [...prevSelectedFlags, flag]);
  };

  const handleRemoveFlag = (flag: string) => {
    setSelectedFlags((prevSelectedFlags) => prevSelectedFlags.filter((item) => item !== flag));
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleUserStatus = (userStatus: string) => {
    setUserStatus(userStatus);
    setCurrentPage(1);
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        color: COLORS.TEXT_PRIMARY,
        minHeight: 'calc(100vh - 74px)',
      }}
    >
      {/*TABLE HEADER*/}
      <FlagsTableHeader
        handleFlagType={handleFlagType}
        handleStartRange={handleStartRange}
        handleEndRange={handleEndRange}
        handleCourseId={handleCourseId}
        handleStudentId={handleStudentId}
        handleMentorId={handleMentorId}
        handleSearch={handleSearch}
        handleActiveFlags={handleActiveFlags}
        handleSelectedTab={handleSelectedTab}
        handleUserStatus={handleUserStatus}
        selectedTab={selectedTab}
        startRange={startRange ?? defaultStartDate}
        endRange={endRange ?? defaultEndDate}
      />

      {selectedTab === 'Flags' ? (
        // FLAGS TABLE
        <div>
          <div className="table-container">
            {/*TABLE COLUMNS*/}
            <div style={{ marginBottom: '16px' }}>
              <TableColumns>
                <div className="table-columns-container">
                  <div style={{ width: '35%', marginRight: '8px' }}>
                    <span>Flag</span>
                  </div>
                  <div style={{ width: '10%', marginRight: '8px' }}>
                    <span>Student</span>
                  </div>
                  <div style={{ width: '10%', marginRight: '8px' }}>
                    <span>Mentor</span>
                  </div>
                  <div style={{ width: '10%', marginRight: '8px' }}>
                    <span>Course</span>
                  </div>
                  <div
                    className="table-filter-columns-container"
                    onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
                  >
                    <span style={{ marginRight: '8px' }}>Date</span>
                    {sort === 'asc' ? (
                      <CaretUpOutlined
                        style={{
                          color: COLORS.TEXT_PRIMARY,
                        }}
                        className="sort-buttons-caret-icon"
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{
                          color: COLORS.TEXT_PRIMARY,
                        }}
                        className="sort-buttons-caret-icon"
                      />
                    )}
                    {/*<TableColumnSortButtons sortType={sort} />*/}
                  </div>
                  <div style={{ width: '25%' }}>
                    {selectedFlags?.length > 0 ? (
                      <SecondaryButton
                        margin="0px 8px 0px 0px"
                        onClick={() => {
                          markAsClosed({
                            flagIds: selectedFlags,
                            closedBy: currentUser?.userId,
                          });
                        }}
                        borderColor={COLORS.ERROR}
                      >
                        <span style={{ color: COLORS.ERROR, whiteSpace: 'nowrap' }}>
                          Mark selected as completed
                        </span>
                      </SecondaryButton>
                    ) : (
                      <span>Access</span>
                    )}
                  </div>
                </div>
              </TableColumns>
            </div>

            {/*TABLE ROWS*/}
            {!isLoadingSearchFlags ? (
              flags?.flags?.map((item) => (
                <FlagsTableRow
                  currentUserId={currentUser?.userId}
                  key={item?._id}
                  flagData={item}
                  refetchSearchFlags={refetchSearchFlags}
                  handleAddFlag={handleAddFlag}
                  handleRemoveFlag={handleRemoveFlag}
                />
              ))
            ) : (
              <AgoraSpinner fontSize={32} margin="32px" />
            )}
          </div>

          {/*PAGINATION*/}
          <div
            style={{
              width: '100%',
              padding: '24px 24px 120px 24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span
                style={{ color: COLORS.TEXT_SECONDARY, marginRight: '8px' }}
                className="flags-table-pagination-label"
              >
                Rows per page
              </span>
              <select
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setPageSize(Number(selectedValue));
                }}
                className="mentors-row-custom-select-component pagination-select-container"
                style={{
                  borderColor: COLORS.TEXT_PRIMARY,
                  color: COLORS.TEXT_PRIMARY,
                  backgroundColor: COLORS.SECTION_BACKGROUND,
                }}
                defaultValue={10}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={flags?.total}>All</option>
              </select>
            </div>

            <div
              style={{ color: COLORS.TEXT_SECONDARY }}
              className="mentors-table-pagination-label"
            >
              {`${(currentPage - 1) * pageSize + 1}-${Math.min(
                currentPage * pageSize,
                flags?.total || 0
              )} of ${flags?.total || 0}`}
            </div>

            {/*PAGINATION NAVIGATOR*/}
            <div>
              <Pagination
                className="custom-pagination"
                showSizeChanger={false}
                current={currentPage}
                defaultCurrent={1}
                total={flags?.total}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                prevIcon={
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LeftOutlined
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                      }}
                    />
                  </div>
                }
                nextIcon={
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RightOutlined
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: '100%' }} className="table-container">
          <FlagsOverview
            search={search}
            isActive={isActive}
            flagType={flagType}
            startRange={startRange ?? defaultStartDate}
            endRange={endRange ?? defaultEndDate}
            studentId={studentId}
            mentorId={mentorId}
            courseId={courseId}
          />
        </div>
      )}
    </div>
  );
};

export default Flags;
