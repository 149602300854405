import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-react';
import { AUTH0_AUDIENCE } from '@shared/frontendEnv';

let getAccessTokenSilently: ((options?: GetTokenSilentlyOptions) => Promise<string>) | null = null;

let logout: ((options?: LogoutOptions) => Promise<void>) | null = null;

export const setGetAccessTokenSilently = (fn: typeof getAccessTokenSilently) => {
  getAccessTokenSilently = fn;
};

export const setLogout = (fn: typeof logout) => {
  logout = fn;
};

export const getTokenSilently = async () => {
  if (!getAccessTokenSilently) throw new Error('Auth0 client not initialized');
  return await getAccessTokenSilently({
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
      scope: 'openid profile email offline_access',
    },
  });
};

export const globalLogout = async () => {
  if (!logout) throw new Error('Auth0 client not initialized');

  return await logout();
};
