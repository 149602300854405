import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import { CheckCircleFilled } from '@ant-design/icons';
import IconBookReaderPrimary from '@assets/icons/icon-awesome-book-reader-primary.svg';
import IconBookReader from '@assets/icons/icon-awesome-book-reader.svg';
import IconAwesomeUserPrimary from '@assets/icons/icon-awesome-user-alt-primary.svg';
import IconAwesomeUser from '@assets/icons/icon-awesome-user-alt.svg';
import IconAwesomeUserGraduatePrimary from '@assets/icons/icon-awesome-user-graduate-primary.svg';
import IconAwesomeUserGraduate from '@assets/icons/icon-awesome-user-graduate.svg';
import './create-new-user.styles.scss';
import hubspotLoading from '@assets/lottie/hubspot-loading.json';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useExternalData } from '@shared/react';
import Lottie from 'react-lottie';

type Props = {
  email: string;
  handleCurrentPage: (page: string) => void;
  role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined;
  handleRole: (role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined) => void;
  handleHubspotUserData: (userData: any) => void;
  hubspotUserData: any;
  handleEmailError: (emailError: string) => void;
};

const CreateNewUserStepTwo = ({
  email,
  handleCurrentPage,
  role,
  handleRole,
  handleHubspotUserData,
  hubspotUserData,
  handleEmailError,
}: Props) => {
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const { data: userData } = useExternalData(
    {
      email: email,
    },
    {
      onSuccess: () => {
        setIsLoadingUserData(false);
      },
      onError: (error: any) => {
        if (error.response.data.error.code === 'USER_ALREADY_EXISTS') {
          handleCurrentPage('create-new-user-step-one');
          handleEmailError(error.response.data.error.message);
        } else if (error.response.data.error.code === 'USER_NOT_FOUND') {
          setIsLoadingUserData(false);
        }
      },
      retry: false,
    }
  );

  const [isHoveredStudent, setIsHoveredStudent] = useState(false);
  const [isHoveredMentor, setIsHoveredMentor] = useState(false);
  const [isHoveredAdmin, setIsHoveredAdmin] = useState(false);

  // const [selectedRole, setSelectedRole] = useState<string | undefined>();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hubspotLoading,
    rendererSettings: {
      preserveAspectRatio: 'xSmalYMid slice',
    },
    clickToPause: false,
  };

  const handleMouseEnter = (role: string) => {
    if (role === 'STUDENT') {
      setIsHoveredStudent(true);
    } else if (role === 'MENTOR') {
      setIsHoveredMentor(true);
    } else {
      setIsHoveredAdmin(true);
    }
  };

  const handleMouseLeave = (role: string) => {
    if (role === 'STUDENT') {
      setIsHoveredStudent(false);
    } else if (role === 'MENTOR') {
      setIsHoveredMentor(false);
    } else {
      setIsHoveredAdmin(false);
    }
  };

  useEffect(() => {
    if (userData) {
      handleHubspotUserData(userData);
    }
  }, [userData]);

  const getUserRoleMessage = () => {
    if (!hubspotUserData) {
      return `Contact with email ${email} was not found in Hubspot. Please double-check the email or select a user role manually.`;
    }

    if (!role) {
      return '';
    }

    if (role.toLocaleLowerCase() !== hubspotUserData.role.toLocaleLowerCase()) {
      return (
        <p style={{ color: COLORS.ERROR }} className="create-new-user-modal-role-description">
          Default HubSpot contact role was overridden. Data integrity might be affected
        </p>
      );
    }

    return (
      <p
        style={{ color: COLORS.TEXT_SECONDARY }}
        className="create-new-user-modal-role-description"
      >
        A user role was detected automatically from an existing HubSpot contact. Changing this role
        might affect data integrity.
      </p>
    );
  };

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {!isLoadingUserData ? (
          <div>
            <h3
              style={{
                color: COLORS.TEXT_SECONDARY,
                marginBottom: '48px',
                marginTop: '32px',
              }}
              className="modal-page-section-title"
            >
              Select the user role:
            </h3>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                marginBottom: '48px',
              }}
            >
              {/*STUDENT*/}
              <div
                onClick={() => handleRole('STUDENT')}
                onMouseEnter={() => handleMouseEnter('STUDENT')}
                onMouseLeave={() => handleMouseLeave('STUDENT')}
                style={{
                  cursor: 'pointer',
                  backgroundColor: COLORS.DARK_GREY4,
                  width: '10.5vw',
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '200px',
                  maxHeight: '200px',
                  height: '10.5vw',
                  borderRadius: '16px',
                  border: `2px solid ${role === 'STUDENT' ? COLORS.PRIMARY : 'transparent'}`,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginRight: 'calc(25% - 16px)',
                      marginTop: '-8px',
                      height: '13px',
                    }}
                  >
                    <CheckCircleFilled
                      style={{
                        display: role === 'STUDENT' ? 'flex' : 'none',
                        fontSize: 13,
                        color: COLORS.PRIMARY,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        isHoveredStudent || role === 'STUDENT'
                          ? IconBookReaderPrimary
                          : IconBookReader
                      }
                      style={{ width: '44px', marginBottom: '16px' }}
                    />
                    <span
                      style={{
                        color:
                          isHoveredStudent || role === 'STUDENT'
                            ? COLORS.PRIMARY
                            : COLORS.TEXT_SECONDARY,
                      }}
                      className="create-new-user-modal-role-card-title"
                    >
                      Student
                    </span>
                  </div>
                </div>
              </div>

              {/*MENTOR*/}
              <div
                onClick={() => handleRole('MENTOR')}
                onMouseEnter={() => handleMouseEnter('MENTOR')}
                onMouseLeave={() => handleMouseLeave('MENTOR')}
                style={{
                  cursor: 'pointer',
                  backgroundColor: COLORS.DARK_GREY4,
                  width: '10.5vw',
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '200px',
                  maxHeight: '200px',
                  height: '10.5vw',
                  borderRadius: '16px',
                  border: `2px solid ${role === 'MENTOR' ? COLORS.PRIMARY : 'transparent'}`,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginRight: 'calc(25% - 16px)',
                      marginTop: '-8px',
                      height: '13px',
                    }}
                  >
                    <CheckCircleFilled
                      style={{
                        display: role === 'MENTOR' ? 'flex' : 'none',
                        fontSize: 13,
                        color: COLORS.PRIMARY,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        isHoveredMentor || role === 'MENTOR'
                          ? IconAwesomeUserGraduatePrimary
                          : IconAwesomeUserGraduate
                      }
                      style={{ width: '44px', marginBottom: '16px' }}
                    />
                    <span
                      style={{
                        color:
                          isHoveredMentor || role === 'MENTOR'
                            ? COLORS.PRIMARY
                            : COLORS.TEXT_SECONDARY,
                      }}
                      className="create-new-user-modal-role-card-title"
                    >
                      Mentor
                    </span>
                  </div>
                </div>
              </div>

              {/*ADMIN*/}
              <div
                onClick={() => handleRole('ADMIN')}
                onMouseEnter={() => handleMouseEnter('ADMIN')}
                onMouseLeave={() => handleMouseLeave('ADMIN')}
                style={{
                  cursor: 'pointer',
                  backgroundColor: COLORS.DARK_GREY4,
                  height: '10.5vw',
                  width: '10.5vw',
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '200px',
                  maxHeight: '200px',
                  borderRadius: '16px',
                  border: `2px solid ${role === 'ADMIN' ? COLORS.PRIMARY : 'transparent'}`,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginRight: 'calc(25% - 16px)',
                      marginTop: '-8px',
                      height: '13px',
                    }}
                  >
                    <CheckCircleFilled
                      style={{
                        display: role === 'ADMIN' ? 'flex' : 'none',
                        fontSize: 13,
                        color: COLORS.PRIMARY,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        isHoveredAdmin || role === 'ADMIN'
                          ? IconAwesomeUserPrimary
                          : IconAwesomeUser
                      }
                      style={{ width: '44px', marginBottom: '16px' }}
                    />
                    <span
                      style={{
                        color:
                          isHoveredAdmin || role === 'ADMIN'
                            ? COLORS.PRIMARY
                            : COLORS.TEXT_SECONDARY,
                      }}
                      className="create-new-user-modal-role-card-title"
                    >
                      Admin
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>{getUserRoleMessage()}</div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <div
              style={{
                marginTop: '-160px',
              }}
            >
              <Lottie
                isClickToPauseDisabled={true}
                options={defaultOptions}
                height={300}
                width={300}
                style={{ cursor: 'default' }}
              />
            </div>
            <p
              style={{
                color: COLORS.TEXT_PRIMARY,
                marginTop: '-80px',
              }}
              className="hubspot-loading-text"
            >
              Checking HubSpot…
            </p>
          </div>
        )}

        {!isLoadingUserData && (
          <SecondaryButton
            onClick={() => {
              if (role) {
                if (role === 'STUDENT') {
                  handleCurrentPage('create-new-user-student-step-one');
                } else if (role === 'MENTOR') {
                  handleCurrentPage('create-new-user-mentor-step-one');
                } else {
                  handleCurrentPage('create-new-user-admin-step-one');
                }
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="12px 0 0 0"
            width="100%"
            disabled={!role}
          >
            <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
              Continue
            </span>
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default CreateNewUserStepTwo;
