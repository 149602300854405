import { UPGRADE_EDUCATION_EMAIL } from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import { Meeting, Student, User } from '@shared/types';
import { feedbackNotProvidedNotification } from '@shared/utils/mailTemplates/feedbackNotProvidedNotification';
import { feedbackNotification } from '@shared/utils/mailTemplates/feedbackNotification';

import moment from 'moment';
import { lateFeedbackConfirm } from './mailTemplates/lateFeedbackReviewedNotification';

type MailLayoutOptions = {
  contentAlign?: 'left' | 'center' | 'right';
};

export const mailLayout = (
  content: string,
  options: MailLayoutOptions = { contentAlign: 'center' }
) => `
  <html>
    <head>
      <meta name="x-apple-disable-message-reformatting" />
    </head>

    <body>
      <p>
        <img
          style="width: 20%; height: auto;"
          src="https://static.upg.li/upgrade-logo-color.png"
          alt=""
        />
      </p>
      <div style="margin: 0 7%; text-align: ${options.contentAlign};">
        <div style="max-width: 45rem; margin: 0 auto;">${content}</div>
        <div style="border-top: 0.5px solid #C7C7C7; max-width: 500px; margin: 0 auto;"></div>
        <div style="margin: 0 15%; padding-top: 30px; text-align: center">
          ${subText(
            'Agora is a platform by Upgrade Education meant to connect students and mentors to make dreams come true'
          )}
          <p style="padding-bottom: 30px;">
            <a
              style="margin: 0 10px;"
              href="https://www.facebook.com/upgradeeducationglobal"
              ><img
                style="height: 20px;"
                src="https://static.upg.li/icons/facebook.png" /></a
            ><a style="margin: 0 10px;" href="https://wa.me/40758962473"
              ><img
                style="height: 20px;"
                src="https://static.upg.li/icons/whatsapp.png" /></a
            ><a
              style="margin: 0 10px;"
              href="https://www.linkedin.com/company/upgradeeducation/mycompany/"
              ><img
                style="height: 20px;"
                src="https://static.upg.li/icons/linkedin.png" /></a
            ><a
              style="margin: 0 10px;"
              href="https://www.instagram.com/upgrade.education.global/"
              ><img
                style="height: 20px;"
                src="https://static.upg.li/icons/instagram.png"
            /></a>
          </p>
          ${subText('10A Dimitrie Pompeiu Blvd. 1st Floor <br />Bucharest, Romania')}
        </div>
      </div>
    </body>
  </html>
`;

export const anchor = (text: string, href: string) =>
  `<a href=${href} style="color: #5f64d0; overflow-wrap: anywhere;">${text}</a>`;

export const header = (
  text: string
) => `<p style="font-size: 24px; padding-bottom: 30px;">${text}</p>
`;

export const paragraph = (text: string) =>
  `<p style="font-size: 18px; padding-bottom: 30px;">${text}</p>`;

export const subText = (text: string) =>
  `<p style="font-size: 14px; color: #c7c7c7; padding-bottom: 30px;">${text}</p>`;

// export const fancyBtn = (link: string, text: string) =>
//   `<div style="font-family: arial; margin: 0 auto; margin-bottom: 25px; position: relative; width: fit-content; height: fit-content; padding: 18px 9px;">
//     <div style="position: absolute; border-radius: 6px; width: 100%; height: 100%; top: 0px; left: 0; filter: blur(12px); background: transparent linear-gradient(90deg, #34377A 0%, #981F62 16%, #F86041 58%, #FEA746 74%, #33A9AB 100%) 0% 0% no-repeat padding-box;"></div>
//     <a href=${link} style="position: relative; font-size: 18px; overflow-wrap: anywhere; border-radius: 6px; background-color: #000000; padding: 15px 25px; text-decoration: none; color: #FFFFFF;">
//       ${text}
//     </a>
//   </div>`;

// !! replace bg-img link from static.upg
export const fancyBtn = (link: string, text: string) =>
  `<div style="font-family: arial; margin: 0 auto; margin-bottom: 25px; position: relative; width: fit-content; height: fit-content; padding: 18px 9px;">
    <a href=${link} style="position: relative; background-image: url('./bg.jpg'); background-size: 'contain';
    background-repeat: no-repeat; background-position: center; font-size: 18px; overflow-wrap: anywhere; border-radius: 6px; padding: 15px 25px; text-decoration: none; color: #000;">
      ${text}
    </a>
  </div>`;

export const resetPasswordMail = (link: string) => {
  const url = anchor(link, link);
  const here = anchor('here', link);

  const content =
    header('Hi there! We received a request from you to change your password.') +
    paragraph(
      "If this wasn't requested by you, feel free to ignore this email, nobody can change your password without this link, and nobody can see your password at all."
    ) +
    paragraph(`Click ${here} to change your password- or copy this URL in your browser`) +
    paragraph(url) +
    subText('Good luck on your learning journey!<br/>The UA Team');

  return mailLayout(content);
};

type PaymentRequestMail = {
  parentName: string;
  studentFirstName: string;
  amountDue: string;
  dateDue: string;
  paymentUrl: string;
  invoiceId: string;
  invoiceDownloadUrl: string;
};
export const paymentRequestEmail = (data: PaymentRequestMail) => {
  const downloadAnchor = anchor('here', data.invoiceDownloadUrl);

  const content =
    header(
      `Hi, ${data.parentName}! We’re happy that ${data.studentFirstName} has joined Upgrade Education.`
    ) +
    paragraph(
      `Here is some information about your payment due in 2 weeks.<br/><br/>Amount due: ${data.amountDue} &euro;<br/>Date due: ${data.dateDue}<br/><br/>You can pay via credit/debit card, bank transfer or iDeal by clicking on the link below.`
    ) +
    paragraph(
      `${data.studentFirstName}’s will get full access to the platform right after the payment goes through. We’ll send them their login information by email.`
    ) +
    anchor('Click here to pay', data.paymentUrl) +
    subText(
      `If you have any questions, contact us by replying to this email. You can download invoice ${data.invoiceId} by clicking ${downloadAnchor}.<br/><br/>Best wishes,<br/>Upgrade Team`
    );

  return mailLayout(content);
};

type WelcomeEmailProps = {
  userId: string;
  registrationToken: string;
};

const activationLink = (userId: string, registrationToken: string) =>
  `${BASE_URL}/auth/welcome?id=${userId}&token=${registrationToken}`;
export const welcomeStudentEmail = ({ userId, registrationToken }: WelcomeEmailProps) => {
  const link = activationLink(userId, registrationToken);
  const content =
    header('Welcome to Upgrade Education!') +
    paragraph(
      'We have just enrolled you on our e-learning platform Agora. Here you will find all of the details regarding your academic program and conduct your mentoring sessions. Please follow the link below to activate your account.'
    ) +
    anchor(link, link) +
    paragraph(`Once you are on the platform, please familiarize yourself with the resources available
<br/><br/>`) +
    subText(`What's next?<br/>
    One of our mentors will contact you to schedule your first session a week before you are scheduled to start your first course.
<br/><br/>
Looking forward to seeing you on Agora!
<br/><br/>
Warmly,
<br/>
The Upgrade Education Team`);

  return mailLayout(content);
};
welcomeStudentEmail.link = ({ userId, registrationToken }: WelcomeEmailProps) => {
  return activationLink(userId, registrationToken);
};

export const welcomeMentorEmail = ({ userId, registrationToken }: WelcomeEmailProps) => {
  const link = activationLink(userId, registrationToken);
  const content =
    header('Welcome to Upgrade Education!') +
    paragraph(
      'We have just enrolled you on our e-learning platform Agora. Here you will find the resources needed for conducting your mentorship sessions. Please follow the link below to activate your account.'
    ) +
    anchor(link, link) +
    paragraph(`Once you are on the platform, please familiarize yourself with the resources available by going through the course materials and the mentor training courses. 
<br/><br/>`) +
    subText(`What's next?<br/>
Once you are allocated your first student, you will receive an email informing you of the student's name and their course enrolment. Please contact them within 24 hours of receiving the mentor allocation email. This will be the case for every student you are allocated.
<br/><br/>
Looking forward to seeing you on Agora!
<br/><br/>
Warmly,
<br/>
The Upgrade Education Team`);

  return mailLayout(content);
};

welcomeMentorEmail.link = ({ userId, registrationToken }: WelcomeEmailProps) => {
  return activationLink(userId, registrationToken);
};

export const welcomeAdminEmail = ({ userId, registrationToken }: WelcomeEmailProps) => {
  const link = activationLink(userId, registrationToken);
  const content =
    header('Welcome to Upgrade Education!') +
    paragraph('Please follow the link below to activate your account.') +
    anchor(link, link) +
    subText(`<br/><br/>
Looking forward to seeing you on Agora!
<br/><br/>
Warmly,
<br/>
The Upgrade Education Team`);

  return mailLayout(content);
};

welcomeAdminEmail.link = ({ userId, registrationToken }: WelcomeEmailProps) => {
  return activationLink(userId, registrationToken);
};

const fullStar = '<img style="height: 24px;" src="https://static.upg.li/icons/star-full.png" />';
const emptyStar = '<img style="height: 24px;" src="https://static.upg.li/icons/star-empty.png" />';
const halfStar = '<img style="height: 24px;" src="https://static.upg.li/icons/star-half.png" />';

export const ratingStats = (rating: number /* 1-5 */) => {
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating % 1);
  const emptyStars = 5 - fullStars - halfStars;

  let stars = '';
  for (let i = 0; i < fullStars; i++) {
    stars += fullStar;
  }
  if (halfStars) {
    stars += halfStar;
  }
  for (let i = 0; i < emptyStars; i++) {
    stars += emptyStar;
  }

  return paragraph(
    `<p style="font-size: 34px;">${rating.toFixed(
      1
    )}</p><p style="padding-bottom: 30px;">${stars}</p>`
  );
};

type MentorRatingMail = {
  rating: number;
  lastRating?: number;
  month: string;
};
export const mentorRatingMail = (data: MentorRatingMail) => {
  // const kbAnchor = anchor(
  //   'Knowledge Base',
  //   `${BASE_URL}/mentor/knowledge-base`
  // );

  const message = !data.lastRating
    ? ''
    : data.rating < data.lastRating
    ? paragraph(
        'This is a bit lower than last month but we believe good things come to those who try.'
      )
    : paragraph(
        'This is an improvement from last month and it goes to show that hard works pays off.'
      );

  const content = header(`Your rating for ${data.month} was`) + ratingStats(data.rating) + message;
  // subText(`For a better teaching experience check out our ${kbAnchor}`);

  return mailLayout(content);
};

type StudentMissedMeetingMail = {
  studentName: string;
  date: string | Date;
};
export const studentMissedMeetingMail = (data: StudentMissedMeetingMail) => {
  const date = moment(data.date).format('MMMM Do');
  const content =
    paragraph(`Dear ${data.studentName},`) +
    paragraph(
      `You missed our meeting scheduled for ${date}. I wanted to check-in with you to make sure you are alright. I would also like to gently remind you that we have a strict policy regarding missing sessions, so if this was not an emergency please make sure this does not happen again. I would like to reschedule our meeting. When are you available in the upcoming days?`
    ) +
    paragraph('Kind regards,');

  return mailLayout(content);
};

type StudentReschedualeMeetingMail = {
  studentName: string;
  mentorName: string;
  meetingName: string;
  date: string | Date;
};
export const studentReschedualeMeetingMail = (data: StudentReschedualeMeetingMail) => {
  const date = moment(data.date).format('MMMM Do');
  const content =
    paragraph(`Dear ${data.studentName},`) +
    paragraph(
      `${data.mentorName} rescheduled the following meeting: <b>${data.meetingName}</b> for ${date}. Please reply if this interval doesn't work for you.`
    ) +
    paragraph('Kind regards,');

  return mailLayout(content);
};

type StudentInitialMeetingMail = {
  studentName: string;
  mentorName: string;
  meetingName: string;
  date: string;
  courseName: string;
};
export const studentinitialMeetingMail = (data: StudentInitialMeetingMail) => {
  const date = data.date;
  const content =
    paragraph(`Dear ${data.mentorName},`) +
    paragraph(
      `New onboarding meeting scheduled for ${date} in  <b>${data.courseName}</b> with ${data.studentName}. `
    ) +
    paragraph('Kind regards,');

  return mailLayout(content);
};

export const generateMentorRequireFeeback = (email: string, students: Student[], hours: number) => {
  const html = feedbackNotification(students, hours);

  return {
    from: UPGRADE_EDUCATION_EMAIL,
    to: email,
    subject: `Feedback request`,
    html: html,
  };
};

export const generateMentorFeedbackNotProvided = async (user: User, students: any[]) => {
  const html = feedbackNotProvidedNotification(students);

  return {
    from: UPGRADE_EDUCATION_EMAIL,
    to: user?.email,
    subject: `Feedback request`,
    html: html,
  };
};

export const generateMentorLateFeedbackConfirm = async (user: User) => {
  const html = lateFeedbackConfirm(user);

  return {
    from: UPGRADE_EDUCATION_EMAIL,
    to: user?.email,
    subject: `Late feedback approved`,
    html: html,
  };
};
