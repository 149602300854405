import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { CloseModalProps } from '@shared/common';
import { useCreateHomework } from '@shared/react';
import { documentUrl } from '@shared/utils/string';
import useToast from 'apps/agora/src/hooks/useToast';
import React from 'react';
import './homework-modal.style.scss';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Modal from '@components/V4/Modal/Modal';

type Props = CloseModalProps & {
  studentId: string;
  enrollmentId: string;
  meetingId: string;
  postSuccess?: () => void;
  refetch?: () => void;
};

const formDataSchema = z.object({
  title: z.string().min(1, { message: 'Please enter a valid title.' }),
  description: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const CreateHomeworkModal: React.FC<Props> = ({
  visible,
  hide,
  studentId,
  enrollmentId,
  meetingId,
  postSuccess,
  refetch,
}) => {
  const [showToast] = useToast();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { mutate: createHomework, isLoading } = useCreateHomework({
    onSuccess: (homework) => {
      window.open(documentUrl(homework.fileId), '_blank');
      refetch && refetch();
      postSuccess?.();
      hide();
      reset();
    },
    onError(err: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: err?.message || 'Error while creating homework.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      createHomework({
        title: data.title,
        description: data.description,
        student: studentId,
        meeting: meetingId,
        enrollment: enrollmentId,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(onSubmit)(e);
  };

  return (
    <Modal isOpen={visible} onClose={hide}>
      <Modal.Header title="Create Homework" />
      <Modal.Body>
        <Input
          type={'text'}
          id={'title'}
          label={'Title'}
          autofocus
          isTouched={touchedFields.title}
          isValid={!errors.title}
          errorText={errors.title?.message}
          isRequired
          {...register('title')}
        />
        <Input
          type={'text'}
          id={'description'}
          label={'Description'}
          {...register('description')}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="flex gap-4 w-1/2 ml-auto mt-6">
          <Button variant="secondary" buttonText="Cancel" size="large" onClick={hide} />
          <Button
            variant="primary"
            buttonText="Create"
            size="large"
            isLoading={isLoading}
            onClick={submitHandler}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateHomeworkModal;
