import { CommonChecklistTable } from '@components';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { useEffect, useState } from 'react';

const TemplatesTable = () => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const response = await apiClient.get('/checklists/all-templates');

    setTemplates(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (templates?.length) {
    return <CommonChecklistTable isAdmin={true} data={templates} refetch={getData} />;
  }

  return <div>No templates found</div>;
};

export default TemplatesTable;
