// Input based on AgoraInput that accepts REFS from react-hook-form

import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { forwardRef } from 'react';
import InputError from './InputError';
import InputLabel from './InputLabel';

type Props = {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  isRequired?: boolean;
  className?: string;
  wrapperClassName?: string;
  isResizable?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  autofocus?: boolean;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      name,
      id,
      label,
      placeholder,
      isRequired,
      isTouched,
      wrapperClassName,
      isValid,
      errorText,
      className,
      rows,
      value,
      isResizable = false,
      onChange,
      onBlur,
      autofocus,
    },
    ref
  ) => (
    <div className={mergeClassNames('w-full', wrapperClassName)}>
      {label && (
        <InputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          htmlFor={id}
        />
      )}

      <textarea
        className={mergeClassNames(
          {
            'block w-full h-20 py-2 px-2.5 mt-2 bg-transparent text-customGrey border border-customGrey rounded-lg text-xs input-autofill hover:bg-inputHover focus:bg-inputHover focus:text-white focus:border-customGrey focus-visible:border-customGrey focus:ring-0 focus-visible:ring-0 placeholder:text-placeholderColor':
              true,
            'text-customPrimary border-customPrimary focus:!border-customPrimary focus:text-white input-autofill-valid':
              isValid && isTouched,
            'text-customRed border-customRed focus:!border-customRed focus:text-white input-autofill-error':
              !isValid && isTouched,
            'input-autofill-default': !isTouched,
            'resize-none': !isResizable,
          },
          className
        )}
        ref={ref}
        rows={rows ?? 4}
        name={name}
        id={id}
        autoFocus={autofocus}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />

      {!isValid && isTouched && errorText && <InputError errorText={errorText} />}
    </div>
  )
);

export default TextArea;
