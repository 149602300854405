import { ReactComponent as WorkIcon } from '@assets/icons/profile-icons/work-icon.svg';
import { ACTIVITY_TYPE, EMPLOYMENT_TYPE, Experience } from '@shared/common';
import { getHasValue } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import { useState } from 'react';
import ItemContent from '../ItemContent';
import ResumeTabWorkForm from './ResumeTabWorkForm';

interface ResumeTabWorkItemProps {
  userId: string;
  jobDetails: Experience;
  onDeleteEntry: (id?: string) => void;
}

const ResumeTabWorkItem = (props: ResumeTabWorkItemProps) => {
  const { userId, jobDetails, onDeleteEntry } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  const getEmploymentOrActivityType = () => {
    if (jobDetails.employmentType !== undefined) {
      return EMPLOYMENT_TYPE[jobDetails.employmentType];
    }

    if (jobDetails.activityType !== undefined) {
      return ACTIVITY_TYPE[jobDetails.activityType];
    }

    return undefined;
  };
  return isEditMode ? (
    <ResumeTabWorkForm
      jobData={jobDetails}
      isModeAddNew={false}
      onCancel={cancelEditHandler}
      userId={userId}
      setIsEditMode={setIsEditMode}
    />
  ) : (
    <ItemContent
      icon={<WorkIcon className="min-w-5" />}
      onEdit={() => setIsEditMode(true)}
      onDelete={() => onDeleteEntry(jobDetails._id)}
    >
      <ItemContent.Title>
        {jobDetails.positionTitle} <span className="text-customGrey">at</span>{' '}
        {jobDetails.employer ?? jobDetails.institution}
      </ItemContent.Title>

      <ItemContent.SubTitle>
        {getEmploymentOrActivityType()} <span>•</span>{' '}
        {moment(jobDetails.startDate).format('MMMM YYYY')} -{' '}
        {moment(jobDetails.endDate).format('MMMM YYYY')}
      </ItemContent.SubTitle>

      <ItemContent.Description>{jobDetails.description}</ItemContent.Description>
    </ItemContent>
  );
};

export default ResumeTabWorkItem;
