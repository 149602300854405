import ImageUploadCrop from '@components/V3/ImageUploadCrop';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import { message } from 'antd';
import { useState } from 'react';

const CoursePictureEditor = ({
  id,
  refetchData,
  handleImageUploaded,
  title,
}: {
  id: string;
  refetchData: () => void;
  handleImageUploaded: () => void;
  title: string;
}) => {
  const [coursePicture, setCoursePicture] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<any>();

  const handleImageSrc = (image: any) => {
    setImageSrc(image);
  };

  const handleCropComplete = (croppedImageUrl: string, croppedBlob: Blob) => {
    setCoursePicture(croppedImageUrl);

    const formData = new FormData();
    formData.append('image', croppedBlob, 'course-image.jpg');

    apiClient
      .post(`/v2/course/upload-image/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        refetchData();
      })
      .catch((error) => {
        message.error('Upload failed:', error);
      });
  };

  return (
    <div>
      <ImageUploadCrop
        aspect={1}
        refetchData={refetchData}
        onCropComplete={handleCropComplete}
        handleImageSrc={handleImageSrc}
        title={title}
      />
    </div>
  );
};

export default CoursePictureEditor;
