import { CalendarIcon, UploadIcon } from '@heroicons/react/outline';
import { CloseModalProps } from '@shared/common';
import {
  useChecklistTemplates,
  useChecklistTemplatesForEnrollment,
  useImportTemplate,
} from '@shared/react';
import styles from '@styles/components/checklist-modal.module.scss';
import modalStyles from '@styles/components/reusables/modal.module.scss';
import { message, Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = CloseModalProps & {
  checklistId: string;
  enrollmentId: string;
  refetch?: () => void;
};

const ImportChecklistModal = ({ visible, hide, checklistId, enrollmentId, refetch }: Props) => {
  const { data: templates } = useChecklistTemplatesForEnrollment(enrollmentId || '');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  useEffect(() => {
    if (templates && templates?.length > 0) {
      setSelectedTemplate(templates[0]._id || '');
    }
  }, [templates]);

  const template = templates?.find((template) => template._id === selectedTemplate);

  const { mutate: importTemplate } = useImportTemplate(checklistId || '', {
    onSuccess: () => {
      message.success('Template imported successfully');
      refetch && refetch();
      hide();
    },
  });

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      footer={false}
      width={700}
      className={modalStyles.modal}
    >
      <div className={styles.importNavbar}>
        {templates?.map((template) => (
          <div
            className={`${styles.importNavbarItem} ${
              selectedTemplate === template._id ? styles.importNavbarItemActive : ''
            }`}
            onClick={() => setSelectedTemplate(template?._id || '')}
            key={template._id}
          >
            {template.title}
          </div>
        ))}
      </div>

      <div className={styles.checklistItems}>
        {template?.items?.map((item) => (
          <div
            className={`${styles.checklistItem} ${item.isDone ? styles.checklistItemDone : ''}`}
            key={item._id}
          >
            <div className={styles.checklistItemDone}></div>

            <div className={styles.checklistItemLabel}>Milestone</div>
            <div>{item.title}</div>

            <div className={styles.checklistItemLabel}>Deadline</div>
            <div className={styles.checklistItemWithIcon}>
              {moment(item.deadline).format('YYYY.MM.DD')} <CalendarIcon className={styles.icon} />
            </div>

            <div className={styles.checklistItemAction}>
              Completed
              <input type="checkbox" checked={item.isDone} />
            </div>
          </div>
        ))}
      </div>

      <div className={`${modalStyles.modalFooterActions} ${modalStyles.modalFooterActionsPrimary}`}>
        <button
          className={`${modalStyles.modalBtn} ${modalStyles.modalBtnPrimary}`}
          onClick={() => {
            importTemplate({ templateId: selectedTemplate });
          }}
        >
          <UploadIcon className={modalStyles.icon} />
          Import
        </button>
      </div>
    </Modal>
  );
};

export default ImportChecklistModal;
