import { Participant } from '@modules/MeetingVideo/utils/types';
import { BasicProps } from 'apps/agora/src/utils/types';
import NetworkScore from './NetworkScore';
import ParticipantCard from './ParticipantCard';

interface NetworkTabProps extends BasicProps {
  participantsList?: Participant[];
  networkQuality?: Record<number, { uplink: number; downlink: number }>;
}

const NetworkTab = (props: NetworkTabProps) => {
  const { participantsList, networkQuality } = props;

  const getNetworkQuality = (userId: number) => {
    const uplinkScore = networkQuality?.[userId]?.uplink;

    const QUALITY_MAP = {
      0: 'Poor',
      1: 'Poor',
      2: 'Decent',
      3: 'Good',
      4: 'Great',
      5: 'Great',
    } as const;

    return QUALITY_MAP[uplinkScore as keyof typeof QUALITY_MAP] ?? 'Unknown';
  };

  return (
    <div className="flex flex-col gap-4">
      {participantsList?.map((user) => (
        <ParticipantCard key={user._id} name={user.fullName}>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <p className="text-xs w-14">Sending:</p>
              <NetworkScore score={networkQuality?.[user.zoomUserId]?.uplink} />
            </div>
            <div className="flex items-center gap-4">
              <p className="text-xs w-14">Receiving:</p>
              <NetworkScore score={networkQuality?.[user.zoomUserId]?.downlink} />
            </div>

            <div className="flex items-center gap-4">
              <p className="text-xs w-14">Quality:</p>
              <p className="text-xs">{getNetworkQuality(user.zoomUserId)}</p>
            </div>
          </div>
        </ParticipantCard>
      ))}
    </div>
  );
};

export default NetworkTab;
