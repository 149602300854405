import VideoMeeting from '@modules/MeetingVideo/components/VideoMeeting/VideoMeeting';
import VideoWaitingRoom from '@modules/MeetingVideo/components/VideoWaitingRoom/VideoWaitingRoom';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { useGetMeetingDetails, useGetVideoSettings } from '@shared/react';
import ZoomVideo, { LiveTranscriptionLanguage, Stream } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useMeetingWebSocket from './useMeetingWebsocket';

const zoomClient = ZoomVideo.createClient();

const MeetingVideo = () => {
  const [isWaitingRoom, setIsWaitingRoom] = useState(true);
  const { meetingId } = useParams<{ meetingId: string }>();
  const [activeCamera, setActiveCamera] = useState<string | undefined>();
  const [activeMicrophone, setActiveMicrophone] = useState<string | undefined>();
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [canJoinMeeting, setCanJoinMeeting] = useState(false);
  const [transcriptLanguage, setTranscriptLanguage] = useState<
    LiveTranscriptionLanguage | undefined
  >(undefined);
  const [stream, setStream] = useState<typeof Stream | undefined>();

  const history = useHistory();

  const [showToast] = useToast();

  const { data: meetingDetails } = useGetMeetingDetails(meetingId, {
    onError: (err: any) => {
      history.replace('/home');
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: err.response.data.error.message,
      });
    },
  });

  const { data: videoSettings } = useGetVideoSettings();

  const { webSocket, isSocketConnected, closeWebsocketConnection } = useMeetingWebSocket(meetingId);

  const contextValue = {
    stream,
    zoomClient,
    videoSettings: videoSettings || {},
    meetingDetails,
    activeCamera,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    isMeetingLoading,
    canJoinMeeting,
    webSocket,
    isSocketConnected,
    transcriptLanguage,
    setActiveCamera,
    setActiveMicrophone,
    setIsMicrophoneActive,
    setIsCameraActive,
    setIsMeetingLoading,
    setCanJoinMeeting,
    setStream,
    setTranscriptLanguage,
    closeWebsocketConnection,
  };

  return (
    <ZoomContext.Provider value={contextValue}>
      {isWaitingRoom ? <VideoWaitingRoom setIsWaitingRoom={setIsWaitingRoom} /> : <VideoMeeting />}
    </ZoomContext.Provider>
  );
};

export default MeetingVideo;
