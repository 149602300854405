import { CloseModalProps } from '@shared/types';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Modal } from 'antd';
import { COLORS } from '../../../v3/global';
import StudentDetails from './StudentDetails';
import './student-details.style.scss';

type Props = CloseModalProps & {
  studentId: string;
  enrollmentId: string;
  studentName: string;
};

const StudentDetailsModal = ({ visible, hide, studentId, enrollmentId, studentName }: Props) => {
  return (
    <Modal
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        borderRadius: '10px',
      }}
      visible={visible}
      onCancel={hide}
      width={'80%'}
      footer={null}
      className={` ${'agora-modal-content'}`}
    >
      <StudentDetails studentId={studentId} />
    </Modal>
  );
};

export default StudentDetailsModal;
