import DriveIcon from '@assets/icons/drive-icon-white.svg';
import { NotesModal } from '@components';
import { DocumentTextIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { useModal } from '@hooks';
import { ENVIRONMENT } from '@shared/frontendEnv';
import { useUserDetails } from '@shared/react';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Typography } from 'antd';
import { COLORS } from '../../../../v3/global';

const { Text } = Typography;

type Props = {
  studentId: string;
};

const AdditionalInfoCard = ({ studentId }: Props) => {
  const [NotesM, showNotes] = useModal(NotesModal);
  const { data: user } = useUserDetails(studentId);

  const openDrive = () => {
    const url = `https://drive.google.com/drive/folders/${
      user?.googleDrive[`${ENVIRONMENT}`].student.id
    }`;
    window.open(url, '_blank');
  };

  const openOldDrive = () => {
    const url = `https://drive.google.com/drive/folders/${user?.folderId}`;
    window.open(url, '_blank');
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={styles.studentModalInfoCard}
    >
      <div style={{ color: COLORS.TEXT_SECONDARY }} className={styles.studentModalInfoCardHead}>
        Quick Access To
      </div>

      <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.studentModalInfoCardItem}>
        <DocumentTextIcon className={styles.icon} />
        <Text
          style={{
            color: COLORS.PRIMARY,
            marginLeft: '2px',
            marginRight: '2px',
          }}
          className="main-green-link"
          onClick={() => {
            showNotes({
              userId: studentId,
            });
          }}
        >
          Mentor's Notes
        </Text>
      </div>

      <div
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.studentModalInfoCardItem}
        onClick={openDrive}
      >
        <ExternalLinkIcon
          style={{ marginLeft: '2px', marginRight: '2px' }}
          className={styles.icon}
        />
        <Text style={{ color: COLORS.PRIMARY }} className="main-green-link">
          Drive Folder
        </Text>
      </div>

      <div
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.studentModalInfoCardItem}
        onClick={openOldDrive}
      >
        <img src={DriveIcon} alt="drive-icon" width={24} />
        <Text style={{ color: COLORS.PRIMARY }} className="main-green-link">
          Old Drive Folder
        </Text>
      </div>

      {NotesM}
    </div>
  );
};

export default AdditionalInfoCard;
