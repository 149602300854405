import { storage } from '@shared/react/UniversalStorage';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

const usePossessUser = (defaultUserId?: string) => {
  const queryClient = useQueryClient();
  const [userId, setUserId] = useState(defaultUserId);

  useEffect(() => {
    if (!userId) return;

    const possess = async () => {
      storage.setItem('impersonate-user-id', userId);

      queryClient.invalidateQueries('usersApis/me');

      window.location.href = '/';
    };
    possess().catch((e) => console.log(e));
  }, [userId]);

  return { setPossessedUserId: setUserId };
};

export default usePossessUser;
