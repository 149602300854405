import { BASE_URL } from '@shared/frontendEnv';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getTokenSilently, globalLogout } from './auth';

const apiClient = axios.create({
  baseURL: `${BASE_URL}/api`,
});

let isRefreshing = false;
let failedQueue: {
  resolve: (token: string) => void;
  reject: (error: unknown) => void;
}[] = [];

const processQueue = (error: unknown, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    } else {
      prom.reject(new Error('No token and no error provided in processQueue'));
    }
  });
  failedQueue = [];
};

apiClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
      const token = await getTokenSilently();
      const impersonateUserId = localStorage.getItem('impersonate-user-id') ?? '';

      if (token) {
        config.headers.set('Authorization', `Bearer ${token}`);
        config.headers.set('x-access-token', token);
      }

      if (impersonateUserId) {
        config.headers.set('impersonate-user-id', impersonateUserId);
      }
    } catch (error) {
      console.error('Error getting token silently:', error);
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.set('Authorization', `Bearer ${token}`);
            return apiClient(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const token = await getTokenSilently();

        processQueue(null, token);

        originalRequest.headers.set('Authorization', `Bearer ${token}`);
        return apiClient(originalRequest);
      } catch (err) {
        processQueue(err, null);
        await globalLogout();
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
