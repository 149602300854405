import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useState } from 'react';
import SessionModalSection, { ActionButton } from '../SessionModalSection';
import SchoolSectionForm from './SchoolSectionForm';

interface SchoolSectionProps extends BasicProps {
  schools?: any[];
  userId: string;
}

const SchoolSection = (props: SchoolSectionProps) => {
  const { schools, userId } = props;
  const [isAddingSchool, setIsAddingSchool] = useState(false);
  const [schoolToEdit, setSchoolToEdit] = useState<any>();

  const [showToast] = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      showToast({
        variant: 'info',
        messageBody: 'School deleted successfully.',
      });
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageBody: 'School could not be deleted.',
      });
    },
  });

  const schoolButtons: ActionButton[] = [
    { buttonText: 'Add School', onClick: () => setIsAddingSchool(true) },
  ];

  const getDuration = (university: any) => {
    const startDate = moment(university.startDate).format('DD.MM.YYYY');
    const endDate = moment(university.endDate).format('DD.MM.YYYY');

    return `${startDate} - ${endDate}`;
  };

  const deleteSchoolClickHandler = (schoolId: string) => {
    updateUserProfile({
      educationalBackground: [
        {
          _id: schoolId,
          delete: true,
        },
      ],
    });
  };

  return isAddingSchool ? (
    <SchoolSectionForm
      userId={userId}
      className="mt-6"
      isModeAddNew
      onCloseEditForm={() => setIsAddingSchool(false)}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Add School" buttonsArray={schoolButtons}>
      {schools?.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell className="w-1/4">School</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Duration</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">GPA</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {schools.map((item) => (
              <Table.Row key={item._id}>
                {schoolToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <SchoolSectionForm
                      userId={userId}
                      className="rounded-none"
                      schoolData={item}
                      onCloseEditForm={() => setSchoolToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.educationalInstitution.name}</Table.Cell>
                    <Table.Cell>{getDuration(item)}</Table.Cell>
                    <Table.Cell>{item.gpa}</Table.Cell>
                    <Table.Cell>
                      <div className="flex gap-4">
                        <IconButton
                          size="small"
                          variant="ghost"
                          icon={<EditIcon />}
                          onClick={() => setSchoolToEdit(item)}
                        />

                        <IconButton
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<TrashIcon />}
                          onClick={() => deleteSchoolClickHandler(item._id)}
                        />
                      </div>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard text="No Existing Data" icon={<NoDataIcon className="text-customGrey" />} />
      )}
    </SessionModalSection>
  );
};

export default SchoolSection;
