interface NetworkScoreProps {
  score?: number;
}

const NetworkScore = (props: NetworkScoreProps) => {
  const { score = 0 } = props;

  const getColor = () => {
    if (score <= 1) {
      return 'bg-red-500';
    } else if (score <= 3) {
      return 'bg-yellow-500';
    } else {
      return 'bg-green-500';
    }
  };

  return (
    <div className="flex gap-1">
      {[...Array(5).keys()].map((_, index) => (
        <div
          key={index}
          className={`h-2.5 w-5 rounded-sm ${index < score ? getColor() : 'bg-gray-300'}`}
        />
      ))}
    </div>
  );
};

export default NetworkScore;
