import { ReactComponent as CheckedIcon } from '@assets/icons/V4/check-icon.svg';
import Tooltip from '@components/V4/Tooltip';
import { useAuthState, useGetUserProfile } from '@shared/react';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useHistory } from 'react-router-dom';
import StepBanner, { StepOption } from './StepBanner';

interface ProfileBannerProps {
  className?: string;
  hasSubBanner: boolean;
}

const ProfileBanner = (props: ProfileBannerProps) => {
  const { className, hasSubBanner } = props;
  const { userId } = useAuthState();
  const { data: userProfile } = useGetUserProfile(userId);

  const history = useHistory();

  const titles = [
    'Finishing Touches',
    'Almost Ready',
    'On the Right Path',
    'Making Progress',
    'Getting started',
    'Critical',
  ];

  const stepsPresets = [
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount - 1],
      getSubTitle: (remainingStepsCount: number) =>
        `${remainingStepsCount} steps left to complete your profile and stay on track with your academic journey.`,
      isFulfilled:
        !!userProfile?.email &&
        !!userProfile?.phoneNumber &&
        !!userProfile?.parentData?.email &&
        !!userProfile?.parentData?.fullName &&
        !!userProfile?.parentData?.phoneNumber &&
        !!userProfile?.city &&
        !!userProfile?.country &&
        !!userProfile?.timezone &&
        !!userProfile?.preferredLanguage,
      step: 'missingContact',
      stepTitle: 'Profile Info',
      href: '',
    },
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount],
      getSubTitle: (remainingStepsCount: number) =>
        `${remainingStepsCount} steps left to enhance your academic journey and move closer to your goals.`,
      isFulfilled: !!userProfile?.appliedUniversities?.length,
      step: 'missingUniversityGoal',
      stepTitle: 'University List',
      href: 'Goals',
    },
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount],
      getSubTitle: (remainingStepsCount: number) =>
        `${remainingStepsCount} steps remaining to get closer to making your Dream University a reality.`,
      isFulfilled: !!userProfile?.languageExams?.length,
      step: 'missingExams',
      stepTitle: 'Exam List',
      href: 'Exams',
    },
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount],
      getSubTitle: (remainingStepsCount: number) =>
        `${remainingStepsCount} steps remain to align your profile with your university aspirations.`,
      isFulfilled:
        !!userProfile?.educationalBackground?.length &&
        !!userProfile?.educationalBackground?.filter(
          (edu) => edu.educationalInstitution.type === 'School'
        ).length,
      step: 'missingSchool',
      stepTitle: 'School',
      href: 'Resume',
    },
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount],
      getSubTitle: (remainingStepsCount: number) =>
        `${remainingStepsCount} steps left to ensure your profile is ready for the next stage.`,
      isFulfilled:
        !!userProfile?.educationalBackground?.length &&
        !!userProfile?.educationalBackground?.filter(
          (edu) => edu.educationalInstitution.type === 'University'
        ).length,
      step: 'missingUniversity',
      stepTitle: 'University',
      href: 'Resume',
    },
    {
      getTitle: (remainingStepsCount: number) => titles[remainingStepsCount],
      getSubTitle: (remainingStepsCount: number) =>
        `${
          remainingStepsCount === 1 ? 'Just 1 step' : remainingStepsCount + ' steps'
        } left to complete a profile that supports your university ambitions.`,
      isFulfilled: !!userProfile?.experiences?.length,
      step: 'missingWork',
      stepTitle: 'Experience',
      href: 'Resume',
    },
  ];

  const getRemainingSteps = () => {
    return stepsPresets.filter((step) => step.isFulfilled !== true);
  };

  const remainingSteps = getRemainingSteps();
  const currentStep = remainingSteps[0];

  const progressPercentage = `${
    ((stepsPresets.length - remainingSteps.length) / stepsPresets.length) * 100
  }%`;

  if (!currentStep) return null;

  return (
    <div
      className={mergeClassNames('flex flex-col gap-4 p-6 rounded-xl bg-profileBanner', className)}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 font-raleway">
          <p className="font-semibold text-customGrey">Profile Status:</p>
          <p className="font-bold text-white">{currentStep.getTitle(remainingSteps.length)}</p>
        </div>
        <p className="font-semibold text-xs text-customGrey leading-5">
          {currentStep.getSubTitle(remainingSteps.length)}
        </p>
        <Tooltip
          overlayClassName="completion-banner-tooltip"
          text={
            <div className="flex flex-col gap-1 ">
              <p className="text-xs text-white leading-5">Steps completed:</p>
              {stepsPresets
                .sort((a) => (a.isFulfilled ? -1 : 1))
                .map((step) => (
                  <div className="flex items-center gap-2">
                    {step.isFulfilled && <CheckedIcon className="text-customPrimary" />}
                    <p className="text-xs text-white leading-5">{step.stepTitle}</p>
                  </div>
                ))}
            </div>
          }
        >
          <div className="w-full h-2 rounded-full bg-customGrey bg-opacity-50">
            <div
              style={{ width: progressPercentage }}
              className={mergeClassNames(`rounded-full h-full`, {
                'bg-customRed': remainingSteps.length === 5,
                'bg-profileProgressBar': remainingSteps.length < 5,
              })}
            ></div>
          </div>
        </Tooltip>
      </div>

      {hasSubBanner && (
        <StepBanner
          stepOption={currentStep.step as StepOption}
          onClick={() =>
            history.push(`${currentStep.href ? 'account?tab=' + currentStep.href : 'account/'}`)
          }
        />
      )}
    </div>
  );
};

export default ProfileBanner;
