import { CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import './agora-selector.styles.scss';

type Props = {
  label: string;
  onSelect: (value: string | undefined) => void;
  onSearch?: (value: string) => void;
  options: any[] | undefined;
  labelKey?: string;
  valueKey?: string;
  value?: string;
  showOptionsBy?: 'value' | 'index' | undefined;
  mode?: 'multiple' | 'tags' | undefined;
  onDeselect?: (value: string | undefined) => void;
  onClearAll?: () => void;
  margin?: string;
  indentOptions?: string[];
};

const AgoraSelector = ({
  label,
  onSelect,
  onSearch,
  options,
  labelKey = '',
  valueKey = '',
  showOptionsBy,
  mode = undefined,
  onDeselect,
  onClearAll,
  margin = '12px 24px 0px 0px',
  indentOptions,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: margin,
      }}
    >
      <span
        style={{
          color: COLORS.TEXT_PRIMARY,
          marginBottom: mode === 'multiple' ? '0px' : '8px',
        }}
        className="filter-label"
      >
        {label}
      </span>
      <div className="custom-search-selector">
        <Select
          className={mode === 'multiple' ? 'custom-multiple-select' : ''}
          onSelect={(value) => {
            onSelect(value);
          }}
          onDeselect={(value: string) => {
            if (onDeselect) {
              onDeselect(value);
            }
          }}
          mode={mode ? mode : undefined}
          showArrow={false}
          allowClear={true}
          onClear={() => {
            if (onClearAll) {
              onClearAll();
            }
          }}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          clearIcon={
            <div className="clear-icon">
              <CloseCircleFilled
                className="close-icon"
                style={{
                  color: COLORS.DARK_GREY_ALT,
                  backgroundColor: 'transparent',
                  background: 'none',
                  fontSize: '20px',
                  position: 'absolute',
                  top: '-4px',
                  left: '-4px',
                }}
              />
            </div>
          }
          showSearch
          style={{ width: 200, outline: 'none', boxShadow: 'none' }}
          placeholder={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                outline: 'none',
                boxShadow: 'none',
              }}
            >
              <span>Search to select</span>
              <span>
                <DownOutlined style={{ fontSize: '10px' }} />
              </span>
            </div>
          }
          options={options?.map((option, index) => ({
            value:
              showOptionsBy === 'value'
                ? option
                : showOptionsBy === 'index'
                ? index.toString()
                : option?.[valueKey],
            label:
              showOptionsBy === 'value'
                ? option
                : showOptionsBy === 'index'
                ? option
                : option?.[labelKey],
            className: indentOptions && indentOptions.includes(option) ? 'option-indent' : '',
          }))}
          onSearch={(value) => {
            if (onSearch) {
              onSearch(value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AgoraSelector;
