import { ReactComponent as CloseIcon } from '@assets/icons/new-close-icon.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/toast-icons/error.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/toast-icons/info.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/toast-icons/success.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/toast-icons/warning.svg';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { mergeClassNames } from '../../utils/helpers';
import Button from './Button';
import IconButton from './IconButton';

export interface ToastProps {
  className?: string;
  variant?: 'error' | 'warning' | 'info' | 'success';
  messageTitle?: string;
  messageBody: string;
  buttonText?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
}

const Toast = (props: ToastProps) => {
  const {
    className,
    variant = 'success',
    messageTitle,
    messageBody,
    buttonText,
    onButtonClick,
    onClose,
  } = props;

  const [shouldShowTransition, setShouldShowTransition] = useState(false);

  const iconClassName = 'min-w-4 min-h-4';

  const variantIcons = {
    error: <ErrorIcon className={iconClassName} />,
    info: <InfoIcon className={iconClassName} />,
    warning: <WarningIcon className={iconClassName} />,
    success: <SuccessIcon className={iconClassName} />,
  };

  useEffect(() => {
    setShouldShowTransition(true);
  }, []);

  return ReactDOM.createPortal(
    <div
      className={mergeClassNames(
        ' bg-surfaceHover rounded-lg shadow-default px-4 py-3 flex gap-4 w-full max-w-110 transition-all opacity-15 -translate-y-4',
        className,
        { '-translate-y-0 opacity-100': shouldShowTransition }
      )}
    >
      {/* Icon */}
      {variantIcons[variant]}

      <div className="flex flex-col items-center gap-4 tablet:flex-row">
        <div className="flex flex-col gap-1">
          {messageTitle && (
            <h3 className="text-xsm leading-4 font-raleway font-bold ">{messageTitle}</h3>
          )}
          <p className="text-xs leading-4 ">{messageBody}</p>
        </div>
        {buttonText && (
          <Button
            variant="secondary"
            buttonText={buttonText}
            onClick={onButtonClick}
            className=""
          />
        )}
      </div>
      <IconButton
        size="small"
        variant="ghost"
        className="ml-auto"
        icon={<CloseIcon className="w-3 h-3" />}
        onClick={onClose}
      />
    </div>,
    document.getElementById('toast-root')!
  );
};

export default Toast;
