import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { Experience } from '@shared/common';
import { useState } from 'react';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import ResumeTabWorkForm from './ResumeTabWorkForm';
import ResumeTabWorkItem from './ResumeTabWorkItem';

interface ResumeWorkSectionProps {
  userId: string;
  experiences: Experience[];
  isMentorViewer: boolean;
  isMentorProfile: boolean;
}

const ResumeWorkSection = (props: ResumeWorkSectionProps) => {
  const { userId, experiences, isMentorViewer, isMentorProfile } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'experiences',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Experience"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <ResumeTabWorkForm
          userId={userId}
          isModeAddNew
          setIsAddNewItem={setIsAddNewItem}
          onCancel={addNewItemCancelHandler}
        />
      )}
      {experiences?.map((exp) => (
        <ResumeTabWorkItem
          key={exp._id}
          userId={userId}
          jobDetails={exp}
          onDeleteEntry={deleteEntryHandler}
        />
      ))}

      {!isAddNewItem && !experiences?.length && (!isMentorViewer || isMentorProfile) && (
        <StepBanner stepOption={'missingWork'} onClick={() => setIsAddNewItem((prev) => !prev)} />
      )}
    </ProfileMainSection>
  );
};

export default ResumeWorkSection;
