import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  SystemNotification,
  SystemNotificationData,
} from '../types';

export const listMyNewNotifications: ApiDescription = {
  endpoint: '/notifications/new/my',
  method: RequestsMethod.Get,
};
export type ListMyNewNotifications = ApiData<unknown, SystemNotification[]>;

export const readNotification: ApiDescription = {
  endpoint: '/notifications/read',
  method: RequestsMethod.Post,
};
export type ReadNotification = ApiData<{ notificationId: string }, SystemNotification>;

export const sendNotificationToAll: ApiDescription = {
  endpoint: '/notifications/send-to-all',
  method: RequestsMethod.Post,
};
export type SendNotificationToAll = ApiData<SystemNotificationData, unknown>;
