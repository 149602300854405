import { Enrollment } from '@shared/common';
import { useContext, useMemo } from 'react';
import { MentorDetailsPageContext } from '../../MentorDetailsPage';
import EnrolledInTable from './enrolledIn/EnrolledInTable';
import TeachingEnrollmentsTable from './teaching/TeachingEnrollmentsTable';

interface EnrollmentsTabProps {
  areEnrollmentsActive?: boolean;
}

const EnrollmentsTab = (props: EnrollmentsTabProps) => {
  const { areEnrollmentsActive = false } = props;

  const { mentor } = useContext(MentorDetailsPageContext);

  const { teaching, enrolledIn } = useMemo(() => {
    const teaching: Partial<Enrollment>[] = [];
    const enrolledIn: Partial<Enrollment>[] = [];

    mentor?.enrollments?.forEach((enrollment) => {
      if (enrollment.user?._id !== mentor._id) {
        teaching.push(enrollment);
      } else {
        enrolledIn.push(enrollment);
      }
    });

    return { teaching, enrolledIn };
  }, [mentor?.enrollments]);

  return (
    <>
      <TeachingEnrollmentsTable
        areEnrollmentsActive={areEnrollmentsActive}
        enrollments={teaching}
      />
      <EnrolledInTable areEnrollmentsActive={areEnrollmentsActive} enrollments={enrolledIn} />
    </>
  );
};

export default EnrollmentsTab;
