import { MENTOR_ROLE } from '@shared/constants';
import { useStudentDetails } from '@shared/react';
import styles from '@styles/components/reusables/modal.module.scss';
import React from 'react';
import BasicInfo from './BasicInfo';
import Enrollments from './Enrollments';

type Props = {
  userId: string;
};

const EnrollmentDetails: React.FC<Props> = ({ userId }) => {
  const { data: userData } = useStudentDetails(userId);

  const isMentor = userData?.roles.includes(MENTOR_ROLE);

  return (
    <div>
      <div className={styles.modalSecondaryHeader}>
        Enrollments for {userData?.fullName} {isMentor ? '(mentor)' : '(student)'}
      </div>

      <div className={styles.modalSecondaryBody}>
        <div style={{ marginBottom: '20px' }}>
          <BasicInfo userId={userId} />
        </div>

        <div style={{ marginBottom: '20px' }}>
          <Enrollments userId={userId} />
        </div>
      </div>
    </div>
  );
};
export default EnrollmentDetails;
