import { DownOutlined, UpOutlined, LoadingOutlined } from '@ant-design/icons';
import { useStudentDetails } from '@shared/react';
import styles from '@styles/components/reusables/modal.module.scss';
import React, { useState } from 'react';

type Props = {
  userId: string;
};

const BasicInfo: React.FC<Props> = ({ userId }) => {
  const [showMore, setShowMore] = useState(false);

  const { data: user, isLoading } = useStudentDetails(userId);

  if (isLoading) {
    return <LoadingOutlined />;
  }

  if (!user) {
    return null;
  }

  const { graduationYear, countries, interests, studySubjects, parentData, phoneNumber, email } =
    user;

  return (
    <div>
      <div
        onClick={() => setShowMore(!showMore)}
        className={styles.modalSecondarySectionTitle}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        Basic Info
        <span className={styles.modalLink}>{showMore ? <DownOutlined /> : <UpOutlined />}</span>
      </div>

      <div className={styles.modalInfoGrid}>
        <span>Grad Year:</span>
        <span>{graduationYear}</span>

        {showMore && (
          <>
            <span>Countries applying to:</span>
            <span>{countries.join(', ')}</span>
            <span>Interests:</span>
            <span>{interests.join(', ')}</span>
            <span>Study Subjects:</span>
            <span>{studySubjects.join(', ')}</span>
            <span>Phone:</span>
            <span>{phoneNumber}</span>
            <span>Email:</span>
            <span>{email}</span>
            <span>Parent Name:</span>
            <span>{parentData?.fullName ?? '-'}</span>
            <span>Parent Phone:</span>
            <span>{parentData?.phoneNumber ?? '-'}</span>
            <span>Parent E-mail:</span>
            <span>{parentData?.email ?? '-'}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
