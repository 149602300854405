import { ApiData, ApiDescription, RequestsMethod, Course, ModuleStatus, FileType } from '../common';

export const getUserCourses: ApiDescription = {
  endpoint: '/v3/courses',
  method: RequestsMethod.Get,
};
export type GetUserCourses = ApiData<never, Course[]>;

export const softDeleteCourse: ApiDescription = {
  endpoint: '/v3/courses/:id/soft-delete',
  method: RequestsMethod.Delete,
};

export const resetDeletedStatusForCourses: ApiDescription = {
  endpoint: '/v3/course/reset-deleted-status-for-courses',
  method: RequestsMethod.Patch,
};
export type ResetDeletedStatusForCourses = ApiData<unknown, any>;

export type SoftDeleteCourse = ApiData<unknown, any>;

export const getCourse: ApiDescription = {
  endpoint: '/courses/:id',
  method: RequestsMethod.Get,
};
export type GetCourse = ApiData<unknown, Course>;

export const getCourseProgress: ApiDescription = {
  endpoint: '/courses/:id/progress',
  method: RequestsMethod.Get,
};
export type GetCourseProgress = ApiData<
  { courseId: string; studentId: string },
  {
    course: Course;
    completedLessonIds: string[];
    completedQuizzIds: string[];
  }
>;

export const getAllCourses: ApiDescription = {
  endpoint: '/v2/course/listAll',
  method: RequestsMethod.Get,
};
export type GetAllCourses = ApiData<unknown, Course[]>;

export const getAllCoursesLite: ApiDescription = {
  endpoint: '/v2/course/listAllTitles',
  method: RequestsMethod.Get,
};
export type GetAllCoursesLite = ApiData<unknown, Course[]>;

export const getCoursesByMentor: ApiDescription = {
  endpoint: '/v2/course/listByMentor',
  method: RequestsMethod.Get,
};
export type GetCoursesByMentor = ApiData<unknown, Course[]>;

export const getStudentEnrollmentStatus: ApiDescription = {
  endpoint: '/v2/course/student-enrollment-status',
  method: RequestsMethod.Get,
};

export type GetStudentEnrollmentStatus = ApiData<
  { courseId: string; studentId: string },
  { completedPerModule: ModuleStatus[] }
>;

export const getNextChapterFromModule: ApiDescription = {
  endpoint: '/v2/course/get-next-chapter',
  method: RequestsMethod.Get,
};

export const uploadCourseImage: ApiDescription = {
  endpoint: '/v2/course/upload-image/:id',
  method: RequestsMethod.Post,
};

export type UploadCourseImage = ApiData<unknown, any>;

export const getPage = {
  endpoint: '/page/:id',
  method: RequestsMethod.Get,
};

export type GetPage = ApiData<unknown, unknown>;

export const addPage = {
  endpoint: '/page/new',
  method: RequestsMethod.Post,
};

export type AddPage = ApiData<unknown, unknown>;

export const deletePage = {
  endpoint: '/page/:id/modify',
  method: RequestsMethod.Delete,
};

export type DeletePage = ApiData<unknown, unknown>;

export const modifyPage = {
  endpoint: '/page/:id/modify',
  method: RequestsMethod.Put,
};

export type ModifyPage = ApiData<unknown, unknown>;

export const answerQuiz = {
  endpoint: '/quiz/:id/answer',
  method: RequestsMethod.Post,
};

export type AnswerQuiz = ApiData<unknown, unknown>;

export const addCourse = {
  endpoint: '/v2/course/new',
  method: RequestsMethod.Post,
};

export type AddCourse = ApiData<unknown, unknown>;

export const editCourse = {
  endpoint: '/course/edit',
  method: RequestsMethod.Post,
};

export type EditCourse = ApiData<unknown, unknown>;

export const getDetails = {
  endpoint: '/course/get-details/:id',
  method: RequestsMethod.Get,
};

export type GetDetails = ApiData<unknown, unknown>;

export const enrollInCourse = {
  endpoint: '/course/enroll/:id',
  method: RequestsMethod.Post,
};

export type EnrollInCourse = ApiData<unknown, unknown>;

export const getCoursesEnrolledIn = {
  endpoint: '/course/getEnrolledIn',
  method: RequestsMethod.Get,
};

export type GetCoursesEnrolledIn = ApiData<unknown, unknown>;

export const getEnrollmentStatusForCourse = {
  endpoint: '/course/enrollmentStatus/:courseId',
  method: RequestsMethod.Get,
};

export type GetEnrollmentStatusForCourse = ApiData<unknown, unknown>;

export const listAvailableCourses = {
  endpoint: '/course/available',
  method: RequestsMethod.Get,
};

export type ListAvailableCourses = ApiData<unknown, unknown>;

export const newModule = {
  endpoint: '/v2/course/new-module/:id',
  method: RequestsMethod.Post,
};

export type NewModule = ApiData<unknown, unknown>;

export const listAll = {
  endpoint: '/v2/course/list-all',
  method: RequestsMethod.Get,
};

export type ListAll = ApiData<unknown, unknown>;

export const editModuleResources = {
  endpoint: '/v2/course/:id/:moduleIndex/edit-resources',
  method: RequestsMethod.Put,
};

export type EditModuleResources = ApiData<unknown, unknown>;

export const getCourseModule = {
  endpoint: '/v2/course/:id/:moduleId',
  method: RequestsMethod.Get,
};

export type GetCourseModule = ApiData<unknown, unknown>;
