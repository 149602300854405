import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { authApis, usersApis } from '../../../apis';
import apiClient from './apiClient';

export const useMe = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  return useQuery<usersApis.Me['response']>(
    usersApis.me.endpoint,
    async () => {
      try {
        await getAccessTokenSilently();
        const { data } = await apiClient.get(usersApis.me.endpoint);
        return data;
      } catch {
        return;
      }
    },
    {
      retry: 0,
      enabled: !isLoading && isAuthenticated,
    }
  );
};

export const useLogin = () => {
  const queryClient = useQueryClient();

  const loginAction = async (params: authApis.Login['payload']) => {
    const { data } = await apiClient.post(authApis.login.endpoint, params);

    return data as authApis.Login['response'];
  };

  return useMutation(loginAction, {
    onSettled: async (data) => {
      queryClient.invalidateQueries('usersApis/me');
    },
  });
};
