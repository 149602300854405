import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/SessionModal/SessionModalFormWrapper';
import { useUpdateMyMentorData } from '@shared/react';
import { GetAllCoursesCanTeachDTO } from '@shared/types/DTOs/MentorsDTO';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { MentorDetailsPageContext } from '../../../MentorDetailsPage';

interface CoursesFormProps extends BasicProps {
  coursesCanTeach?: GetAllCoursesCanTeachDTO[];
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  coursesCanTeach: z.string().min(1, { message: 'Please select a valid course.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const CoursesForm = (props: CoursesFormProps) => {
  const { coursesCanTeach, className, onCloseEditForm } = props;

  const [showToast] = useToast();

  const query = useQueryClient();

  const { mutate: updateMyMentorData, isLoading } = useUpdateMyMentorData({
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      onCloseEditForm();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const { mentor, courses } = useContext(MentorDetailsPageContext);

  const courseOptions = courses?.filter((course) => {
    return !coursesCanTeach?.map((course) => course._id)?.find((m: string) => m === course._id);
  });

  const { handleSubmit, trigger, setValue, getValues, control } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: subjectsCanTeachField, fieldState: subjectsCanTeachState } = useController({
    name: 'coursesCanTeach',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && coursesCanTeach) {
      updateMyMentorData({
        coursesCanTeach: [...coursesCanTeach, { _id: data.coursesCanTeach }],
        userId: mentor?._id,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <SessionModalFormWrapper
      title="Course"
      className={className}
      onCancel={onCloseEditForm}
      isLoading={!!isLoading}
      submitHandler={submitHandler}
    >
      <Select
        options={courseOptions?.map((course) => ({
          label: course.title,
          value: course._id,
        }))}
        onSelect={subjectsCanTeachField.onChange}
        onBlur={subjectsCanTeachField.onBlur}
        value={subjectsCanTeachField.value}
        size="large"
        id={'courses'}
        label={'Courses'}
        isRequired
        allowClear={false}
        isTouched={subjectsCanTeachState.isTouched}
        isValid={!subjectsCanTeachState.error}
        errorText={subjectsCanTeachState.error?.message}
      />
    </SessionModalFormWrapper>
  );
};

export default CoursesForm;
