import { createContext } from 'react';
import { useEnvironmentState } from './EnvironmentContext';

type State = {
  baseUrl: string;
  apiUrl: string;
};
export const UrlStateContext = createContext<State | undefined>(undefined);

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const UrlProvider = ({ children }: Props) => {
  const { environment } = useEnvironmentState();

  const state: State = {
    baseUrl: '',
    apiUrl: '',
  };

  switch (environment) {
    case 'development':
      state.baseUrl = 'https://local.agora.upgrade.education:8443';
      state.apiUrl = `${state.baseUrl}/api`;
      break;
    case 'CI':
      state.baseUrl = 'https://ci.agora.upgrade.education';
      state.apiUrl = `${state.baseUrl}/api`;
      break;
    case 'staging':
      state.baseUrl = 'https://staging.agora.upgrade.education';
      state.apiUrl = `${state.baseUrl}/api`;
      break;
    case 'production':
      state.baseUrl = 'https://agora.upgrade.education';
      state.apiUrl = `${state.baseUrl}/api`;
      break;
    default:
      state.baseUrl = 'https://notfound.agora.upgrade.education';
      state.apiUrl = `${state.baseUrl}/api`;
      break;
  }

  return <UrlStateContext.Provider value={state}>{children}</UrlStateContext.Provider>;
};

export { UrlProvider };
