import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { createContext, ReactNode, useState } from 'react';
import Modal, { ModalProps } from '../Modal';
import Navigation from './Navigation';

export interface NavigationProps {
  isBackButtonActive: boolean;
  goToPage: (page: string) => void;
  goBack: () => void;
}

interface MultiPageModalProps extends ModalProps {
  initialPage: string;
  title?: string;
  bodyWrapperClassName?: string;
  navigation?: Record<string, { label: string; isCompleted: boolean }>;
  pages: Record<string, (navigation: NavigationProps) => ReactNode>;
  onClose: () => void;
}

interface PaginationContext {
  isBackHistoryButtonActive: boolean;
  isGoToNextPageDisabled: boolean;
  goBackHistory: () => void;
  goToPage: (page: string) => void;
  goBack: () => void;
  goToNextPage: () => void;
}

export const PaginationContext = createContext({
  isBackHistoryButtonActive: false,
  isGoToNextPageDisabled: false,
  goBackHistory: () => console.warn('goBackHistory not implemented.'),
  goToPage: (page: string) => console.warn('goToPage not implemented.'),
  goBack: () => console.warn('goBack not implemented.'),
  goToNextPage: () => console.warn('goToNextPage not implemented.'),
});

const MultiPageModal = (props: MultiPageModalProps) => {
  const { initialPage, pages, navigation, title, isOpen, bodyWrapperClassName, onClose, ...rest } =
    props;

  const [currentPage, setCurrentPage] = useState<string>(initialPage);
  const [history, setHistory] = useState<string[]>([]);

  if (!pages[initialPage]) {
    console.error(`Initial page "${initialPage}" does not exist in pages.`);
    return null;
  }

  const pageKeys = Object.keys(pages);

  const goToPage = (page: string) => {
    const currentPageIndex = pageKeys.indexOf(currentPage);
    const pageIndex = pageKeys.indexOf(page);

    if (currentPageIndex === -1 || pageIndex === -1) {
      console.error('Invalid page navigation');
      return;
    }

    const isPageAfterCurrent = pageIndex > currentPageIndex;

    if (navigation && !navigation[currentPage]?.isCompleted && isPageAfterCurrent) {
      return;
    }

    setHistory((prevHistory) => [...prevHistory, currentPage]);
    setCurrentPage(page);
  };

  const goBack = () => {
    const indexOfCurrentPage = pageKeys.indexOf(currentPage);

    if (indexOfCurrentPage > 0) {
      setHistory((prevHistory) => [...prevHistory, currentPage]);
      setCurrentPage(pageKeys[indexOfCurrentPage - 1]);
    }
  };

  const isGoToNextPageDisabled = (navigation && !navigation[currentPage]?.isCompleted) ?? false;

  const goToNextPage = () => {
    if (isGoToNextPageDisabled) {
      return;
    }

    const indexOfCurrentPage = pageKeys.indexOf(currentPage);

    if (indexOfCurrentPage < pageKeys.length - 1) {
      setHistory((prevHistory) => [...prevHistory, currentPage]);
      setCurrentPage(pageKeys[indexOfCurrentPage + 1]);
    }
  };

  const goBackHistory = () => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      const previousPage = newHistory.pop();

      if (previousPage) {
        setCurrentPage(previousPage);
      }

      return newHistory;
    });
  };

  const closeModalHandler = () => {
    setCurrentPage(initialPage);
    setHistory([]);
    onClose();
  };

  return (
    <Modal {...rest} isOpen={isOpen} onClose={closeModalHandler}>
      {title && <Modal.Header title={title}></Modal.Header>}
      <div
        className={mergeClassNames(
          { 'flex flex-row gap-x-14': !!navigation },
          bodyWrapperClassName
        )}
      >
        <PaginationContext.Provider
          value={{
            isBackHistoryButtonActive: !!history.length,
            isGoToNextPageDisabled,
            goBackHistory,
            goToPage,
            goBack,
            goToNextPage,
          }}
        >
          {!!navigation && (
            <Navigation
              currentPage={currentPage}
              navigation={navigation}
              onCurrentPageChange={goToPage}
            />
          )}
          {pages[currentPage]?.({
            isBackButtonActive: !!history.length,
            goToPage,
            goBack: goBackHistory,
          })}
        </PaginationContext.Provider>
      </div>
    </Modal>
  );
};

export default MultiPageModal;
