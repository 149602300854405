import Accordion from '@components/V4/Accordion';
import SessionModalEmptyState from '../../../SessionModalEmptyState';

interface SessionNotesTabProps {
  studentsNotes?: {
    _id: string;
    fullName: string;
    notes: string;
  }[];
  mentorNotes?: {
    _id: string;
    fullName: string;
    notes: string;
  };
}

const SessionNotesTab = (props: SessionNotesTabProps) => {
  const { mentorNotes, studentsNotes } = props;
  return (
    <div>
      <div className="flex flex-col gap-1">
        {!mentorNotes?.notes && !studentsNotes?.[0]?.notes ? (
          <SessionModalEmptyState />
        ) : (
          <>
            <Accordion
              defaultIsOpen
              title={<p className="font-raleway font-medium font-xs text-white">Mentor Notes</p>}
              titleWrapperClassName="bg-surfaceHover p-2"
              className="m-2 max-h-[200px] laptop:max-h-[540px]"
            >
              {mentorNotes?.notes ? (
                <blockquote className="text-white text-xs leading-5">
                  {mentorNotes?.notes}
                </blockquote>
              ) : (
                <SessionModalEmptyState className="py-4" />
              )}
            </Accordion>

            <Accordion
              titleWrapperClassName="bg-surfaceHover p-2"
              title={<p className="font-raleway font-medium font-xs text-white">Student Notes</p>}
              className="m-2 max-h-[80px] laptop:max-h-[140px]"
            >
              {studentsNotes?.[0]?.notes ? (
                <blockquote className="text-white text-xs leading-5">
                  {studentsNotes[0]?.notes}
                </blockquote>
              ) : (
                <SessionModalEmptyState className="py-4" />
              )}
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
};

export default SessionNotesTab;
