import Table from '@components/V4/Table/Table';
import SessionModalSection, {
  ActionButton,
} from '@modules/Admin/components/SessionModal/SessionModalSection';
import { getSymbolFromCurrency, getValueOrNA } from 'apps/agora/src/utils/helpers';
import { useContext, useState } from 'react';
import { MentorDetailsPageContext } from '../../MentorDetailsPage';
import PaymentsForm from './PaymentsForm';

const PaymentsTab = () => {
  const { mentor } = useContext(MentorDetailsPageContext);

  const [isEditingMajor, setIsEditingMajor] = useState(false);

  const buttons: ActionButton[] = [{ buttonText: 'Edit', onClick: () => setIsEditingMajor(true) }];

  const mentorData = mentor?.mentorData;

  const paymentsInfo = [
    {
      label: 'Pay Rate',
      value: mentorData?.rate?.amount
        ? `${mentorData?.rate?.amount}${
            getSymbolFromCurrency(mentorData?.rate?.currency ?? '') ?? '$'
          }/hour`
        : undefined,
    },
    {
      label: 'Pay Rate Currency',
      value: getValueOrNA(mentorData?.rate?.currency),
    },
    {
      label: 'Billing Currency',
      value: getValueOrNA(mentorData?.rate?.billingCurrency),
    },
    {
      label: 'VAT Payer',
      value: mentorData?.rate?.vatPayer ? 'Yes' : 'No',
    },
    {
      label: 'Company / Freelancer Name',
      value: getValueOrNA(mentorData?.company?.name),
    },
    {
      label: 'Company / Freelancer Address',
      value: getValueOrNA(mentorData?.company?.address),
    },
    {
      label: 'Fiscal registration number (or equivalent)',
      value: getValueOrNA(mentorData?.company?.CUI),
    },
    {
      label: 'IBAN',
      value: getValueOrNA(mentorData?.company?.IBAN),
    },
  ];

  return isEditingMajor ? (
    <PaymentsForm
      className="mt-6"
      mentorData={mentorData}
      onCloseEditForm={() => setIsEditingMajor(false)}
      userId={mentor?._id || ''}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Payment Details" buttonsArray={buttons}>
      <Table size="small">
        <Table.Body>
          {paymentsInfo.map((info) => (
            <Table.Row key={info.label}>
              <Table.Cell>{info.label}</Table.Cell>
              <Table.Cell>{info.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </SessionModalSection>
  );
};

export default PaymentsTab;
