import { DislikeOutlined, EditOutlined, LeftOutlined, LikeOutlined } from '@ant-design/icons';
import { EdjData, KnowledgeBaseArticle as KB } from '@shared/types';
import { Button, Space, Tooltip, Tag, Divider, Skeleton, Typography, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../v3/global';
import ArticleCRUD from '../../Admin/KnowledgeBaseEditor/article-crud';
import EdjDisplay from '../../Base/LessonDisplay/EdjDisplay';

const { Text } = Typography;

interface ArticleProps {
  id: string;
  changeId(id: string): any;
  showAdminButtons?: boolean;
}

const KnowledgeBaseArticle = (props: ArticleProps) => {
  const { id, changeId, showAdminButtons } = props;
  const [article, setArticle] = useState<KB>();
  const [newRating, setNewRating] = useState('');
  const getArticle = async () => {
    const newArticle = await ArticleCRUD.read(id);
    setArticle(newArticle.data);
  };

  const rateArticle = async (clickedRating: string) => {
    if (newRating) return;
    setNewRating(clickedRating);
    switch (clickedRating) {
      case 'like':
        await ArticleCRUD.like(id);
        break;
      case 'dislike':
        await ArticleCRUD.dislike(id);
        break;
      default:
        break;
    }
  };

  const copyIdToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = id;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('Copied to clipboard');
  };

  const editArticle = () => {
    window.location.href = `/admin/knowledge-base/${id}`;
  };

  useEffect(() => {
    getArticle();
  }, []);

  if (!article?.title || !article?.keywords || !article?.body) return <Skeleton active />;

  const { title, body, keywords, lastUpdate, likes, dislikes } = article;

  return (
    <div>
      <Space align="baseline">
        <Tooltip title="Back to search">
          <Button onClick={() => changeId('')} type="default" icon={<LeftOutlined />}>
            {' '}
          </Button>
        </Tooltip>
        <h3 style={{ color: COLORS.TEXT_PRIMARY }}>{title}</h3>
      </Space>
      <EdjDisplay data={body} preview />
      <Divider />
      <p style={{ color: COLORS.TEXT_PRIMARY }}>
        <b>Keywords: </b>
        {keywords.map((tag) => (
          <Tag>{tag}</Tag>
        ))}
      </p>
      <p style={{ color: COLORS.TEXT_PRIMARY }}>
        <b>Last update: </b> {moment(lastUpdate).calendar()}
      </p>
      <p style={{ color: COLORS.TEXT_PRIMARY }}>
        <Space>
          <b>Was this useful? </b>

          <Tooltip title="Yes, this helped">
            <LikeOutlined
              style={newRating === 'like' ? { color: 'green' } : {}}
              onClick={() => rateArticle('like')}
            />
          </Tooltip>
          <Tooltip title="Needs improvement">
            <DislikeOutlined
              style={newRating === 'dislike' ? { color: 'red' } : {}}
              onClick={() => rateArticle('dislike')}
            />
          </Tooltip>
        </Space>
      </p>
      {showAdminButtons && (
        <>
          <Divider />
          <p style={{ color: COLORS.TEXT_PRIMARY }}>
            <b>Admin tools:</b>
          </p>
          <Space>
            <Link to={`/admin/knowledge-base/${id}`}>
              <Button icon={<EditOutlined />}>Edit</Button>
            </Link>
            <span style={{ color: COLORS.TEXT_PRIMARY }}>
              Rating: {likes} likes, {dislikes} dislikes
            </span>
          </Space>
          <br />
          <Text style={{ color: COLORS.TEXT_PRIMARY }} type="secondary">
            Article id:{' '}
            <b style={{ cursor: 'pointer' }} onClick={copyIdToClipboard}>
              {id}
            </b>
          </Text>
        </>
      )}
    </div>
  );
};

const DEFAULT_BODY: EdjData = {
  blocks: [],
  time: 0,
  version: '0.0.1',
};

export default KnowledgeBaseArticle;
