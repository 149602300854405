import { ReactComponent as BackSignIcon } from '@assets/icons/back-sign.svg';
import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { useGetSession } from '@shared/react';
import SessionDetailsSection from './SessionDetailsSection/SessionDetailsSection';
import SessionTabsSection, { StudentTab } from './SessionTabsSection/SessionTabsSection';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';

interface SessionDetailsPageProps {
  sessionId: string;
  isBackButtonActive?: boolean;
  onGoBack?: () => void;
  tab?: StudentTab;
}

const SessionDetailsPage = (props: SessionDetailsPageProps) => {
  const { sessionId, isBackButtonActive = false, onGoBack, tab } = props;

  const { data: session, isLoading } = useGetSession(sessionId);

  return isLoading || !session ? (
    <AgoraSpinner />
  ) : (
    <>
      <Modal.Header className="relative font-raleway text-xl font-bold text-center mb-6">
        {isBackButtonActive && (
          <Button
            icon={<BackSignIcon />}
            className="absolute top-0 left-0"
            variant="secondary"
            onClick={onGoBack}
            buttonText="Back"
          />
        )}

        <h3>Session Details - {session?.name}</h3>
        <h3>{`${session?.mentor ?? 'n/a'} / ${session?.student ?? 'n/a'}`}</h3>
      </Modal.Header>
      <Modal.Body>
        <SessionDetailsSection session={session} />
        <SessionTabsSection session={session} tab={tab} />
      </Modal.Body>
    </>
  );
};

export default SessionDetailsPage;
