import CoursesTable from './courses/CoursesTable';
import SubjectsTable from './subjects/SubjectsTable';

const CoursesAndSubjectsTab = () => {
  return (
    <>
      <CoursesTable />
      <SubjectsTable />
    </>
  );
};

export default CoursesAndSubjectsTab;
