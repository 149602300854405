import { COLORS } from 'apps/agora/src/v3/global';
import classNames from 'classnames';
import AgoraInputError from '../AgoraInputError';
import AgoraInputLabel from '../AgoraInputLabel';
import styles from './agora-new-input.module.scss';

type Props = {
  htmlFor: string;
  type: string;
  name: string;
  id: string;
  placeholder: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  isRequired?: boolean;
  className?: string;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
};

const AgoraInput = ({
  htmlFor,
  type,
  name,
  id,
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  rows,
  isRequired,
  className,
  isValid,
  isTouched,
  errorText,
}: Props) => {
  return (
    <div
      className={classNames(
        styles['agora-input-wrapper'],
        className,
        isValid && isTouched
          ? styles['agora-input-wrapper--valid']
          : !isValid && isTouched
          ? styles['agora-input-wrapper--error']
          : ''
      )}
    >
      {label && (
        <AgoraInputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          htmlFor={htmlFor}
        />
      )}

      <div className={styles[`agora-input-inner-wrapper`]}>
        {type === 'textarea' ? (
          <textarea
            rows={rows ?? 4}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        ) : (
          <input
            value={value}
            onChange={onChange}
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            onBlur={onBlur}
          />
        )}
      </div>

      {!isValid && isTouched && errorText && <AgoraInputError errorText={errorText} />}
    </div>
  );
};

export default AgoraInput;
