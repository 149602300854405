import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import styles from '@styles/components/sider.module.scss';
import { Layout } from 'antd';
import LogoButton from './Components/LogoButton';
import MenuTabs from './Components/MenuTabs';
import { useMobileState } from './MobileContext';
import { useNavigationState } from './NavigationContext';

const Sider = () => {
  const { isCollapsed, setIsCollapsed, menuType } = useNavigationState();
  const isMobile = useMobileState();
  const toggleSider = () => setIsCollapsed(!isCollapsed);

  return (
    <Layout.Sider
      width={'300'}
      collapsedWidth={isMobile ? '0' : '100'}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
      collapsible
      collapsed={isCollapsed}
      breakpoint="sm"
      trigger={null}
      className={styles.sider}
    >
      <div
        className={styles.head}
        style={{ justifyContent: isCollapsed ? 'center' : 'space-between' }}
      >
        {!isCollapsed && <LogoButton />}

        <div onClick={toggleSider} style={{ cursor: 'pointer' }} className={styles.toggleContainer}>
          <ChevronDoubleLeftIcon
            className={styles.toggle}
            style={{
              transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
      </div>

      <div className={`${styles.menu} ${isCollapsed ? styles.menuCollapsed : ''}`}>
        <MenuTabs />
      </div>
    </Layout.Sider>
  );
};

export default Sider;
