import { getTimestampFromMeetingTime } from 'apps/agora/src/utils/helpers';
import { COLORS } from 'apps/agora/src/v3/global';

interface SessionDetailsChatMessageProps {
  timestamp: string;
  startDate?: string;
  accentColor: string;
  author: string;
  authenticatedUserFullName: string;
  message: string;
}

const SessionDetailsChatMessage = (props: SessionDetailsChatMessageProps) => {
  const { timestamp, startDate, accentColor, author, authenticatedUserFullName, message } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        {timestamp && startDate ? (
          <p className="text-customGrey text-xs leading-4 font-light">
            {getTimestampFromMeetingTime(timestamp, startDate)}
          </p>
        ) : (
          'n/a'
        )}
        <p
          className="text-black text-xs font-bold p-1 rounded-xsm"
          style={{
            backgroundColor:
              author !== authenticatedUserFullName ? accentColor : COLORS.TEXT_SECONDARY,
          }}
        >
          {author === authenticatedUserFullName ? 'You' : author}
        </p>
      </div>
      <p className="text-white text-sm leading-5 break-words">{message}</p>
    </div>
  );
};

export default SessionDetailsChatMessage;
