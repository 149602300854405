import Button from '@components/V4/Button';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import React, { ReactNode } from 'react';

interface EmptyStateCardProps {
  icon: ReactNode;
  text: string | ReactNode;
  buttonText?: string;
  className?: string;
  onClick?: () => void;
}

const EmptyStateCard = (props: EmptyStateCardProps) => {
  const { icon, text, buttonText, className, onClick } = props;
  return (
    <div
      className={mergeClassNames(
        'flex flex-col gap-4 items-center justify-center w-full ',
        className
      )}
    >
      {icon}

      <p className="text-xxs text-customGrey font-semibold text-center max-w-64 leading-4 whitespace-pre-wrap">
        {text}
      </p>

      {buttonText && <Button variant="secondary" buttonText={buttonText} onClick={onClick} />}
    </div>
  );
};

export default EmptyStateCard;
