import React, { useState } from 'react';
import InputError from '../Inputs/InputError';
import Star from './Star';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

interface StarRatingProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
  numberOfStars?: number;
  value?: number;
  size?: 'default' | 'small';
  isReadOnly?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({
  numberOfStars = 5,
  value = 0,
  size = 'default',
  isReadOnly = false,
  isValid,
  isTouched,
  errorText,
  className,
  onChange,
  ...inputProps
}) => {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);

  return (
    <div className={mergeClassNames('flex flex-col', className)}>
      <div
        className={mergeClassNames('flex items-center gap-2', {
          'gap-1': size === 'small',
        })}
      >
        {[...Array(numberOfStars)].map((_, index) => (
          <Star
            key={index}
            isReadOnly={isReadOnly}
            size={size}
            isGold={index + 1 <= (hoveredStar ?? value)}
            onClick={() => onChange?.(index + 1)}
            onMouseEnter={() => !isReadOnly && setHoveredStar(index + 1)}
            onMouseLeave={() => !isReadOnly && setHoveredStar(null)}
          />
        ))}
        <input type="hidden" value={value} {...inputProps} />
      </div>
      {!isValid && isTouched && errorText && <InputError errorText={errorText} />}
    </div>
  );
};

export default StarRating;
