import SectionHeader from '@components/Common/SectionHeader';
import { icons } from '@components/Navigation/NotificationsCard';
import { ClockIcon } from '@heroicons/react/outline';
import { SystemNotification } from '@shared/common';
import { useListMyNewNotifications } from '@shared/react';
import styles from '@styles/pages/notifications.module.scss';
import { Avatar, Space } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

const NotificationsPage = () => {
  const { data } = useListMyNewNotifications();

  const groups =
    data?.reduce((groups: Record<string, SystemNotification[]>, item) => {
      const date = item?.createdAt.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {}) || {};

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      notifications: groups[date],
    };
  });

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <SectionHeader title="Notifications" />

        <Space className={styles.notifications} size="large" direction="vertical">
          {groupArrays.map((group) => {
            const today = moment();
            const yesterday = moment().subtract(1, 'day');

            const title =
              group.date === today.format('YYYY-MM-DD')
                ? 'Today'
                : group.date === yesterday.format('YYYY-MM-DD')
                ? 'Yesterday'
                : moment(group.date).format('dddd, D MMMM');

            return (
              <Space className="w-full" direction="vertical" size="middle" key={group.date}>
                <div className={styles.notificationsTitle}>{title}</div>
                {group.notifications.map((notification) => (
                  <Link
                    to={notification.link}
                    className={styles.notification}
                    key={notification._id}
                  >
                    <Avatar
                      icon={icons[notification.type]}
                      className={styles.notificationAvatar}
                      size="large"
                    />

                    <Space direction="vertical" className="w-full">
                      <Space className="w-full">
                        {icons[notification.type]}
                        {notification.title}
                      </Space>

                      <Space className="w-full">
                        <ClockIcon className={styles.icon} />
                        {moment(notification.createdAt).format('HH:mm')} in
                        {notification.type}
                      </Space>
                    </Space>
                  </Link>
                ))}
              </Space>
            );
          })}
        </Space>
      </div>
    </div>
  );
};

export default NotificationsPage;
