import { EducationalBackground } from '@shared/common';
import { useContext, useMemo } from 'react';
import SchoolSection from '../../../SchoolSection/SchoolSection';
import UniversitySection from '../../../UniversitySection/UniversitySection';
import WorkExperienceSection from '../../../WorkExperienceSection/WorkExperienceSection';
import { MentorDetailsPageContext } from '../../MentorDetailsPage';

const ResumeTab = () => {
  const { mentor } = useContext(MentorDetailsPageContext);

  const { schools, universities } = useMemo(() => {
    const schools: EducationalBackground[] = [];
    const universities: EducationalBackground[] = [];

    mentor?.educationalBackground?.forEach((educationItem) => {
      const institutionType = educationItem.educationalInstitution.type;

      if (institutionType === 'University') {
        universities.push(educationItem);
      } else if (institutionType === 'School') {
        schools.push(educationItem);
      }
    });

    return { schools, universities };
  }, [mentor?.educationalBackground]);

  return (
    <>
      <WorkExperienceSection experiences={mentor?.experiences} userId={mentor?._id || ''} />
      <UniversitySection universities={universities} userId={mentor?._id || ''} />
      <SchoolSection schools={schools} userId={mentor?._id || ''} />
    </>
  );
};

export default ResumeTab;
