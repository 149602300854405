import classNames from 'classnames';
import React from 'react';
import styles from './agora-input-label.module.scss';

type Props = {
  htmlFor?: string;
  label: string;
  isRequired?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  className?: string;
};
const AgoraInputLabel = ({ htmlFor, label, isRequired, isValid, isTouched, className }: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        styles['agora-input-label'],
        className,
        isValid && isTouched
          ? styles['agora-input-label--valid']
          : !isValid && isTouched
          ? styles['agora-input-label--error']
          : ''
      )}
    >
      {label} {isRequired && <span>*</span>}
    </label>
  );
};

export default AgoraInputLabel;
