import { takeawaysApis } from '../../apis';
import { genericQueryWithParams } from './generics';

export const useMyTakeaways = genericQueryWithParams<takeawaysApis.SearchMyTakeaways>(
  takeawaysApis.searchMyTakeaways
);

export const useModuleTakeaways = genericQueryWithParams<takeawaysApis.GetModuleTakeaways>(
  takeawaysApis.getModuleTakeaways
);
