import { meetingSchedulerApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useMentorAvailablityInitialMeeting =
  genericQueryWithId<meetingSchedulerApis.GetMentorAvailabilityInitialMeeting>(
    meetingSchedulerApis.getMentorAvailabilityInitialMeeting
  );

export const useSetInitialMeeting = genericMutationWithId<meetingSchedulerApis.SetInitialMeeting>(
  meetingSchedulerApis.setInitialMeeting
);

export const useGetMentorAvailability =
  genericQueryWithParams<meetingSchedulerApis.GetMentorAvailability>(
    meetingSchedulerApis.getMentorAvailability
  );

export const useMarkMentorAvailabilityDays =
  genericMutationWithId<meetingSchedulerApis.MarkMentorAvailabilityDays>(
    meetingSchedulerApis.markMentorAvailabilityDays
  );

export const useUpdateMentorAvailability =
  genericMutationWithId<meetingSchedulerApis.UpdateMentorAvailability>(
    meetingSchedulerApis.updateMentorAvailability
  );

export const useDeleteMentorAvailability =
  genericMutationWithId<meetingSchedulerApis.DeleteMentorAvailability>(
    meetingSchedulerApis.deleteMentorAvailability
  );

export const useAddMentorAvailability =
  genericMutationWithId<meetingSchedulerApis.AddMentorAvailability>(
    meetingSchedulerApis.addMentorAvailability
  );
