import { pdfGeneratorApis } from '@shared/apis';
import { genericMutation } from './generics';

export const useGeneratePersonalLastMonthActivityReport =
  genericMutation<pdfGeneratorApis.GeneratePersonalLastMonthActivityReport>(
    pdfGeneratorApis.generatePersonalLastMonthActivityReport
  );

export const usePreviewActivityReport = genericMutation<pdfGeneratorApis.PreviewActivityReport>(
  pdfGeneratorApis.previewActivityReport
);
