import React from 'react';
import './page-title.styles.scss';
import { COLORS } from '../../../../v3/global';

type Props = {
  title?: string;
  description?: string;
};

const PageTitle = ({ title, description }: Props) => {
  return (
    <div>
      <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="page-title-title">
        {title}
      </h1>
      <h4 style={{ color: COLORS.TEXT_SECONDARY }} className="page-title-description">
        {description}
      </h4>
    </div>
  );
};

export default PageTitle;
