import Button from '@components/V4/Button';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { ReactNode } from 'react';

interface SessionDetailsEmptyStateProps {
  icon: ReactNode;
  text: string;
  buttonText?: string;
  textClassName?: string;
  onClick?: () => void;
  buttonVariant?: 'secondary' | 'primary' | 'primary-error';
}

const SessionDetailsEmptyState = (props: SessionDetailsEmptyStateProps) => {
  const { icon, text, buttonText, textClassName, onClick, buttonVariant = 'secondary' } = props;

  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full m-auto max-w-64">
      {icon}

      <p
        className={mergeClassNames(
          'text-sm text-customGrey text-center  leading-5 whitespace-pre-wrap',
          textClassName
        )}
      >
        {text}
      </p>

      {!!buttonText && (
        <Button variant={buttonVariant} buttonText={buttonText} onClick={() => onClick?.()} />
      )}
    </div>
  );
};

export default SessionDetailsEmptyState;
