import { ReactComponent as CompletedIcon } from '@assets/icons/V4/empty-states/completed-ongoing-courses-icon.svg';
import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/empty-icon.svg';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import Button from '@components/V4/Button';
import CourseProgressCard from '@components/V4/CourseProgressCard';
import { COURSES } from '@routes';
import { Enrollment } from '@shared/common';
import { useLoginAndRedirectToCourse } from '@shared/react';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './your-courses.styles.scss';

type Props = {
  enrollments: Enrollment[];
};

const YourCoursesStudent = ({ enrollments }: Props) => {
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [itemsPerRow, setItemsPerRow] = useState(1);
  const [showAll, setShowAll] = useState(false);

  const { mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  const filteredEnrollments = enrollments.filter((enrollment) => !enrollment.ended);

  const itemsToShow = showAll ? filteredEnrollments : filteredEnrollments.slice(0, itemsPerRow * 2);

  useEffect(() => {
    const calculateItemsPerRow = () => {
      if (containerRef.current) {
        const firstChild = containerRef.current.firstElementChild;
        if (firstChild) {
          const itemWidth = (firstChild as HTMLElement).offsetWidth;
          const containerWidth = containerRef.current.offsetWidth;
          setItemsPerRow(Math.floor(containerWidth / itemWidth));
        }
      }
    };

    calculateItemsPerRow();
    window.addEventListener('resize', calculateItemsPerRow);

    return () => {
      window.removeEventListener('resize', calculateItemsPerRow);
    };
  }, [filteredEnrollments]);

  const displayEnrollments = () => {
    if (enrollments.every((enrollment) => enrollment.ended)) {
      return (
        <EmptyStateCard
          icon={<CompletedIcon />}
          text="Congratulations on completing all your courses! Stay tuned for new opportunities."
        />
      );
    } else if (enrollments.some((enrollment) => !enrollment.ended)) {
      return (
        <div
          ref={containerRef}
          className="grid gap-4 grid-cols-[repeat(auto-fit,minmax(232px,1fr))]"
        >
          {itemsToShow.map((course) => (
            <CourseProgressCard
              className="h-full"
              enrollment={course}
              key={course._id}
              onClick={() => {
                if (course.course?.lmsCourseId) {
                  redirectToLMS({
                    courseId: course.course.lmsCourseId,
                  });
                } else {
                  history.push(`${COURSES}/${course.course._id}`);
                }
              }}
            />
          ))}
        </div>
      );
    } else {
      return (
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text="No courses available yet. Stay tuned for updates!"
        />
      );
    }
  };

  return (
    <div>
      <div className="your-courses-header-container">
        <div className="your-courses-section-title">
          <SectionTitle title="Ongoing Courses" />
        </div>
        {filteredEnrollments.length > itemsPerRow * 2 && (
          <Button
            variant="text"
            className="w-max"
            buttonText={showAll ? 'See Less' : 'See All'}
            onClick={() => setShowAll((prev) => !prev)}
          />
        )}
      </div>
      {displayEnrollments()}
    </div>
  );
};

export default YourCoursesStudent;
