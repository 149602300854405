import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import ArrowDown from '@assets/icons/ic-arrow-narrow-down.svg';
import ArrowUp from '@assets/icons/ic-arrow-narrow-up.svg';
import NPSDetractorsAndPassivesModal from '@components/V3/NPSDetractorsAndPassivesModal';
import './nps-detractors-passives-card.styles.scss';
import { Empty } from 'antd';

type Props = {
  problemTitle: string;
  problemRole: string;
  detractors: any;
  passives: any;
  date: any;
};

type Role = 'student' | 'mentor' | 'parent';

const NPSDetractorsAndPassivesCard = ({
  problemTitle,
  problemRole,
  detractors,
  passives,
  date,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const NPSDetractorsAndPassivesData = {};

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  function isWithinMonth(dateStr1: string, dateStr2: string) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    let previousMonth = month1 - 1;
    let previousMonthYear = year1;

    if (previousMonth < 0) {
      previousMonth = 11;
      previousMonthYear -= 1;
    }

    return year2 === previousMonthYear && month2 === previousMonth;
  }

  return (
    <div
      style={{
        minWidth: '224px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 4px #15223214',
        padding: '16px 17px 4px 17px',
      }}
    >
      {/*NPS DETRACTORS AND PASSIVES MODAL*/}
      {NPSDetractorsAndPassivesData && showModal && (
        <NPSDetractorsAndPassivesModal
          showModal={showModal}
          handleShowModal={handleShowModal}
          title={modalTitle}
          date={date}
          role={
            (problemRole.toLowerCase() === 'student' ||
            problemRole.toLowerCase() === 'mentor' ||
            problemRole.toLowerCase() === 'parent'
              ? problemRole.toLowerCase()
              : 'student') as Role
          }
          showCategoryTabs={true}
          npsType={problemTitle === 'Edu' ? 'education' : 'platform'}
        />
      )}

      <div
        style={{
          width: 'calc(100% - 10px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*DETRACTORS*/}
        <h4
          style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
          className="nps-detractors-passives-card-title"
        >
          {problemTitle} <span style={{ color: COLORS.TEXT_PRIMARY }}>{problemRole}</span>{' '}
          Detractors
        </h4>

        {detractors?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <p>No Data</p>
          </div>
        ) : (
          detractors?.slice(0, 5)?.map((detractor: any) => (
            <div
              style={{
                display: 'flex',
                marginBottom: '16px',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  width: '24%',
                  marginRight: '1%',
                }}
              >
                <span
                  style={{
                    color:
                      problemTitle === 'Edu' &&
                      detractor?.userRateCurrentMonth?.rating > detractor?.userRateLastMonth?.rating
                        ? COLORS.GREEN
                        : problemTitle === 'Edu' &&
                          detractor?.userRateCurrentMonth?.rating <
                            detractor?.userRateLastMonth?.rating
                        ? COLORS.RED
                        : problemTitle === 'Agora' &&
                          detractor?.userRateCurrentMonth?.platformRating >
                            detractor?.userRateLastMonth?.platformRating
                        ? COLORS.GREEN
                        : problemTitle === 'Agora' &&
                          detractor?.userRateCurrentMonth?.platformRating <
                            detractor?.userRateLastMonth?.platformRating
                        ? COLORS.RED
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="nps-detractors-passives-card-value"
                >
                  {problemTitle === 'Edu'
                    ? detractor?.userRateCurrentMonth?.rating
                    : problemTitle === 'Agora'
                    ? detractor?.userRateCurrentMonth?.platformRating
                    : '-'}
                </span>{' '}
                {problemTitle === 'Edu' &&
                detractor?.userRateCurrentMonth?.rating > detractor?.userRateLastMonth?.rating ? (
                  <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Edu' &&
                  detractor?.userRateCurrentMonth?.rating < detractor?.userRateLastMonth?.rating ? (
                  <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Agora' &&
                  detractor?.userRateCurrentMonth?.platformRating >
                    detractor?.userRateLastMonth?.platformRating ? (
                  <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Agora' &&
                  detractor?.userRateCurrentMonth?.platformRating <
                    detractor?.userRateLastMonth?.platformRating ? (
                  <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
                ) : (
                  <span
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginLeft: '2px',
                      marginRight: '2px',
                    }}
                    className="nps-detractors-passives-card-value"
                  >
                    =
                  </span>
                )}
                <span
                  style={{
                    color:
                      problemTitle === 'Edu' &&
                      detractor?.userRateCurrentMonth?.rating > detractor?.userRateLastMonth?.rating
                        ? COLORS.GREEN
                        : problemTitle === 'Edu' &&
                          detractor?.userRateCurrentMonth?.rating <
                            detractor?.userRateLastMonth?.rating
                        ? COLORS.RED
                        : problemTitle === 'Agora' &&
                          detractor?.userRateCurrentMonth?.platformRating >
                            detractor?.userRateLastMonth?.platformRating
                        ? COLORS.GREEN
                        : problemTitle === 'Agora' &&
                          detractor?.userRateCurrentMonth?.platformRating <
                            detractor?.userRateLastMonth?.platformRating
                        ? COLORS.RED
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="nps-detractors-passives-card-value"
                >
                  {problemTitle === 'Edu'
                    ? detractor?.userRateLastMonth?.rating
                    : problemTitle === 'Agora'
                    ? detractor?.userRateLastMonth?.platformRating
                    : ''}

                  {!detractor?.userRateLastMonth
                    ? '?'
                    : problemTitle === 'Edu' &&
                      isWithinMonth(date, detractor?.userRateLastMonth?.createdAt)
                    ? ''
                    : problemTitle === 'Edu' &&
                      !isWithinMonth(date, detractor?.userRateLastMonth?.createdAt)
                    ? '*'
                    : problemTitle === 'Agora' &&
                      isWithinMonth(date, detractor?.userRateLastMonth?.createdAt)
                    ? ''
                    : problemTitle === 'Agora' &&
                      !isWithinMonth(date, detractor?.userRateLastMonth?.createdAt)
                    ? '*'
                    : '?'}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '75%',
                }}
              >
                <p
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="nps-detractors-passives-card-name"
                >
                  {problemRole.toLowerCase() === 'parent'
                    ? detractor?.userRateCurrentMonth?.from?.parentData.fullName ?? '-'
                    : detractor?.userRateCurrentMonth?.from?.fullName ?? '-'}
                </p>
              </div>
            </div>
          ))
        )}

        {/*PASSIVES*/}
        <h4
          style={{
            color: COLORS.TEXT_SECONDARY,
            marginBottom: '24px',
            marginTop: '8px',
          }}
          className="nps-detractors-passives-card-title"
        >
          {problemTitle} <span style={{ color: COLORS.TEXT_PRIMARY }}>{problemRole}</span> Passives
        </h4>

        {passives?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <p>No Data</p>
          </div>
        ) : (
          passives?.slice(0, 5)?.map((passive: any) => (
            <div
              style={{
                display: 'flex',
                marginBottom: '16px',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  width: '24%',
                  marginRight: '1%',
                }}
              >
                <span
                  style={{
                    color:
                      problemTitle === 'Edu' &&
                      passive?.userRateCurrentMonth?.rating > passive?.userRateLastMonth?.rating
                        ? COLORS.GREEN
                        : problemTitle === 'Edu' &&
                          passive?.userRateCurrentMonth?.rating < passive?.userRateLastMonth?.rating
                        ? COLORS.RED
                        : problemTitle === 'Agora' &&
                          passive?.userRateCurrentMonth?.platformRating >
                            passive?.userRateLastMonth?.platformRating
                        ? COLORS.GREEN
                        : problemTitle === 'Agora' &&
                          passive?.userRateCurrentMonth?.platformRating <
                            passive?.userRateLastMonth?.platformRating
                        ? COLORS.RED
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="nps-detractors-passives-card-value"
                >
                  {problemTitle === 'Edu'
                    ? passive?.userRateCurrentMonth?.rating
                    : problemTitle === 'Agora'
                    ? passive?.userRateCurrentMonth?.platformRating
                    : '-'}
                </span>{' '}
                {problemTitle === 'Edu' &&
                passive?.userRateCurrentMonth?.rating > passive?.userRateLastMonth?.rating ? (
                  <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Edu' &&
                  passive?.userRateCurrentMonth?.rating < passive?.userRateLastMonth?.rating ? (
                  <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Agora' &&
                  passive?.userRateCurrentMonth?.platformRating >
                    passive?.userRateLastMonth?.platformRating ? (
                  <img src={ArrowUp} style={{ height: '12px' }} alt="arrow" />
                ) : problemTitle === 'Agora' &&
                  passive?.userRateCurrentMonth?.platformRating <
                    passive?.userRateLastMonth?.platformRating ? (
                  <img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />
                ) : (
                  <span
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginLeft: '2px',
                      marginRight: '2px',
                    }}
                    className="nps-detractors-passives-card-value"
                  >
                    =
                  </span>
                )}
                {/*<img src={ArrowDown} style={{ height: '12px' }} alt="arrow" />{' '}*/}
                <span
                  style={{
                    color:
                      problemTitle === 'Edu' &&
                      passive?.userRateCurrentMonth?.rating > passive?.userRateLastMonth?.rating
                        ? COLORS.GREEN
                        : problemTitle === 'Edu' &&
                          passive?.userRateCurrentMonth?.rating < passive?.userRateLastMonth?.rating
                        ? COLORS.RED
                        : problemTitle === 'Agora' &&
                          passive?.userRateCurrentMonth?.platformRating >
                            passive?.userRateLastMonth?.platformRating
                        ? COLORS.GREEN
                        : problemTitle === 'Agora' &&
                          passive?.userRateCurrentMonth?.platformRating <
                            passive?.userRateLastMonth?.platformRating
                        ? COLORS.RED
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="nps-detractors-passives-card-value"
                >
                  {problemTitle === 'Edu'
                    ? passive?.userRateLastMonth?.rating
                    : problemTitle === 'Agora'
                    ? passive?.userRateLastMonth?.platformRating
                    : ''}

                  {!passive?.userRateLastMonth
                    ? '?'
                    : problemTitle === 'Edu' &&
                      isWithinMonth(date, passive?.userRateLastMonth?.createdAt)
                    ? ''
                    : problemTitle === 'Edu' &&
                      !isWithinMonth(date, passive?.userRateLastMonth?.createdAt)
                    ? '*'
                    : problemTitle === 'Agora' &&
                      isWithinMonth(date, passive?.userRateLastMonth?.createdAt)
                    ? ''
                    : problemTitle === 'Agora' &&
                      !isWithinMonth(date, passive?.userRateLastMonth?.createdAt)
                    ? '*'
                    : '?'}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '75%',
                }}
              >
                <p
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="nps-detractors-passives-card-name"
                >
                  {problemRole.toLowerCase() === 'parent'
                    ? passive?.userRateCurrentMonth?.from?.parentData.fullName ?? '-'
                    : passive?.userRateCurrentMonth?.from?.fullName ?? '-'}
                </p>
              </div>
            </div>
          ))
        )}

        {/*SEE ALL BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '24px',
          }}
        >
          <span
            onClick={() => {
              setShowModal(true);
              setModalTitle(problemTitle + ' ' + problemRole + ' NPS Details');
            }}
            style={{ color: COLORS.PRIMARY, cursor: 'pointer' }}
            className="nps-problems-see-all-button"
          >
            See All
          </span>
        </div>
      </div>
    </div>
  );
};

export default NPSDetractorsAndPassivesCard;
