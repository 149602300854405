import ReactHtmlParser from '@orrisroot/react-html-parser';
import { PureComponent } from 'react';

class RawHtmlOutput extends PureComponent {
  constructor(props) {
    super(props);

    this.supportedKeys = [];
  }

  render() {
    let { data, style, classNames, config } = this.props;
    if (!data || !data.html) return '';
    if (!style || typeof style !== 'object') style = {};
    if (!config || typeof config !== 'object') config = {};
    if (!classNames || typeof classNames !== 'object') classNames = {};

    this.supportedKeys.forEach((key) => {
      if (!style[key] || typeof style[key] !== 'object') style[key] = {};
      if (!classNames[key] || typeof classNames[key] !== 'string') classNames[key] = '';
    });

    const containerStyle = config.disableDefaultStyle
      ? style.container
      : { width: '100%', ...style.container };

    return (
      <div style={containerStyle} className={classNames.container}>
        {ReactHtmlParser(data.html)}
      </div>
    );
  }
}

export default RawHtmlOutput;
