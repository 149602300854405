import { CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import './agora-big-selector.styles.scss';

type Props = {
  label: string;
  labelColor?: string;
  borderColor?: string;
  labelMargin?: string;
  selectMargin?: string;
  onSelect: (value: string | undefined) => void;
  onSearch?: (value: string) => void;
  options: any[] | undefined;
  labelKey?: string;
  secondLabelKey?: string;
  valueKey?: string;
  showOptionsBy?: 'value' | 'index' | undefined;
  mode?: 'multiple' | 'tags' | undefined;
  onDeselect?: (value: string | undefined) => void;
  onBlur?: (value: any) => void;
  onClear?: () => void;
  margin?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  borderRadius?: string;
  value?: any;
  isRequired?: boolean;
  className?: string;
};

const AgoraBigSelector = ({
  label,
  labelColor,
  borderColor,
  labelMargin,
  selectMargin,
  onSelect,
  onSearch,
  options,
  labelKey = '',
  secondLabelKey = '',
  valueKey = '',
  showOptionsBy,
  mode = undefined,
  onDeselect,
  onBlur,
  onClear,
  margin = '12px 24px 0px 0px',
  minHeight,
  height,
  width,
  borderRadius,
  value,
  isRequired,
  className,
}: Props) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: margin,
        width: width ?? 'inherit',
      }}
    >
      <label
        style={{
          margin: labelMargin ? labelMargin : mode === 'multiple' ? '0px' : '8px',
          color: labelColor ?? COLORS.TEXT_PRIMARY,
          marginBottom: mode === 'multiple' ? '8px' : '8px',
        }}
        // htmlFor={htmlFor}
        className="block text-sm font-medium leading-6 text-customPrimary"
      >
        {label} {isRequired && <span className="text-red-500">*</span>}
      </label>
      {/*<span*/}
      {/*  style={{*/}
      {/*    color: labelColor ?? COLORS.TEXT_PRIMARY,*/}
      {/*    marginBottom: mode === 'multiple' ? '0px' : '8px',*/}
      {/*    margin: labelMargin*/}
      {/*      ? labelMargin*/}
      {/*      : mode === 'multiple'*/}
      {/*      ? '0px'*/}
      {/*      : '8px',*/}
      {/*  }}*/}
      {/*  className="filter-label-big"*/}
      {/*>*/}
      {/*  {label}*/}
      {/*</span>*/}
      <div
        style={{
          margin: selectMargin ?? '',
          borderRadius: borderRadius ?? '',
          borderColor: borderColor ?? COLORS.PRIMARY,
        }}
        className="custom-search-selector-big"
      >
        <Select
          value={value}
          className={mode === 'multiple' ? 'custom-multiple-select-big' : 'custom-ant-select'}
          maxTagCount="responsive"
          onSelect={(value) => {
            onSelect(value);
          }}
          onDeselect={(value: string) => {
            if (onDeselect) {
              onDeselect(value);
            }
          }}
          mode={mode ? mode : undefined}
          showArrow={false}
          allowClear={true}
          onClear={onClear ? onClear : () => onSelect(undefined)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          clearIcon={
            <div className="clear-icon">
              <CloseCircleFilled
                className="close-icon"
                style={{
                  color: COLORS.DARK_GREY_ALT,
                  backgroundColor: 'transparent',
                  background: 'none',
                  fontSize: '20px',
                  position: 'absolute',
                  top: '-4px',
                  left: '-4px',
                }}
              />
            </div>
          }
          showSearch
          style={{
            width: '100%',
            outline: 'none',
            boxShadow: 'none',
            minHeight: minHeight ?? 'inherit',
            height: height ?? 'inherit',
            display: 'flex',
            alignItems: 'center',
          }}
          placeholder={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                outline: 'none',
                boxShadow: 'none',
              }}
            >
              <span>Search to select</span>
              <span>
                <DownOutlined style={{ fontSize: '10px' }} />
              </span>
            </div>
          }
          options={options?.map((option, index) => ({
            value:
              showOptionsBy === 'value'
                ? option
                : showOptionsBy === 'index'
                ? index.toString()
                : option?.[valueKey],
            label:
              showOptionsBy === 'value'
                ? option
                : showOptionsBy === 'index'
                ? option
                : secondLabelKey
                ? option?.[labelKey]?.[secondLabelKey]
                : option?.[labelKey],
          }))}
          onSearch={(value) => {
            if (onSearch) {
              onSearch(value);
            }
          }}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default AgoraBigSelector;
