import { ReactComponent as ArrowDownIcon } from '@assets/icons/V4/arrow-down.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import SessionModalSection, {
  ActionButton,
} from '@modules/Admin/components/SessionModal/SessionModalSection';
import { useGetAllCoursesCanTeach, useReorderCoursesCanTeach } from '@shared/react';
import { GetAllCoursesCanTeachDTO } from '@shared/types/DTOs/MentorsDTO';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useContext, useState } from 'react';
import { MentorDetailsPageContext } from '../../../MentorDetailsPage';
import CoursesForm from './CoursesForm';

const CoursesTable = () => {
  const { mentor } = useContext(MentorDetailsPageContext);

  const { data: allCourses } = useGetAllCoursesCanTeach(mentor?._id);

  const { mutate: reorderCoursesCanTeach, isLoading: isReorderingCoursesCanTeach } =
    useReorderCoursesCanTeach();

  const [isAddingCourse, setIsAddingCourse] = useState(false);

  const [paginatedCourses, pagination] = useClientPagination({
    data: allCourses || [],
    shouldOnlyShowPages: true,
  });

  const buttons: ActionButton[] = [{ buttonText: 'Add', onClick: () => setIsAddingCourse(true) }];

  const deleteEntryHandler = (_id: string) => {
    if (allCourses) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id,
            delete: true,
          },
        ],
        userId: mentor?._id,
      });
    }
  };

  const decrementOrderHandler = (course: any) => {
    if (!isReorderingCoursesCanTeach) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id: course._id,
            newOrder: course.order - 1,
          },
        ],
        userId: mentor?._id,
      });
    }
  };
  const incrementOrderHandler = (course: any) => {
    if (!isReorderingCoursesCanTeach) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id: course._id,
            newOrder: course.order + 1,
          },
        ],
        userId: mentor?._id,
      });
    }
  };

  return isAddingCourse ? (
    <CoursesForm
      className="mt-6"
      coursesCanTeach={allCourses}
      onCloseEditForm={() => setIsAddingCourse(false)}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Courses Can Teach" buttonsArray={buttons}>
      {allCourses?.length ? (
        <Table size="small" pagination={pagination}>
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell>Course</Table.HeaderCell>
              <Table.HeaderCell>Students</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedCourses.map((item: GetAllCoursesCanTeachDTO) => (
              <Table.Row key={item._id}>
                <>
                  <Table.Cell>{item.title}</Table.Cell>
                  <Table.Cell>{item.activeStudents}</Table.Cell>
                  <Table.Cell>
                    <div className="flex gap-4">
                      <IconButton
                        isDisabled={item.order === 0 || isReorderingCoursesCanTeach}
                        size="small"
                        variant="ghost"
                        icon={<ArrowDownIcon className="rotate-180 h-4 w-4" />}
                        onClick={() => decrementOrderHandler(item)}
                      />
                      <IconButton
                        isDisabled={
                          (!!allCourses && item.order === allCourses?.length - 1) ||
                          isReorderingCoursesCanTeach
                        }
                        size="small"
                        variant="ghost"
                        icon={<ArrowDownIcon className="h-4 w-4" />}
                        onClick={() => incrementOrderHandler(item)}
                      />
                      <IconButton
                        isDisabled={isReorderingCoursesCanTeach}
                        size="small"
                        variant="ghost"
                        color="danger"
                        icon={<TrashIcon />}
                        onClick={() => deleteEntryHandler(item._id)}
                      />
                    </div>
                  </Table.Cell>
                </>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard text="No Existing Data" icon={<NoDataIcon className="text-customGrey" />} />
      )}
    </SessionModalSection>
  );
};

export default CoursesTable;
