// TEMPORARY VIEW UNTILL THE NEW DESIGN GETS IMPLEMENTED

import SectionHeader from '@components/Common/SectionHeader';
import InformationCard from '@modules/Mentor/SessionHistory/InformationCard';
import ReportsAndInvoicesTable from '@modules/Mentor/SessionHistory/ReportsAndInvoicesTable';
import { useAuthState, useGetMentorReportActivityHistory, useMentorSessionNo } from '@shared/react';
import style from '@styles/components/session-history.module.scss';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { COLORS } from '../../../v3/global';

const tabs = [
  { name: 'Open Reports', current: false, key: 'Tab 1' },
  { name: 'Past Reports', current: false, key: 'Tab 2' },
];

const ActivityReport = () => {
  const { userId, rate } = useAuthState();
  const { data: sessions } = useMentorSessionNo(userId);
  const { data: currentReports, isLoading: currentReportsLoading } =
    useGetMentorReportActivityHistory({
      id: userId,
      date: 'current',
    });

  const { data: pastReports, isLoading: pastReportsLoading } = useGetMentorReportActivityHistory({
    id: userId,
    date: 'past',
  });

  const [currency, setCurrency] = useState('$');
  const [activeTab, setActiveTab] = useState('Tab 1');

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    switch (rate?.currency) {
      case 'USD':
        setCurrency('$');
        break;
      case 'EUR':
        setCurrency('€');
        break;
      case 'GBP':
        setCurrency('£');
        break;
      case 'RON':
        setCurrency('RON');
        break;
      default:
        setCurrency('$');
        break;
    }
  }, [rate]);

  return (
    <div className={style.sessionHistoryPageContainer}>
      <SectionHeader title="Payments Dashboard" />
      <div className={style.paymentsDashboardCardsContainer}>
        <InformationCard
          title={`${currency} ${
            rate?.amount && sessions?.noOfSessions ? rate?.amount * sessions?.noOfSessions : 0
          }`}
          subtitle={`Estimated balance for ${moment().startOf('month').format('DD.MM')} -
        ${moment().format('DD.MM')}`}
        />
        <InformationCard title={`${currency} ${rate?.amount ?? 0}`} subtitle="Hourly Rate" />
        <InformationCard
          title={sessions?.noOfSessions.toString()}
          subtitle={`${
            sessions?.noOfSessions !== 1 ? 'Sessions' : 'Session'
          } held between ${moment().startOf('month').format('DD.MM')} -
        ${moment().format('DD.MM')}`}
        />
      </div>

      <div style={{ marginTop: '40px' }} />
      <SectionHeader title="Activity Reports & Invoices" />

      {/*TAILWIND TABS*/}
      <div style={{ marginBottom: '16px' }}>
        <div>
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs?.map((tab) => (
                <span
                  style={{
                    cursor: 'pointer',
                    color: activeTab === tab?.key ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                    borderColor: activeTab === tab?.key ? COLORS.PRIMARY : 'inherit',
                  }}
                  key={tab?.key}
                  onClick={() => setActiveTab(tab?.key)}
                  className={classNames(
                    tab?.key === activeTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                  )}
                  aria-current={tab?.current ? 'page' : undefined}
                >
                  {tab?.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '1rem', padding: '0 0' }}>
        {activeTab === 'Tab 1' && (
          <ReportsAndInvoicesTable
            type="CURRENT_REPORTS"
            isLoading={currentReportsLoading}
            reports={currentReports}
          />
        )}

        {activeTab === 'Tab 2' && (
          <ReportsAndInvoicesTable
            type="PAST_REPORTS"
            isLoading={pastReportsLoading}
            reports={pastReports}
          />
        )}
      </div>

      <div style={{ marginTop: '40px' }} />
    </div>
  );
};

export default ActivityReport;
