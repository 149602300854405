import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import Modal from '@components/V4/Modal/Modal';
import { PaginationContext } from '@components/V4/Modal/MultiPageModal/MultiPageModal';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import AIGeneratedFeedbackToggle from '../AIGeneratedFeedbackToggle';

export type ParentHelp = {
  feedback: string;
};

export const parentHelpSchema = z.object({
  feedback: z.string(),
});

export type ParentHelpFormData = z.infer<typeof parentHelpSchema>;

interface ParentHelpPageProps extends BasicProps {
  isAIFeedbackReady: boolean;
  didAIFeedbackGenerationFail?: boolean;
  isFeedbackAIGenerationEnabled: boolean;
  parentHelpFormData: UseFormReturn<ParentHelpFormData, any, undefined>;
  onNextClick: (parentHelp: ParentHelp) => void;
  onIsFeedbackAIGenerationEnabledToggle: (isAIGenerated: boolean) => void;
}

const ParentHelpPage = (props: ParentHelpPageProps) => {
  const {
    isAIFeedbackReady,
    parentHelpFormData,
    didAIFeedbackGenerationFail,
    isFeedbackAIGenerationEnabled,
    onNextClick,
    onIsFeedbackAIGenerationEnabledToggle,
  } = props;
  const { goBack, goToNextPage } = useContext(PaginationContext);

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = parentHelpFormData;

  const touchAllFields = (fields: Record<keyof ParentHelpFormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof ParentHelpFormData, getValues()[key as keyof ParentHelpFormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: ParentHelpFormData) => {
    const isValid = await trigger();

    if (isValid) {
      goToNextPage();
      onNextClick(data);
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <Modal.Body className="tablet:px-0 gap-6">
      <div className="flex flex-col gap-2 tablet:gap-0 tablet:flex-row tablet:justify-between tablet:items-center">
        <h3 className="text-customGrey text-2xl font-semibold">Parent Help</h3>
        <AIGeneratedFeedbackToggle
          didAIFeedbackGenerationFail={didAIFeedbackGenerationFail}
          isDisabled={!isAIFeedbackReady}
          isChecked={isFeedbackAIGenerationEnabled && isAIFeedbackReady}
          onClick={() => onIsFeedbackAIGenerationEnabledToggle(!isFeedbackAIGenerationEnabled)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <TextArea
          className="text-customGrey"
          id="parentHelpFeedback"
          label="Anything the parent can help with"
          isTouched={touchedFields.feedback}
          isValid={!errors.feedback}
          errorText={errors.feedback?.message}
          {...register('feedback')}
        />
        {isAIFeedbackReady && (
          <span className="text-xsm text-customRed">
            This feature is in beta, so please review and adjust the feedback to ensure accuracy.
          </span>
        )}
      </div>

      <div className="flex justify-end gap-4">
        <Button
          variant="secondary"
          className="w-24"
          size="large"
          buttonText="Back"
          onClick={goBack}
        />
        <Button className="w-24" size="large" buttonText="Next" onClick={submitHandler} />
      </div>
    </Modal.Body>
  );
};

export default ParentHelpPage;
