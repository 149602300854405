import KnowledgeBaseAIAsistantIcon from '@assets/icons/knowledge-base-ai-assistant-icon.svg';
import UELogoSecondary from '@assets/ue-logo-secondary.svg';

export default function ChatPlaceholder() {
  return (
    <div className="flex flex-col items-center justify-center max-w-115 h-full">
      <img
        src={UELogoSecondary}
        alt="upgrade-logo"
        className="min-h-6 max-h-6 w-auto h-auto mb-6 tablet:max-h-16 laptop:max-h-21 laptop:mb-8"
      />
      <div className="mb-8 flex justify-center items-center">
        <h2 className="text-lg laptop:text-2xl mr-4 text-customGrey">Knowledge Base Assistant</h2>
        <img src={KnowledgeBaseAIAsistantIcon} alt="ai-icon" className="h-5 w-auto" />
      </div>
      <div className="flex flex-col">
        <p className="mb-6 text-center text-customGrey text-xsm">
          This tool uses our existing Knowledge Base to answer your questions. Simply type in your
          query related to Upgrade Education topics, and the AI will try to provide you with what it
          thinks a good response is.{' '}
        </p>
        <p className="mb-6 text-center text-customGrey text-xsm">
          Disclaimer: These chats are stored and might be used at a later date by Upgrade Education
          to fine-tune the model. ChatGPT can make mistakes, it's ultimately up to you to decide
          whether you act on any information provided.{' '}
        </p>
        <p className="text-center text-customGrey text-xsm">Start chatting now!</p>
      </div>
    </div>
  );
}
