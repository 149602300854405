import CreateNewUserAdminStepOne from '@components/V3/CreateNewUser/CreateNewUserAdminStepOne';
import CreateNewUserConfirmation from '@components/V3/CreateNewUser/CreateNewUserConfirmation';
import CreateNewUserMentorStepOne from '@components/V3/CreateNewUser/CreateNewUserMentorStepOne';
import CreateNewUserMentorStepTwo from '@components/V3/CreateNewUser/CreateNewUserMentorStepTwo';
import CreateNewUserStepOne from '@components/V3/CreateNewUser/CreateNewUserStepOne';
import CreateNewUserStepTwo from '@components/V3/CreateNewUser/CreateNewUserStepTwo';
import CreateNewUserStudentStepOne from '@components/V3/CreateNewUser/CreateNewUserStudentStepOne';
import CreateNewUserStudentStepTwo from '@components/V3/CreateNewUser/CreateNewUserStudentStepTwo';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import SessionModal from '@modules/Admin/components/SessionModal/SessionModal';
import { useCreateUserV2, useGetUserProfile } from '@shared/react';
import { AdminDetails, MentorDetails, StudentDetails } from '@shared/types';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import './create-new-user.styles.scss';
import UserPermissionsPage from '../UserPermissionsModal/UserPermissionsPage';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
};

export interface CreateMentorDetails extends Omit<MentorDetails, 'coursesCanTeach'> {
  coursesCanTeach: { _id: string }[];
}

const CreateNewUserModal = ({ showModal, handleShowModal }: Props) => {
  const [currentPage, setCurrentPage] = useState('create-new-user-step-one');

  const [newUserId, setNewUserId] = useState<string | undefined>();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState<'STUDENT' | 'MENTOR' | 'ADMIN' | undefined>();

  const [emailError, setEmailError] = useState('');

  const [hubspotUserData, setHubspotUserData] = useState<any>(null);

  const [studentDetails, setStudentDetails] = useState<StudentDetails>({
    fullName: '',
    phoneNumber: '',
    parentName: '',
    parentEmail: '',
    parentPhoneNumber: '',
    city: '',
    country: '',
    highschool: '',
    graduationYear: '',
    gpa: '',
    universities: [],
    countriesToStudyIn: [],
    major: '',
    preferredLanguage: '',
    timezone: '',
  });

  const [mentorDetails, setMentorDetails] = useState<CreateMentorDetails>({
    fullName: '',
    phoneNumber: '',
    coursesCanTeach: [],
    mentorStatus: '',
    payRate: undefined,
    currency: '',
    billingCurrency: '',
    vatPayer: false,
    company: '',
    companyCountry: '',
    companyAddress: '',
    fiscalRegistrationNumber: '',
    companyIBAN: '',
  });

  const [adminDetails, setAdminDetails] = useState<AdminDetails>({
    fullName: '',
    phoneNumber: '',
  });

  const handleEmailError = (emailError: string) => {
    setEmailError(emailError);
  };

  const handleHubspotUserData = (userData: any) => {
    setHubspotUserData(userData);
  };

  const handleCurrentPage = (page: string) => {
    setCurrentPage(page);
  };

  const handleEmail = (email: string) => {
    setEmail(email);
  };

  const handleRole = (role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined) => {
    setRole(role);
  };

  const handleInputChangeStudent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name in studentDetails) {
      setStudentDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInputChangeMentor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name in mentorDetails) {
      setMentorDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInputChangeAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name in adminDetails) {
      setAdminDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleArrayChangeStudent = (name: keyof StudentDetails, value: string) => {
    if (Array.isArray(studentDetails[name])) {
      setStudentDetails((prevState) => ({
        ...prevState,
        [name]: [...(prevState[name] as string[]), value],
      }));
    }
  };

  const handleArrayChangeMentor = (name: keyof MentorDetails, value: string) => {
    if (Array.isArray(mentorDetails[name])) {
      setMentorDetails((prevState) => ({
        ...prevState,
        [name]: [...(prevState[name] as { _id: string; order: number }[] | string[]), value],
      }));
    }
  };

  const handleArrayRemoveStudent = (name: keyof StudentDetails, index: number) => {
    if (Array.isArray(studentDetails[name])) {
      setStudentDetails((prevState) => ({
        ...prevState,
        [name]: (prevState[name] as string[]).filter((_, i) => i !== index),
      }));
    }
  };

  const handleArrayRemoveMentor = (name: keyof MentorDetails, index: number) => {
    if (name === 'coursesCanTeach') {
      setMentorDetails((prevState) => ({
        ...prevState,
      }));
    }

    if (Array.isArray(mentorDetails[name])) {
      setMentorDetails((prevState) => ({
        ...prevState,
        [name]: (prevState[name] as { _id: string; order: number }[] | string[]).filter(
          (_, i) => i !== index
        ),
      }));
    }
  };

  const handleCountrySelectStudent = (selectedOption: any) => {
    setStudentDetails((prevDetails) => ({
      ...prevDetails,
      country: selectedOption,
    }));
  };

  const handlePreferredLanguageSelectStudent = (selectedOption: any) => {
    setStudentDetails((prevDetails) => ({
      ...prevDetails,
      preferredLanguage: selectedOption,
    }));
  };

  const handleTimezoneSelectStudent = (selectedOption: any) => {
    setStudentDetails((prevDetails) => ({
      ...prevDetails,
      timezone: selectedOption,
    }));
  };

  const handleCountriesToStudyInSelect = (values: string[]) => {
    setStudentDetails((prevDetails) => ({
      ...prevDetails,
      countriesToStudyIn: values,
    }));
  };

  const handleCoursesCanTeachSelect = (values: { _id: string }[]) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      coursesCanTeach: values,
    }));
  };

  const handleGraduationYearChange = (date: Moment | null) => {
    if (date) {
      const dateString = date.format('YYYY');
      setStudentDetails((prev) => ({
        ...prev,
        graduationYear: dateString,
      }));
    }
  };

  const handleUniversities = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStudentDetails((prevStudentDetails) => ({
      ...prevStudentDetails,
      universities: [newValue, ...prevStudentDetails.universities.slice(1)],
    }));
  };

  const handleStatusMentor = (value: any) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      mentorStatus: value,
    }));
  };

  const handleCurrencyMentor = (value: any) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      currency: value,
    }));
  };

  const handleBillingCurrencyMentor = (value: any) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      billingCurrency: value,
    }));
  };

  const handleVATPayer = (value: boolean) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      vatPayer: value,
    }));
  };

  const handleCompanyCountryMentor = (value: any) => {
    setMentorDetails((prevMentorDetails) => ({
      ...prevMentorDetails,
      companyCountry: value,
    }));
  };

  useEffect(() => {
    if (hubspotUserData && hubspotUserData?.role?.toLowerCase() === 'student') {
      setRole('STUDENT');
      setStudentDetails({
        ...studentDetails,
        city: hubspotUserData?.city ?? '',
        country: hubspotUserData?.country ?? '',
        fullName: hubspotUserData?.fullName ?? '',
        graduationYear: hubspotUserData?.graduationYear ?? '',
        highschool: hubspotUserData?.highschool ?? '',
        parentEmail: hubspotUserData?.parentEmail ?? '',
        parentName: hubspotUserData?.parentFullName ?? '',
        parentPhoneNumber: hubspotUserData?.parentPhoneNumber ?? '',
        phoneNumber: hubspotUserData?.phoneNumber ?? '',
        universities: hubspotUserData?.universities ?? [],
      });
    }
    if (hubspotUserData && hubspotUserData?.role?.toLowerCase() === 'mentor') {
      setRole('MENTOR');
      setMentorDetails({
        ...mentorDetails,
        fullName: hubspotUserData?.fullName ?? '',
        phoneNumber: hubspotUserData?.phoneNumber ?? '',
        coursesCanTeach: hubspotUserData?.subjectsMentorsCanTeach
          ? [hubspotUserData?.subjectsMentorsCanTeach]
          : [],
        payRate: hubspotUserData?.amount?.toString() ?? null,
        currency: hubspotUserData?.currency ?? '',
        billingCurrency: hubspotUserData?.invoiceCurrency ?? '',
        company: hubspotUserData?.companyName ?? '',
      });
    }
    if (hubspotUserData && hubspotUserData?.role?.toLowerCase() === 'admin') {
      setRole('ADMIN');
      setAdminDetails({
        ...adminDetails,
        fullName: hubspotUserData?.fullName ?? '',
        phoneNumber: hubspotUserData?.phoneNumber ?? '',
      });
    }
  }, [hubspotUserData]);

  const { data: userProfile, refetch: refetchUserData } = useGetUserProfile(newUserId, {
    onSuccess: () => {
      switch (currentPage) {
        case 'create-new-user-student-step-two':
          handleCurrentPage('create-new-user-enrolments-student');
          break;

        case 'create-new-user-permissions-mentor':
          handleCurrentPage('create-new-user-enrolments-mentor');
          break;
      }
    },
  });

  const { mutate: createNewUser } = useCreateUserV2({
    onSuccess: (response) => {
      setNewUserId(response.id);
      if (currentPage === 'create-new-user-permissions-admin') {
        handleCurrentPage('create-new-user-confirmation');
      }
    },
  });

  return (
    <div>
      <AgoraModal
        backButton={
          currentPage === 'create-new-user-step-two' ||
          currentPage === 'create-new-user-student-step-one' ||
          currentPage === 'create-new-user-student-step-two' ||
          currentPage === 'create-new-user-mentor-step-one' ||
          currentPage === 'create-new-user-mentor-step-two' ||
          currentPage === 'create-new-user-admin-step-one' ||
          currentPage === 'create-new-user-permissions-mentor' ||
          currentPage === 'create-new-user-permissions-admin'
        }
        onBack={
          currentPage === 'create-new-user-step-two'
            ? handleCurrentPage
            : currentPage === 'create-new-user-student-step-one' ||
              currentPage === 'create-new-user-mentor-step-one' ||
              currentPage === 'create-new-user-admin-step-one'
            ? () => handleCurrentPage('create-new-user-step-two')
            : currentPage === 'create-new-user-student-step-two'
            ? () => handleCurrentPage('create-new-user-student-step-one')
            : currentPage === 'create-new-user-mentor-step-two'
            ? () => handleCurrentPage('create-new-user-mentor-step-one')
            : currentPage === 'create-new-user-permissions-mentor'
            ? () => handleCurrentPage('create-new-user-mentor-step-two')
            : currentPage === 'create-new-user-permissions-admin'
            ? () => handleCurrentPage('create-new-user-admin-step-one')
            : () => null
        }
        backRoute={
          currentPage === 'create-new-user-step-two'
            ? 'create-new-user-step-one'
            : currentPage === 'create-new-user-student-step-one' ||
              currentPage === 'create-new-user-mentor-step-one' ||
              currentPage === 'create-new-user-admin-step-one'
            ? 'create-new-user-step-two'
            : currentPage === 'create-new-user-student-step-two'
            ? 'create-new-user-student-step-one'
            : currentPage === 'create-new-user-mentor-step-two'
            ? 'create-new-user-mentor-step-one'
            : currentPage === 'create-new-user-permissions-mentor'
            ? 'create-new-user-mentor-step-two'
            : currentPage === 'create-new-user-permissions-admin'
            ? 'create-new-user-admin-step-one'
            : 'create-new-user-step-one'
        }
        title={
          currentPage === 'create-new-user-step-one'
            ? `Create New User`
            : currentPage === 'create-new-user-step-two'
            ? 'Create New User'
            : currentPage === 'create-new-user-student-step-one'
            ? 'Complete Student Details'
            : currentPage === 'create-new-user-student-step-two'
            ? 'Complete Student Details'
            : currentPage === 'create-new-user-mentor-step-one'
            ? 'Complete Mentor Details'
            : currentPage === 'create-new-user-mentor-step-two'
            ? 'Complete Mentor Details'
            : currentPage === 'create-new-user-admin-step-one'
            ? 'Complete Admin Details'
            : currentPage === 'create-new-user-permissions-mentor' ||
              currentPage === 'create-new-user-permissions-admin'
            ? 'Edit Permissions'
            : currentPage === 'create-new-user-confirmation'
            ? ''
            : ''
        }
        title2={''}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        onClose={() => {
          if (currentPage === 'create-new-user-confirmation') {
            setCurrentPage('create-new-user-step-one');
            setStudentDetails({
              fullName: '',
              phoneNumber: '',
              parentName: '',
              parentEmail: '',
              parentPhoneNumber: '',
              city: '',
              country: '',
              highschool: '',
              graduationYear: '',
              gpa: '',
              universities: [],
              countriesToStudyIn: [],
              major: '',
            });
            setMentorDetails({
              fullName: '',
              phoneNumber: '',
              coursesCanTeach: [],
              mentorStatus: '',
              payRate: undefined,
              currency: '',
              billingCurrency: '',
              vatPayer: false,
              company: '',
              companyCountry: '',
              companyAddress: '',
              fiscalRegistrationNumber: '',
              companyIBAN: '',
            });
            setAdminDetails({
              fullName: '',
              phoneNumber: '',
            });
            setEmail('');
            setHubspotUserData(null);
          }
        }}
        width="58vw"
      >
        {currentPage === 'create-new-user-step-one' ? (
          <CreateNewUserStepOne
            handleCurrentPage={handleCurrentPage}
            email={email}
            handleEmail={handleEmail}
            handleEmailError={handleEmailError}
            emailError={emailError}
          />
        ) : currentPage === 'create-new-user-step-two' ? (
          <CreateNewUserStepTwo
            email={email}
            handleCurrentPage={handleCurrentPage}
            role={role}
            handleRole={handleRole}
            handleHubspotUserData={handleHubspotUserData}
            hubspotUserData={hubspotUserData}
            handleEmailError={handleEmailError}
          />
        ) : currentPage === 'create-new-user-student-step-one' ? (
          <CreateNewUserStudentStepOne
            handleCurrentPage={handleCurrentPage}
            studentDetails={studentDetails}
            handleInputChangeStudent={handleInputChangeStudent}
          />
        ) : currentPage === 'create-new-user-student-step-two' ? (
          <CreateNewUserStudentStepTwo
            email={email}
            role={role}
            handleCurrentPage={handleCurrentPage}
            studentDetails={studentDetails}
            handleInputChangeStudent={handleInputChangeStudent}
            setNewUserId={setNewUserId}
            handleCountrySelectStudent={handleCountrySelectStudent}
            handleCountriesToStudyInSelect={handleCountriesToStudyInSelect}
            handleGraduationYearChange={handleGraduationYearChange}
            handlePreferredLanguageSelectStudent={handlePreferredLanguageSelectStudent}
            handleTimezoneSelectStudent={handleTimezoneSelectStudent}
            handleUniversities={handleUniversities}
            createUserV2={createNewUser}
          />
        ) : currentPage === 'create-new-user-mentor-step-one' ? (
          <CreateNewUserMentorStepOne
            handleCurrentPage={handleCurrentPage}
            mentorDetails={mentorDetails}
            handleInputChangeMentor={handleInputChangeMentor}
            handleArrayChangeMentor={handleArrayChangeMentor}
            handleArrayRemoveMentor={handleArrayRemoveMentor}
            handleCoursesCanTeachSelect={handleCoursesCanTeachSelect}
            handleStatusMentor={handleStatusMentor}
          />
        ) : currentPage === 'create-new-user-mentor-step-two' ? (
          <CreateNewUserMentorStepTwo
            handleCurrentPage={handleCurrentPage}
            mentorDetails={mentorDetails}
            handleInputChangeMentor={handleInputChangeMentor}
            handleArrayChangeMentor={handleArrayChangeMentor}
            handleArrayRemoveMentor={handleArrayRemoveMentor}
            handleCountriesToStudyInSelect={handleCountriesToStudyInSelect}
            handleGraduationYearChange={handleGraduationYearChange}
            handleCurrencyMentor={handleCurrencyMentor}
            handleBillingCurrencyMentor={handleBillingCurrencyMentor}
            handleVATpayer={handleVATPayer}
            handleCompanyCountryMentor={handleCompanyCountryMentor}
          />
        ) : currentPage === 'create-new-user-admin-step-one' ? (
          <CreateNewUserAdminStepOne
            email={email}
            role={role}
            handleCurrentPage={handleCurrentPage}
            adminDetails={adminDetails}
            handleInputChangeAdmin={handleInputChangeAdmin}
          />
        ) : currentPage === 'create-new-user-enrolments-student' ? (
          <SessionModal
            isOpen
            onClose={() => setCurrentPage('create-new-user-confirmation')}
            studentId={newUserId}
            page="student"
          />
        ) : currentPage === 'create-new-user-enrolments-mentor' ? (
          <SessionModal
            isOpen
            onClose={() => setCurrentPage('create-new-user-confirmation')}
            mentorId={newUserId}
            page="mentor"
          />
        ) : currentPage === 'create-new-user-permissions-mentor' ? (
          <UserPermissionsPage
            userData={{
              email: email,
              userType: role ?? '',
              mentorData: {
                fullName: mentorDetails.fullName,
                phoneNumber: mentorDetails.phoneNumber,
                city: '',
                country: '',
                universities: [''],
                majors: [],
                countries: [''],
                status: mentorDetails.mentorStatus,
                coursesCanTeach: mentorDetails.coursesCanTeach,
                capacity: 10,
                rate: {
                  amount: Number(mentorDetails.payRate),
                  currency: mentorDetails.currency,
                  billingCurrency: mentorDetails.billingCurrency,
                  vatPayer: mentorDetails.vatPayer,
                },
                companyModel: '',
                companyName: mentorDetails.company,
                companyAddress: mentorDetails.companyAddress,
                companyCountry: mentorDetails.companyCountry,
                companyCUI: mentorDetails.fiscalRegistrationNumber,
                companyIBAN: mentorDetails.companyIBAN,
              },
            }}
            createUserV2={createNewUser}
            refetchUserData={refetchUserData}
            currentPage={currentPage}
          />
        ) : currentPage === 'create-new-user-permissions-admin' ? (
          <UserPermissionsPage
            userData={{
              email: email,
              userType: role ?? '',
              adminData: {
                fullName: adminDetails.fullName,
                phoneNumber: adminDetails.phoneNumber,
              },
            }}
            createUserV2={createNewUser}
            refetchUserData={refetchUserData}
            currentPage={currentPage}
          />
        ) : currentPage === 'create-new-user-confirmation' ? (
          <CreateNewUserConfirmation />
        ) : null}
      </AgoraModal>
    </div>
  );
};

export default CreateNewUserModal;
