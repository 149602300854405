import Tabs from '@components/V4/Tabs/Tabs';
import { GetSessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';
import SessionChatTab from './SessionChatTab/SessionChatTab';
import SessionFeedbackTab from './SessionFeedbackTab/SessionFeedbackTab';
import SessionMeetingsTab from './SessionMeetingsTab/SessionMeetingsTab';
import SessionNotesTab from './SessionNotesTab/SessionNotesTab';
import SessionTranscriptTab from './SessionTranscriptTab/SessionTranscriptTab';

export type StudentTab = 'meetings' | 'feedback' | 'transcript' | 'chat' | 'session-notes';

interface SessionTabsSectionProps {
  tab?: StudentTab;
  session?: GetSessionDTO;
}

const SessionTabsSection = (props: SessionTabsSectionProps) => {
  const { tab = 'meetings', session } = props;

  const tabs: { label: string; value: StudentTab }[] = [
    { label: 'Meetings', value: 'meetings' },
    { label: 'Feedback', value: 'feedback' },
    { label: 'Transcript', value: 'transcript' },
    { label: 'Chat', value: 'chat' },
    { label: 'Session Notes', value: 'session-notes' },
  ];

  const renderContent = (currentTab: StudentTab) => {
    switch (currentTab) {
      case 'meetings':
        return <SessionMeetingsTab name={session?.name ?? ''} meetings={session?.meetings ?? []} />;
      case 'feedback':
        return (
          <SessionFeedbackTab
            mentorAIFeedback={session?.mentorAIFeedback}
            mentorFeedback={session?.mentorFeedback}
            studentFeedback={session?.studentFeedback}
            mentorRecommendation={session?.mentorRecommendation}
          />
        );
      case 'transcript':
        return (
          <SessionTranscriptTab
            transcript={session?.relevantMeeting?.transcript}
            meetingStartDate={session?.relevantMeeting?.startTime}
          />
        );
      case 'chat':
        return (
          <SessionChatTab
            chat={session?.relevantMeeting?.chat}
            meetingStartDate={session?.relevantMeeting?.startTime}
          />
        );
      case 'session-notes':
        return (
          <SessionNotesTab
            mentorNotes={session?.relevantMeeting?.mentorNotes}
            studentsNotes={session?.relevantMeeting?.studentsNotes}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="mt-6 h-full">
      <Tabs tabs={tabs} initialTab={tab} renderContent={renderContent} />
    </div>
  );
};

export default SessionTabsSection;
