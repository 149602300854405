import MentorPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';
import USBigGold from '@assets/images/us_big_3.jpg';
import style from '@styles/views/home.module.scss';
import { Progress } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import '../StudentProgressCard/progress-card.styles.scss';

const PreviewStudentProgressCard = ({
  courseTitle,
  image,
  imageWidth,
  imageHeight,
}: {
  courseTitle: string;
  image?: any;
  imageWidth?: any;
  imageHeight?: any;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          backgroundColor: hover ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
        }}
        className={`${style.enrollment} progressCardComponentContainer`}
      >
        {image && (
          <div className="upperProgressCardContainer">
            <img
              className="object-cover object-center w-full h-full"
              src={image ?? USBigGold}
              alt="Background"
              crossOrigin="anonymous"
            />
            <div className="upperProgressCardContent">
              <span className={'dateText'}>{`Apr '24 - May '25`}</span>
            </div>
          </div>
        )}

        {!image && (
          <div className="upperProgressCardContainer">
            <img
              className="object-cover object-center w-full h-full"
              src={USBigGold}
              alt="Background"
              crossOrigin="anonymous"
            />

            <div className="upperProgressCardContent">
              <span className={'dateText'}>{`Apr '24 - May '25`}</span>
            </div>
          </div>
        )}

        <div
          className={`${style.content} lowerProgressCardContainer`}
          style={{
            backgroundColor: hover ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
          }}
        >
          <div style={{ marginTop: '14px' }}>
            <div style={{ height: '48px' }}>
              <span style={{ color: COLORS.TEXT_PRIMARY }} className="courseTitleText">
                {courseTitle}
              </span>
            </div>

            <div style={{ marginTop: '4px', marginBottom: '12px' }}>
              <img
                className="inline-block rounded-full avatar mentorAvatar"
                src={MentorPlaceholderAvatar}
                crossOrigin="anonymous"
                alt="avatar"
              />
              <span style={{ color: COLORS.TEXT_SECONDARY }} className="mentorName">
                {'John Doe'}
              </span>
            </div>

            <Progress
              strokeColor={COLORS.GOLD}
              trailColor={COLORS.DARK_GREY2}
              showInfo={false}
              percent={Math.floor(50)}
            />
          </div>

          <div className="courseCardDetailsContainer">
            <span
              className={`${style.courseProgressValue} courseCompletitionText`}
              style={{
                color: COLORS.TEXT_SECONDARY,
                marginRight: '4px',
                fontWeight: 'bold',
              }}
            >
              {0}%
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY }}
              className={`${style.courseProgressTitle} courseCompletitionText`}
            >
              Completed
            </span>

            <span
              style={{
                fontSize: '18px',
                marginLeft: '8px',
                marginRight: '8px',
                color: COLORS.TEXT_SECONDARY,
              }}
              className="courseCompletitionText"
            >
              •
            </span>

            <div className="completedSessionsContainer">
              <span
                className={`${style.courseProgressValue} courseCompletitionText`}
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  marginRight: '4px',
                  fontWeight: 'bold',
                }}
              >
                {'5/10'}
              </span>
              <span
                style={{ color: COLORS.TEXT_SECONDARY }}
                className={`${style.courseProgressTitle} courseCompletitionText`}
              >
                Sessions done
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewStudentProgressCard;
