import Table from '@components/V4/Table/Table';
import SessionModalSection, {
  ActionButton,
} from '@modules/Admin/components/SessionModal/SessionModalSection';
import { useContext, useState } from 'react';
import { MentorDetailsPageContext } from '../../MentorDetailsPage';
import CapacityForm from './CapacityForm';

const CapacityTab = () => {
  const { mentor } = useContext(MentorDetailsPageContext);

  const [isEditingMajor, setIsEditingMajor] = useState(false);

  const buttons: ActionButton[] = [{ buttonText: 'Edit', onClick: () => setIsEditingMajor(true) }];

  const currentNoEnrollments =
    mentor?.enrollments?.filter(
      (enrollment) =>
        enrollment.user?._id && enrollment.user?._id !== mentor?._id && !enrollment.ended
    ).length ?? 0;

  return isEditingMajor ? (
    <CapacityForm
      className="mt-6"
      maxEnrollments={mentor?.mentorData?.mentorCapacity?.capacity}
      currentNoEnrollments={currentNoEnrollments}
      onCloseEditForm={() => setIsEditingMajor(false)}
      userId={mentor?._id || ''}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Capacity" buttonsArray={buttons}>
      <Table size="small">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Max Capacity</Table.Cell>
            <Table.Cell>{mentor?.mentorData?.mentorCapacity?.capacity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Current number of enrollments</Table.Cell>
            <Table.Cell>{currentNoEnrollments}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </SessionModalSection>
  );
};

export default CapacityTab;
