import { Question as QuestionType } from '@shared/common';
import { Button, Space } from 'antd';

type Props = {
  question: QuestionType;
  answerCorrect: (answerId: string) => void;
  answerWrong: (answerId: string) => void;
};

const Question = ({ question, answerCorrect, answerWrong }: Props) => {
  const choices = question.answers.map((answer, index) => (
    <Button
      key={answer._id}
      onClick={index === 0 ? () => answerCorrect(answer._id) : () => answerWrong(answer._id)}
      style={{
        width: '100%',
        marginBottom: 10,
        whiteSpace: 'inherit',
        minHeight: '32px',
        height: 'auto',
      }}
    >
      {answer.text}
    </Button>
  ));
  const shuffledChoices = choices.sort(() => Math.random() - 0.5);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h4>{question.text}</h4>
      <img src={question.imageUrl} alt="" style={{ maxWidth: '100%' }} />
      {shuffledChoices}
    </Space>
  );
};

export default Question;
