import { ROLESv2, RoleType } from '@shared/constants';
import { CSSProperties } from 'react';
import ConstantsSelector from './_ContantsSelector';

type Props = {
  value?: RoleType[];
  onChange?: (value: RoleType[]) => void;
  style?: CSSProperties;
};

const RolesSelector = (props: Props) => {
  return (
    <ConstantsSelector constants={ROLESv2} mode="multiple" placeholder="Select Roles" {...props} />
  );
};

export default RolesSelector;
