import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/SessionModal/SessionModalFormWrapper';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';

interface UpcomingExamsSectionFormProps extends BasicProps {
  isModeAddNew?: boolean;
  upcomingExam?: any;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  targetGrade: z.string().optional().default(''),
  prevGrade: z.string().optional().default(''),
  date: z.string().min(1, { message: 'Please enter a valid date.' }),
  areasOfImprovement: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const UpcomingExamsSectionForm = (props: UpcomingExamsSectionFormProps) => {
  const { isModeAddNew = false, className, upcomingExam, onCloseEditForm } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [showToast] = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      onCloseEditForm();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: upcomingExam?.exam,
      targetGrade: upcomingExam?.targetGrade,
      prevGrade: upcomingExam?.prevGrade,
      date: upcomingExam?.date ?? '',
      areasOfImprovement: upcomingExam?.areasOfImprovement,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const languageExam: any = {
        ...data,
        _id: upcomingExam?._id,
      };

      updateUserProfile({
        languageExams: [languageExam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Upcoming Exam' : 'Edit Upcoming Exam'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Upcoming Exam"
    >
      <Input
        isRequired
        type="text"
        id="exam"
        label="Exam Name"
        isValid={!errors.exam?.message}
        isTouched={touchedFields.exam}
        errorText={errors.exam?.message}
        autofocus
        {...register('exam')}
      />
      <div className="flex gap-6">
        <Input type="text" id="targetGrade" label="Target Score" {...register('targetGrade')} />
        <Input type="text" id="prevGrade" label="Last Score" {...register('prevGrade')} />
      </div>
      <AgoraDatePicker
        isRequired
        value={dateField.value ? moment(dateField.value) : undefined}
        onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
        disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
        onBlur={dateField.onBlur}
        allowClear={false}
        label="Date"
        isValid={!dateFieldState.error}
        isTouched={dateFieldState.isTouched}
        errorText={dateFieldState.error?.message}
      />

      <TextArea
        id="areasOfImprovement"
        className="hover:bg-opacity-50 focus:bg-opacity-50"
        label="Ares Of Improvement"
        {...register('areasOfImprovement')}
      />
    </SessionModalFormWrapper>
  );
};

export default UpcomingExamsSectionForm;
