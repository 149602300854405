import styles from '@styles/components/extra-services-table.module.scss';
import { Empty, Spin } from 'antd';
import { FC } from 'react';
import ExtraServicesRow from './ExtraServicesRow';

// TODO Refactor any types
type Props = {
  selectedDate: any;
  extraServicesRendered: any;
  extraServices: any;
  isLoading: boolean;
  tableHeight?: number;
  handleSelected: (extraService: any) => void;
  handleSelectAll: (extraServices: any[]) => void;
  selectAll: boolean;
  selectedItems: any[];
  handleRowTouched: () => void;
};

const ExtraServicesTable: FC<Props> = ({
  selectedDate,
  extraServicesRendered,
  extraServices,
  isLoading,
  tableHeight,
  handleSelected,
  handleSelectAll,
  selectAll,
  selectedItems,
  handleRowTouched,
}) => {
  return (
    <div>
      <div className={`${styles.gridTable} ${isLoading ? styles.tableLoading : ''}`}>
        {/*<div className={styles.gridTableHeader}>*/}
        {/*  <Checkbox*/}
        {/*    checked={selectAll}*/}
        {/*    onClick={() => handleSelectAll(extraServices)}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={styles.gridTableHeader}>Mentor</div>
        <div className={styles.gridTableHeader}>Extra Activity</div>
        <div className={styles.gridTableHeader}>Description</div>
        <div className={styles.gridTableHeader}>Amount</div>
        <div className={styles.gridTableHeader}>Creation Date</div>
        <div className={styles.gridTableHeader}>Status</div>
        <div className={styles.gridTableHeader}>Actions</div>

        {isLoading && <Spin className="w-full" style={{ gridColumn: '1 / 11' }} />}

        {extraServicesRendered?.length && !isLoading ? (
          extraServicesRendered?.map((extraService: any, index: any) => (
            <ExtraServicesRow
              handleRowTouched={handleRowTouched}
              selectedDate={selectedDate}
              selectAll={selectAll}
              handleSelected={handleSelected}
              selectedItems={selectedItems}
              isEven={true}
              extraService={extraService}
              key={`${extraService._id}-${index}`}
              index={index}
            />
          ))
        ) : (
          <div style={{ gridColumn: '1 / 11' }}>{!isLoading && <Empty />}</div>
        )}
      </div>
    </div>
  );
};

export default ExtraServicesTable;
