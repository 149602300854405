import { CloseModalProps } from '@shared/common';
import { Modal } from 'antd';
import NewUserForm from '../Sales/NewUserForm';

const NewUserModal = ({ visible, hide }: CloseModalProps) => {
  return (
    <Modal title="New User" visible={visible} onCancel={hide} footer={null} width={700}>
      <NewUserForm isMentor={false} />
    </Modal>
  );
};

export default NewUserModal;
