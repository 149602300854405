import Button from '@components/V4/Button';
import { SESSION_STATUSES, SessionStatus } from '@shared/constants';
import moment from 'moment';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import CourseAbbreviationCard from './CourseAbbreviationCard';
import DetailsHeaderParticipantsList from './DetailsHeaderParticipantsList';
import HeaderLabelAndValue from './HeaderLabelAndValue';
import { DetailsHeaderProps } from './SessionDetailsHeader';
import SessionDetailsNavigationArrows from './SessionDetailsNavigationArrows';
import SessionSummaryCard from './SessionSummaryCard';
import { convertSecondsToTime } from 'apps/agora/src/utils/helpers';

const DetailsHeaderMobile = (props: DetailsHeaderProps) => {
  const {
    abbreviation,
    title,
    sessionSummary,
    agenda,
    scheduledDate,
    endDate,
    mentor,
    student,
    nextSession,
    prevSession,
    accentColor,
    meetingDuration,
    sessionStatus,
    setIsMentorFeedbackOpen,
    onMeetingModalOpen,
  } = props;

  return (
    <SessionDetailsLayoutCard className="gap-8 tablet:hidden">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <CourseAbbreviationCard abbreviation={abbreviation} accentColor={accentColor} />

          <SessionDetailsNavigationArrows nextSession={nextSession} prevSession={prevSession} />
        </div>

        <h1 className="text-3.5xl font-raleway font-semibold" style={{ color: accentColor }}>
          {title}
        </h1>

        <div className="flex gap-2">
          <DetailsHeaderParticipantsList
            mentor={mentor}
            student={student}
            accentColor={accentColor}
          />
        </div>

        <div className="flex flex-col gap-3">
          {!!endDate && (
            <HeaderLabelAndValue
              label="End Date:"
              value={moment(endDate).format('DD.MM.YYYY, HH:mm')}
            />
          )}

          {!endDate && !!scheduledDate && (
            <HeaderLabelAndValue
              label="Scheduled Date:"
              value={moment(scheduledDate).format('DD.MM.YYYY, HH:mm')}
            />
          )}

          {!endDate && !scheduledDate && (
            <Button buttonText="Schedule Meeting" className="w-36" onClick={onMeetingModalOpen} />
          )}

          {!!sessionStatus && (
            <HeaderLabelAndValue label="Session Status:" value={SESSION_STATUSES[sessionStatus]} />
          )}

          {!!meetingDuration && (
            <HeaderLabelAndValue
              label="Meeting Duration:"
              value={convertSecondsToTime(meetingDuration ?? 0)}
            />
          )}

          {!!agenda && <HeaderLabelAndValue label="Agenda:" value={agenda} />}
        </div>
      </div>

      <SessionSummaryCard
        needsFeedback={sessionStatus === SessionStatus.NEEDS_FEEDBACK}
        accentColor={accentColor}
        sessionSummary={sessionSummary}
        setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
      />
    </SessionDetailsLayoutCard>
  );
};

export default DetailsHeaderMobile;
