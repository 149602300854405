import FeedbackModal from '@modules/Student/components/FeedbackModal/FeedbackModal';

import { Meeting } from '@shared/common';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import './meeting-card-buttons.styles.scss';

interface JoinButtonProps {
  meeting: Meeting;
  afterSave?: () => void;
}

const GiveFeedbackButtonStudent: React.FC<JoinButtonProps> = ({ meeting, afterSave }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {showModal && (
        <FeedbackModal
          onClose={() => setShowModal(false)}
          isOpen={showModal}
          meetingId={meeting._id}
        />
      )}

      <Tooltip
        zIndex={1}
        color="transparent"
        placement="topRight"
        trigger="hover"
        title={
          <div className="feedback-button-tooltip">
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Give Feedback
            </span>
          </div>
        }
      >
        <span onClick={() => setShowModal(true)} className="button-icon-container">
          <div className="feedback-button-icon" />
        </span>
      </Tooltip>
    </div>
  );
};

export default GiveFeedbackButtonStudent;
