import Button from '@components/V4/Button';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface SessionModalFormWrapperProps extends BasicProps {
  title: string;
  submitButtonText?: string;
  contentClassName?: string;
  isLoading: boolean;
  onCancel: () => void;
  submitHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SessionModalFormWrapper = (props: SessionModalFormWrapperProps) => {
  const {
    title,
    submitButtonText,
    className,
    contentClassName,
    children,
    onCancel,
    isLoading,
    submitHandler,
  } = props;

  return (
    <div className={mergeClassNames(className)}>
      <div
        className={mergeClassNames(
          'flex flex-col gap-4 bg-surfaceHover rounded-lg w-full px-4 py-6 tablet:px-8 ',
          contentClassName
        )}
      >
        <div className="flex gap-4 items-center h-6">
          <h4 className="font-raleway text-xsm font-bold text-customGrey">{title}</h4>
        </div>

        <div className="flex flex-col gap-2">{children}</div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={onCancel} />
          <Button
            buttonText={submitButtonText ?? 'Save Details'}
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default SessionModalFormWrapper;
