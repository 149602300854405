export const COLORS = {
  PRIMARY: '#36B3A8',
  PURPLE: '#B980C9',
  GOLD: '#D7B037',

  TEXT_PRIMARY: '#FFFFFF',
  TEXT_SECONDARY: '#B0B0B0',
  COMPONENT_BACKGROUND: '#181818',
  SECTION_BACKGROUND: '#121212',
  HOVER_BACKGROUND: '#B0B0B0',
  ERROR: '#C96565',
  DARK_GREY: '#232323',
  DARK_GREY_ALT: '#272827',
  DARK_GREY2: '#707070',
  DARK_GREY3: '#333333',
  DARK_GREY4: '#1D1D1D',
  DARK_GREY5: '#161616',

  DARK_WHITE: '#F8F8F8',
  SURFACE_BACKGROUND: '#252525',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',
  NAVY_BLUE: '#2828C9',
  CORNFLOWER_BLUE: '#275CA5',
  CRETE_GREEN: '#7E8A2E',
  NIGHT_CRIMSON: '#A83663',
  ALERT_RED: '#D85E62',

  // FLAGS COLORS
  FLAG_COLORS: [
    '#D85E62',
    '#B980C9',
    '#D7B037',
    '#36B3A8',
    '#275CA5',
    '#2828C9',
    '#7E8A2E',
    '#A83663',
  ],

  // NPS COLORS
  GREEN: '#3DD598',
  RED: '#D85E62',

  NPS_DETAILS: ['#36B3A8', '#D7B037', '#B980C9', '#D85E62', '#275CA5', '#2828C9', '#7E8A2E'],
};
