import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

type Props<T> = {
  constants: { [key: number]: string };
} & SelectProps<T>;

const ConstantsSelector = <T extends SelectValue>(props: Props<T>) => {
  const { constants, ...rest } = props;

  return (
    <Select allowClear style={{ width: '100%' }} optionFilterProp="children" {...rest}>
      {Object.entries(constants).map(([value, label]) => (
        <Select.Option key={value} value={Number(value)}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ConstantsSelector;
