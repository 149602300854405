import { ReportStatus } from '@shared/constants';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';
import styles from '@styles/components/table.module.scss';
import { Button, message } from 'antd';
import { formatEnumValue } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';

interface Props {
  report: any;
  isEven: boolean;
}

const OpenReportsRow: React.FC<Props> = (props) => {
  const { report, isEven } = props;
  const handleDownloadActivityReport = async () => {
    const response = await apiClient.get(`/report/${report._id}/download-activity-report`, {
      responseType: 'blob',
    });
    saveByteArray(response);
  };

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const saveByteArray = (blob: any) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async () => {
    try {
      const response = await apiClient.get(`/report/${report._id}/download-invoice`, {
        responseType: 'blob',
      });
      // Handle successful response
      message.success('Invoice downloaded successfully');

      saveByteArray(response);
    } catch (error) {
      // Handle error
      console.log('error: ', error);
      // message.error(error.response.data.message);
    }
  };

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.createdAt).format('MMMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.updatedAt).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {report?.paymentAmountInHC} {report?.hubspotCurrency}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {formatEnumValue(ReportStatus[report.status])}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {/*{Modal}*/}
        {report?.invoiceId && (
          <Button
            type="primary"
            // disabled
            style={{
              border: '2px solid #000000',
              margin: '0px 10px',
              // cursor: 'not-allowed',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div onClick={() => handleDownload()}>Download Invoice</div>
              {/*<Dropdown*/}
              {/*  trigger={['click']}*/}
              {/*  overlay={*/}
              {/*    <div className={dropdown.dropdown}>*/}
              {/*      <div onClick={() => {}} className={dropdown.dropdownItem}>*/}
              {/*        Download Activity Report*/}
              {/*      </div>*/}

              {/*      /!*<DeleteMeetingButton meetingId={meeting._id} />*!/*/}
              {/*    </div>*/}
              {/*  }*/}
              {/*  placement="bottomRight"*/}
              {/*>*/}
              {/*  <DotsVerticalIcon className={button.actionButtonIcon} />*/}
              {/*</Dropdown>*/}
            </div>
          </Button>
        )}

        <Button
          onClick={() => handleDownloadActivityReport()}
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
        >
          <span style={{ width: '170px' }}>View Monthly Report</span>
        </Button>
      </div>
    </>
  );
};

export default OpenReportsRow;
