import { ReactComponent as StarIcon } from '@assets/icons/V4/star-icon.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import IconButton from '../IconButton';

interface StarProps extends BasicProps {
  size?: 'default' | 'small';
  isReadOnly?: boolean;
  isGold?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Star = (props: StarProps) => {
  const { size, isReadOnly, isGold, onClick, onMouseEnter, onMouseLeave } = props;

  return (
    <IconButton
      icon={<StarIcon />}
      variant="ghost"
      type="button"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={mergeClassNames(
        'cursor-pointer transition-colors text-customGrey hover:text-customGrey',
        {
          'w-8 h-8': size === 'default',
          'w-3 h-3': size === 'small',
          'cursor-default': isReadOnly,
          'text-customGold hover:text-customGold': isGold,
        }
      )}
    />
  );
};

export default Star;
