import { ReactComponent as SchoolIcon } from '@assets/icons/profile-icons/school-icon.svg';
import moment from 'moment';
import { useState } from 'react';
import { EducationalItemProps } from '../../../types';
import ItemContent from '../ItemContent';
import ResumeTabSchoolForm from './ResumeTabSchoolForm';

const ResumeTabSchoolItem = (props: EducationalItemProps) => {
  const { userId, schoolDetails, onDeleteEntry } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  return isEditMode ? (
    <ResumeTabSchoolForm
      schoolData={schoolDetails}
      isModeAddNew={false}
      onCancel={cancelEditHandler}
      userId={userId}
      setIsEditMode={setIsEditMode}
    />
  ) : (
    <ItemContent
      icon={<SchoolIcon className="min-w-5" />}
      onEdit={() => setIsEditMode(true)}
      onDelete={() => onDeleteEntry(schoolDetails._id)}
    >
      <ItemContent.Title>{schoolDetails.educationalInstitution.name}</ItemContent.Title>

      <ItemContent.SubTitle>
        {moment(schoolDetails.startDate).format('MMMM YYYY')} -
        {moment(schoolDetails.endDate).format('MMMM YYYY')}
      </ItemContent.SubTitle>

      <ItemContent.Description className="text-customPrimary">
        GPA: {schoolDetails.gpa}
      </ItemContent.Description>
    </ItemContent>
  );
};

export default ResumeTabSchoolItem;
