import { UploadOutlined } from '@ant-design/icons';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import UploadInvoiceModal from '@components/UploadInvoiceModal/UploadInvoiceModal';
import Button from '@components/V4/Button';
import { useModal } from '@hooks';
import { MENTOR_ROLE, ReportStatus, UPLOAD_INVOICE_STATES, KONAMI_CODE } from '@shared/constants';
import {
  useAuthState,
  useCheckMentorLastMonthMeetingAndExtraServices,
  useGeneratePersonalLastMonthActivityReport,
  useGetLatestMentorReport,
} from '@shared/react';
import {
  getUploadInvoiceBannerTitle,
  getUploadInvoiceBannerMessage,
  showMentorUploadInvoiceBanner,
  getInvoiceActionableState,
} from '@shared/utils/uploadInvoiceBannerHelper';
import AgoraSpinner from '@utils/AgoraSpinner';
import { message } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './banner-stack.styles.scss';
import apiClient from '@shared/react/contexts/AuthContext/apiClient';

type Props = {
  handleSubmitFeedback: () => void;
  banners: any[];
};

const BannerStack = ({ handleSubmitFeedback, banners }: Props) => {
  const history = useHistory();

  // UPLOAD BANNER

  const { userId, roles } = useAuthState();
  const userRole = localStorage.getItem('userRole');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [keySequence, setKeySequence] = useState<string[]>([]);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(true);

  const handleKeyDown = (event: any) => {
    setKeySequence((prevSequence) => [...prevSequence, event.code].slice(-11));
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isMentor: boolean = userRole === 'mentor' || roles.includes(MENTOR_ROLE);

  const { data: lastReportStatus } = useGetLatestMentorReport({
    enabled: isMentor,
  });

  const showUploadInvoiceBanner: boolean = showMentorUploadInvoiceBanner(
    isMentor,
    lastReportStatus?.status
  );

  const [Modal, show] = useModal(UploadInvoiceModal);

  const {
    mutate: generatePdf,
    data: pdfData,
    isError,
    isLoading,
  } = useGeneratePersonalLastMonthActivityReport({
    onError: (error: any) => {},
  });

  const { data: hasMentorMeetingsOrExtraServices, isLoading: isCheckingLoading } =
    useCheckMentorLastMonthMeetingAndExtraServices(userId, {
      enabled: showUploadInvoiceBanner,
    });

  const saveByteArray = (reportName: any, byte: any) => {
    const blob = new Blob([new Uint8Array(byte).buffer]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    const generatePdfIfNeeded = async () => {
      if (userId && showUploadInvoiceBanner) {
        generatePdf({ mentorId: userId });
      }
    };

    generatePdfIfNeeded();
  }, [isMentor, generatePdf, showUploadInvoiceBanner, userId]);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (keySequence.join(' ') === KONAMI_CODE.join(' ')) {
      show({});
    }
  }, [keySequence]);

  const saveByteArrayFile = (blob: any) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;

    const fileName = `Factura.pdf`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadInvoice = async (reportId: any) => {
    setIsInvoiceDownloading(true);
    try {
      const response = await apiClient.get(`/report/${reportId}/download-invoice`, {
        responseType: 'blob',
      });
      saveByteArrayFile(response);
    } catch (error: any) {
      message.error(`Error: ${(error?.response.message, error?.message)}`);
    } finally {
      setIsInvoiceDownloading(false);
    }
  };

  // END UPLOAD BANNER

  // BANNER STACK

  const [currentBanner, setCurrentBanner] = useState(banners.length > 0 ? banners[0] : undefined);
  const [bannerList, setBannerList] = useState(banners);
  const [animationClass, setAnimationClass] = useState('');
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const findCurrentIndex = () => banners.findIndex((banner) => banner === currentBanner);

  const handleArrowUp = () => {
    setAnimationClass('banner-exit-active');
    setTimeout(() => {
      const currentIndex = findCurrentIndex();
      const newIndex = currentIndex === 0 ? banners.length - 1 : currentIndex - 1;
      setCurrentBanner(banners[newIndex]);
      setAnimationClass('banner-enter-active');
      setTimeout(() => {
        setAnimationClass('');
        const updatedBanners = [...bannerList];
        const [removedBanner] = updatedBanners.splice(0, 1);
        setBannerList([...updatedBanners, removedBanner]);
      }, 200);
    }, 200);
  };

  const handleArrowDown = () => {
    setAnimationClass('banner-exit-active');
    setTimeout(() => {
      const updatedBanners = [...bannerList];
      const [removedBanner] = updatedBanners.splice(0, 1);
      setBannerList([...updatedBanners, removedBanner]);
      setCurrentBanner(updatedBanners[0]);
      setAnimationClass('banner-enter-active');
      setTimeout(() => {
        setAnimationClass('');
      }, 200);
    }, 200);
  };

  const nonCurrentBanners = bannerList.filter((banner) => banner !== currentBanner);

  useEffect(() => {
    setBannerList(banners);
    setCurrentBanner(banners.length > 0 ? banners[0] : undefined);
  }, [banners]);

  if (bannerList.length === 0 || currentBanner === undefined) {
    return null;
  }

  const getBannerBackgroundColor = (banner: any) => {
    switch (banner.name) {
      case 'invoice-banner':
        return lastReportStatus?.status === -1 || // report not generated
          lastReportStatus?.status === ReportStatus.INVOICE_UPLOADED ||
          lastReportStatus?.status === ReportStatus.INVOICE_ACCEPTED
          ? COLORS.SURFACE_BACKGROUND
          : COLORS.ALERT_RED;
      default:
        return banner.backgroundColor;
    }
  };

  const InvoiceRejectedBanner = (
    <div>
      <span
        style={{ color: COLORS.TEXT_PRIMARY }}
        className="banner-description"
        dangerouslySetInnerHTML={{
          __html: getUploadInvoiceBannerMessage(UPLOAD_INVOICE_STATES.REJECTED),
        }}
      ></span>
      <div className="upload-invoice-banner-buttons-container gap-2 tablet:gap-4">
        {Modal}
        <Button
          variant="primary"
          onClick={() => show({})}
          buttonText="Upload Invoice"
          icon={<UploadOutlined />}
        />
        <Button
          variant="secondary"
          onClick={() => saveByteArray('Monthly_Report.pdf', pdfData?.pdfBuffer?.data)}
          buttonText={!pdfData && isLoading ? 'Loading...' : 'View Monthly Report'}
          icon={!pdfData && isLoading ? <AgoraSpinner /> : undefined}
        />
      </div>
    </div>
  );

  const InvoiceActionableBanner = (
    <div>
      <span
        style={{ color: COLORS.TEXT_PRIMARY }}
        className="banner-description"
        dangerouslySetInnerHTML={{
          __html: getUploadInvoiceBannerMessage(
            getInvoiceActionableState(lastReportStatus?.status)
          ),
        }}
      />
      {lastReportStatus?.status !== ReportStatus.ERROR && (
        <div className="upload-invoice-banner-buttons-container gap-2 tablet:gap-4">
          {Modal}

          {lastReportStatus?.status !== ReportStatus.INVOICE_ACCEPTED &&
          lastReportStatus?.status !== ReportStatus.INVALID_E_INVOICE ? (
            <Button
              variant="primary"
              onClick={() => show({})}
              buttonText={
                lastReportStatus?.status === ReportStatus.INVOICE_UPLOADED
                  ? 'Replace Invoice'
                  : 'Upload Invoice'
              }
              icon={<UploadOutlined />}
            />
          ) : (
            <Button
              variant="secondary"
              onClick={() => handleDownloadInvoice(lastReportStatus._id)}
              buttonText="Download Invoice"
            />
          )}

          <Button
            variant="secondary"
            onClick={() => saveByteArray('Monthly_Report.pdf', pdfData?.pdfBuffer?.data)}
            buttonText={!pdfData && isLoading ? 'Loading...' : 'View Monthly Report'}
            icon={!pdfData && isLoading ? <AgoraSpinner margin="0px 8px 0px 0px" /> : null}
          />
        </div>
      )}
    </div>
  );

  // END BANNER STACK
  return (
    <div style={{ width: '100%' }} className="harvester-banner-container">
      <div>
        <div
          ref={bannerRef}
          style={{
            backgroundColor: getBannerBackgroundColor(currentBanner),
          }}
          className={`harvester-banner-inner-container ${animationClass}`}
        >
          {/*IMAGE*/}
          <div className="harvester-banner-image">
            <img
              style={{
                height: '98px',
                minWidth: '98px',
                maxWidth: '98px',
                // backgroundColor: 'black',
              }}
              src={currentBanner?.image}
              alt="banner-image"
            />
          </div>

          {/*CONTENT*/}
          <div
            style={{
              minHeight: '128px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div className="w-full">
              {/*BANNER TITLE*/}
              <div style={{ marginBottom: '8px' }}>
                <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="banner-title">
                  {currentBanner?.name === 'invoice-banner'
                    ? getUploadInvoiceBannerTitle(
                        hasMentorMeetingsOrExtraServices,
                        isError,
                        lastReportStatus?.status
                      )
                    : currentBanner?.title}
                </h2>
              </div>

              {Modal}
              {currentBanner?.name === 'invoice-banner' ? (
                <>
                  {(isCheckingLoading || isLoading) && (
                    <AgoraSpinner margin="0px 8px 0px 0px" fontSize={32} />
                  )}

                  {!isCheckingLoading && !isLoading && (
                    <>
                      {!hasMentorMeetingsOrExtraServices && (
                        <span
                          style={{ color: COLORS.TEXT_PRIMARY }}
                          className="banner-description"
                          dangerouslySetInnerHTML={{
                            __html: getUploadInvoiceBannerMessage(
                              UPLOAD_INVOICE_STATES.NOTHING_TO_UPLOAD
                            ),
                          }}
                        />
                      )}

                      {hasMentorMeetingsOrExtraServices &&
                        !isError &&
                        (lastReportStatus?.status === ReportStatus.REJECTED
                          ? InvoiceRejectedBanner
                          : InvoiceActionableBanner)}

                      {isError && (
                        <span
                          style={{ color: COLORS.TEXT_PRIMARY }}
                          className="banner-description"
                          dangerouslySetInnerHTML={{
                            __html: getUploadInvoiceBannerMessage(
                              UPLOAD_INVOICE_STATES.NOT_GENERATED
                            ),
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/*BANNER DESCRIPTION*/}
                  <div className="banner-description-container">
                    <p style={{ color: COLORS.TEXT_PRIMARY }} className="banner-description">
                      {currentBanner?.description}
                    </p>
                  </div>

                  {/*FEEDBACK LINK/BUTTON*/}
                  {currentBanner?.name !== 'invoice-banner' && (
                    <div
                      className="banner-link-container"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (currentBanner?.name === 'feedback-banner') {
                            handleSubmitFeedback();
                          } else if (currentBanner?.name === 'new-students-assigned-banner') {
                            history.push('/my-students?status=upcoming');
                          }
                        }}
                        buttonText={currentBanner?.secondaryButtonText}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {/*BANNER BUTTONS*/}
            <div
              style={{
                marginLeft: '8px',
                minHeight: '100%',
                width: '56px',
                minWidth: '56px',
                maxWidth: '56px',
              }}
              className="banner-buttons-container"
            >
              {/*DESKTOP ARROWS*/}
              {banners.length > 1 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    onClick={handleArrowUp}
                    className="arrow arrow-up"
                    style={{ width: '24px', height: '24px' }} // Ensure to set the height as well for proper display
                  />
                  <div
                    onClick={handleArrowDown}
                    className="arrow arrow-down"
                    style={{ width: '24px', height: '24px' }} // Ensure to set the height as well for proper display
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:
            animationClass === 'banner-exit-active' || animationClass === 'banner-enter-active'
              ? `calc(-${bannerRef.current?.clientHeight}px + 8px)`
              : ``,
        }}
      >
        {nonCurrentBanners.map((banner, index) => (
          <div
            key={banner?.name}
            style={{
              width: `calc(100% - (${index + 1} * 18px))`,
              // height: '8px',
              height:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? `${bannerRef.current?.clientHeight}px`
                  : '8px',
              backgroundColor: getBannerBackgroundColor(banner),
              borderTopLeftRadius:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? '12px'
                  : '0px',
              borderTopRightRadius:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? '12px'
                  : '0px',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
            }}

            // className="harvester-banner-inner-container"
          >
            {(animationClass === 'banner-exit-active' ||
              animationClass === 'banner-enter-active') &&
              index === 0 && (
                <div
                  // style={{ transform: 'scale(0.8)' }}
                  // ref={bannerRef}
                  // style={{
                  //   backgroundColor: banner?.backgroundColor,
                  //   boxShadow: 'none',
                  //   minHeight: '129px',
                  //   padding: '12px 16px 12px 16px',
                  // }}
                  className={
                    (animationClass === 'banner-exit-active' ||
                      animationClass === 'banner-enter-active') &&
                    index === 0
                      ? `harvester-banner-inner-container`
                      : ''
                  }
                >
                  {/*IMAGE*/}
                  <div className="harvester-banner-image">
                    <img
                      style={{
                        height: '98px',
                        minWidth: '98px',
                        maxWidth: '98px',
                      }}
                      src={banner?.image}
                      alt="banner-image"
                    />
                  </div>

                  {/*// CONTENT BACK BANNER*/}

                  <div
                    style={{
                      minHeight: '108px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>
                      {/*BANNER TITLE*/}
                      <div style={{ marginBottom: '8px' }}>
                        <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="banner-title">
                          {banner?.name === 'invoice-banner'
                            ? getUploadInvoiceBannerTitle(
                                hasMentorMeetingsOrExtraServices,
                                isError,
                                lastReportStatus?.status
                              )
                            : banner?.title}
                        </h2>
                      </div>

                      {banner?.name === 'invoice-banner' ? (
                        <>
                          {(isCheckingLoading || isLoading) && (
                            <AgoraSpinner margin="0px 8px 0px 0px" fontSize={32} />
                          )}

                          {!isCheckingLoading && !isLoading && (
                            <>
                              {!hasMentorMeetingsOrExtraServices && (
                                <span
                                  style={{ color: COLORS.TEXT_PRIMARY }}
                                  className="banner-description"
                                  dangerouslySetInnerHTML={{
                                    __html: getUploadInvoiceBannerMessage(
                                      UPLOAD_INVOICE_STATES.NOTHING_TO_UPLOAD
                                    ),
                                  }}
                                />
                              )}

                              {hasMentorMeetingsOrExtraServices &&
                                !isError &&
                                (lastReportStatus?.status === ReportStatus.REJECTED
                                  ? InvoiceRejectedBanner
                                  : InvoiceActionableBanner)}

                              {isError && (
                                <span
                                  style={{ color: COLORS.TEXT_PRIMARY }}
                                  className="banner-description"
                                  dangerouslySetInnerHTML={{
                                    __html: getUploadInvoiceBannerMessage(
                                      UPLOAD_INVOICE_STATES.NOT_GENERATED
                                    ),
                                  }}
                                />
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/*BANNER DESCRIPTION*/}
                          <div className="banner-description-container">
                            <p
                              style={{ color: COLORS.TEXT_PRIMARY }}
                              className="banner-description"
                            >
                              {banner?.description}
                            </p>
                          </div>
                          {/*FEEDBACK LINK/BUTTON*/}
                          {banner?.name !== 'invoice-banner' && (
                            <div
                              className="banner-link-container"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Button
                                variant="secondary"
                                onClick={() => {}}
                                buttonText={banner?.secondaryButtonText}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/*BANNER BUTTONS*/}
                    <div
                      style={{
                        marginLeft: '8px',
                        minHeight: '100%',
                        width: '56px',
                        minWidth: '56px',
                        maxWidth: '56px',
                      }}
                      className="banner-buttons-container"
                    >
                      {/*DESKTOP ARROWS*/}
                      {banners.length > 1 && (
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <div
                            // onClick={handleArrowUp}
                            className="arrow arrow-up-back-banner"
                            style={{ width: '24px', height: '24px' }}
                          />
                          <div
                            // onClick={handleArrowDown}
                            className="arrow arrow-down-back-banner"
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))}

        {/*DOTS*/}
        {banners.length > 1 && (
          <div style={{ marginTop: '4px' }} className="banner-dots-container">
            {banners.map((banner) => (
              <div
                key={banner?.name}
                onClick={() => {
                  setCurrentBanner(banner);
                }}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    height: banner?.name === currentBanner?.name ? '6px' : '4px',
                    width: banner?.name === currentBanner?.name ? '6px' : '4px',
                    borderRadius: '50%',
                    backgroundColor: banner?.name === currentBanner?.name ? '#FFFFFF' : '#A3A3A3',
                  }}
                ></div>
              </div>
            ))}
          </div>
        )}

        {/*MOBILE ARROWS*/}
        {banners.length > 1 && (
          <div
            style={{
              marginTop: '4px',
              width: '100%',
            }}
            className="banner-mobile-arrow-buttons"
          >
            <img
              onClick={handleArrowUp}
              src={ArrowLeft}
              style={{ width: '24px', height: 'auto', cursor: 'pointer' }}
            />
            <img
              onClick={handleArrowDown}
              src={ArrowRight}
              style={{ width: '24px', height: 'auto', cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerStack;
