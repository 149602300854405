import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus-icon.svg';
import IconButton from '@components/V4/IconButton';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface ResumeSectionProps extends BasicProps {
  title: string;
  isButtonActive?: boolean;
  isEditMode?: boolean;
  onClick?: () => void;
}

const ProfileMainSection = (props: ResumeSectionProps) => {
  const { children, title, onClick, isButtonActive, isEditMode } = props;
  return (
    <>
      <div className="flex justify-between">
        <h3 className="font-raleway font-bold">{title}</h3>
        {!!onClick && (
          <IconButton
            onClick={onClick}
            variant="primary"
            className={mergeClassNames('mr-4 tablet:mr-8 laptop:hover:text-surfaceBackground', {
              'text-customPrimary laptop:text-customPrimary bg-transparent hover:bg-customGrey hover:text-surfaceBackground':
                isButtonActive,
            })}
            icon={
              isEditMode ? (
                <EditIcon className="w-4 h-4 text-inherit m-auto" />
              ) : (
                <PlusIcon className="w-4 h-4 text-inherit m-auto" />
              )
            }
          />
        )}
      </div>
      {children}
    </>
  );
};

export default ProfileMainSection;
