import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Pagination from '@components/V4/Pagination';
import { SESSION_STATUSES } from '@shared/common';
import { useAuthState } from '@shared/react';
import { COLORS } from 'apps/agora/src/v3/global';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import RelevantSessionActionButtons from '../components/RelevantSessionActionButtons';
import { SessionHistoryListProps } from '../types';

const SessionHistoryListMobile = (props: SessionHistoryListProps) => {
  const { allSessions, pagination, onStudentFeedbackOpen, onMentorFeedbackOpen, isLoading } = props;

  const { isMentor } = useAuthState();

  return (
    <section className="flex flex-col gap-4 tablet:hidden">
      {allSessions?.sessions?.map((session) => (
        <div
          key={`${session._id}`}
          className="flex items-center justify-between gap-4 p-4 rounded-2xl bg-surfaceObject"
        >
          <div className="flex items-center gap-4">
            <div
              className="flex items-center justify-center w-8 h-8 font-raleway font-bold text-xs text-black rounded-lg"
              style={{
                background: session?.course?.color ?? COLORS.PRIMARY,
              }}
            >
              {!!session?.course?.abbreviation &&
              session?.course?.abbreviation !== 'NO_ABBREVIATION'
                ? session?.course?.abbreviation
                : 'n/a'}
            </div>
            <div className="flex flex-col gap-2.5">
              <p className="text-xs leading-5">
                {session.name},{' '}
                <Link
                  to={`/student-profile/${session?.relevantMeeting?.students?.[0]?._id}`}
                  className="text-white hover:text-customPrimary"
                >
                  {isMentor ? session?.students?.[0]?.fullName : session?.mentor?.fullName}
                </Link>
              </p>
              <p className="text-xs leading-5 text-customGrey">
                {session?.startDate
                  ? moment(session?.startDate).format('DD MMM YYYY, HH:mm')
                  : 'n/a'}
                {isMentor ? `, ${SESSION_STATUSES[session?.status]}` : ''}
              </p>
            </div>
          </div>

          <div className="flex gap-4">
            <RelevantSessionActionButtons
              meeting={session.relevantMeeting}
              session={session}
              isMentor={isMentor}
              onOpenFeedbackModal={
                isMentor
                  ? () =>
                      onMentorFeedbackOpen(
                        `${session.name} / ${session.students?.[0]?.fullName}`,
                        session?.relevantMeeting?._id ?? ''
                      )
                  : () => onStudentFeedbackOpen(session?.relevantMeeting?._id ?? '')
              }
            />
          </div>
        </div>
      ))}
      {isLoading && (
        <section className="w-full flex justify-center items-center h-64">
          <LoadingSpinner className="w-5 h-5" />
        </section>
      )}

      {!isLoading && !allSessions?.sessions?.length && (
        <EmptyStateCard
          className="h-64"
          icon={<BlockIcon className="text-customGrey" />}
          text={'No results.'}
        />
      )}
      {!!pagination && <Pagination size={'default'} {...pagination} />}
    </section>
  );
};

export default SessionHistoryListMobile;
