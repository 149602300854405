import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import apiClient from './AuthContext/apiClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      queryFn: async ({ queryKey }) => {
        let url = `/${queryKey[0]}`;
        queryKey.slice(1).forEach((key) => (url = url.replace('$', key as string)));

        const { data } = await apiClient.get(url);
        return data;
      },
    },
  },
});

type Props = {
  showWebDevTools?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const ReactQueryProvider = ({ children, showWebDevTools }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {showWebDevTools && <ReactQueryDevtools initialIsOpen={false} />}
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
